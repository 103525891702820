import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../store/Page";
import "../../../Modal/dialog.css";
import api from "../../../../api";
import config from "../../../../config";
import util from "../../../../util";
import utilFunc from "../../../../util_funs";
import CustomErrorField from "../../../Form/CustomErrorField";
import { Message } from "semantic-ui-react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";

import "../../Marketing/yr-style.css";
import util_funs from "../../../../util_funs";
class AddDomain extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      domainame: "",
      fromemail: "",
      loading: false,
      errors: {},
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      fromname:'',
    };
  }

  componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Configuration Setting",
      navigationName: "marketing",
      subHeaderTitle: "Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {}

  onchange = (e) => {
    if (e.target.value != "") {
      let errList = this.state.errors;
      delete errList[e.target.name];
      this.setState({ errors: errList });
    } else {
      let errList = this.state.errors;
      errList[e.target.name] = "This field is required.";
      this.setState({ errors: errList });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  onAdd = () => {
    var obj = this;
    this.validateForm(this.state, function (errors) {
      obj.setState({ errors });
      if (Object.keys(errors).length == 0) {
        obj.setState({ loading: true });
        api.ConfigSetting.adddomain({
          domainame: obj.state.domainame,
          fromemail: obj.state.fromemail,
          fromname: obj.state.fromname,
        }).then((data) => {
          obj.setState({ loading: false });
          try {
            if (data.data.status) {
              window.location.href =
                "/marketing/detaildomain/" + util_funs.encrypt_url(data.data.auto_id);
            } else {
              obj.showToaster("red", data.data);
              utilFunc.scrollTop(800);
            }
          } catch (err) {
            console.log(err);
          }
        });
      }
    });
  };

  validateForm = (data, callback) => {
    const errors = {};
    if (data.domainame == "") {
      errors["domainame"] = "This field is required.";
    }
    if (data.fromemail == "") {
      errors["fromemail"] = "This field is required.";
    }
    if (data.fromname == "") {
      errors["fromname"] = "This field is required.";
    }
    callback(errors);
  };

  showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      3000
    );
  };

  render() {
    const { errors } = this.state;

    return (
      <div style={{ width: "100%" }}>
        <div class="kt-header__bottom">
          <div class="kt-container">
            <div class="yr-pagetitle d-flex justify-content-between">
              <div class="left yr-titleSelect kt-margin-t-10 kt-margin-b-10">
                <Link
                  to={"/marketing/listdomain"}
                  id="listdomain"
                  class="btn linear-gradient yr-add-new float-right kt-font-bold"
                >
                  {" "}
                  <i class="fas fa-chevron-left"></i>Back to configuration
                  settings
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div class="kt-container kt-body  kt-grid kt-grid--ver" id="kt_body">
          <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
            <div class="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-ameplaybooks">
              <Message
                color={
                  this.state.userToasterColor
                    ? this.state.userToasterColor
                    : "teal"
                }
                style={{
                  display: this.state.userToaster ? "block" : "none",
                  marginTop: 30,
                }}
              >
                {this.state.userToasterMsg}
              </Message>
              {this.state.loading && <div className="ui loading form"></div>}
              <div class="yr-body-p">
                <div class="row">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="kt-portlet--tabs yr-mt-10">
                      {/* <h2 class="kt-font-bolder"></h2> */}
                      <div class="row">
                        <div class="col-lg-3 col-md-4 col-sm-12">
                          <div class="kt-form__control">
                            <label>Domain Name</label>
                            <input
                              name="domainame"
                              type="text"
                              class="form-control"
                              onChange={(e) => this.onchange(e)}
                              value={this.state.domainame}
                              className={
                                errors && errors["domainame"]
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {errors && errors.hasOwnProperty("domainame") && (
                              <CustomErrorField
                                message={errors["domainame"]}
                                className="error invalid-feedback"
                              />
                            )}
                          </div>
                          <div
                            class="kt-form__control"
                            style={{ marginTop: "10px" }}
                          >
                            <label>Sending Email</label>
                            <div style={{ display: "flex" }}>
                              <div style={{ width: 50 + "%" }}>
                                <input
                                  name="fromemail"
                                  type="text"
                                  class="form-control"
                                  onChange={(e) => this.onchange(e)}
                                  value={this.state.fromemail}
                                  className={
                                    errors && errors["fromemail"]
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                />
                                {errors &&
                                  errors.hasOwnProperty("fromemail") && (
                                    <CustomErrorField
                                      message={errors["fromemail"]}
                                      className="error invalid-feedback"
                                    />
                                  )}
                              </div>
                              <div
                                style={{ width: 50 + "%", marginTop: "12px" }}
                              >
                                {"@" + this.state.domainame}
                              </div>
                            </div>
                          </div>
                          <div class="kt-form__control">
                            <label>From Name</label>
                            <input
                              name="fromname"
                              type="text"
                              class="form-control"
                              onChange={(e) => this.onchange(e)}
                              value={this.state.fromname}
                              className={
                                errors && errors["fromname"]
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {errors && errors.hasOwnProperty("fromname") && (
                              <CustomErrorField
                                message={errors["fromname"]}
                                className="error invalid-feedback"
                              />
                            )}
                          </div>

                          <div class="kt-form__control kt-margin-t-20 text-right">
                            {/* <button type="submit" class="btn blue">Cancel</button> */}
                            <button
                              type="button"
                              onClick={() => this.onAdd()}
                              class="btn linear-gradient yr-add-new"
                            >
                              Add Domain
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(AddDomain);
