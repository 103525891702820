import React from "react";
import {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
} from "../../../../store/Page";
import "../../../Modal/dialog.css";
import "./email.css";
import { library, icon } from "@fortawesome/fontawesome-svg-core";

import api from "../../../../api";
//sagar
import Api from "../../../../apiPlaybook/marketing";
//sagar
import config from "../../../../config";
import util from "../../../../util";
import utilFunc from "../../../../util_funs";
import CustomErrorField from "../../../Form/CustomErrorField";
import { Message } from "semantic-ui-react";
import { connect } from "react-redux";
import DatatableList from "./PlayBook/DatatableList";
import { Multiselect } from "multiselect-react-dropdown";
import { Dropdown } from "semantic-ui-react";
import "../../Marketing/yr-style.css";
import EmailListNew from "./Template/EmailListNew";

class PlayBook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      errors: {},
      tab: "",
      bucketList: [],
      bucketListOptions: [], //sagar
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      dialogTitle: "",
      dialogMessage: "",
      dialogLoading: false,
      dialogShow: false,
      title: "",
      pb_id: "",
      playbookStatus:"",
      selectedValue: [],
      selectedValuePlayBook: [],
      checkedValue: [],
      actionId: "",
      playbookList: "",
      playbookList1: [],
      pb_flag: 0,
	    playbookBucketType : [{id:'email',name:"Email"},{id:'postcard',name:"Postcard"},{id:'sms',name:"SMS"}],
	    selectedPlaybookType : [],
      disabled:false,
      bucketloader:false
    };
  }

  componentDidMount() {
    const { setPageTitle, toggleBreadcrum, toggleNavigation } = this.props;
    setPageTitle({
      title: "Email",
      navigationName: "marketing",
      subHeaderTitle: "Engage",
    });
    toggleNavigation({ toggle: true });
    toggleBreadcrum({ toggle: false });
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.key != prevProps.location.key) {
      this.loadData();
    }
  }
  //sagar

  loadData = () => {
    api.marketing.getBucketList({}).then((res) => {
      try {
        if (res.success) {
          let bucketListOptions = res.data.map((item) => {
            return {
              id: item.id,
              name: item.bucket_title,
            };
          });
          this.setState({
            loading: false,
            bucketList: res.data,
            bucketListOptions: bucketListOptions,
          });
        } else {
          this.showToaster("red", res.message);
          utilFunc.scrollTop(800);
        }
      } catch (err) {
        console.log(err);
      }
    });
    api.marketing.getAdminTepmlates().then((resp) => {
      if(resp.adminPlaybook != undefined ){
        let adminTemplatesList = resp.adminPlaybook.map((item) => {
          return {
            value: item.play_id,
            text: item.play_title,
          };
        });
        this.setState({ playbookList: adminTemplatesList });
      }
    });
  };

  /* End of Code */

  showToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      3000
    );
  };

  setPbid = (e) => {
    let pb_id = e.target.value;
    alert(pb_id);
    if (e.target.value != "") {
      let pb_id = e.target.value;
      //alert( pb_id );
      this.setState({ pb_id: pb_id });
    } else {
      this.setState({ pb_id: "" });
    }
  };
  // Start Rohit
  onSelectPlaybook = (key, data) => {
    this.setState({
      pb_id: data.value,
    });
  };
  // End Rohit
  // Start Rohit
  onRemovePlaybook = (selectedList, removedItem) => {
    this.setState({
      pb_id: "",
    });
  };

  // End Rohit

  validatePlaybookDelete = (action,status) => {
    this.setState({
      dialogTitle: "Delete",
      dialogMessage: "Please stop your campaign then you can delete it.",
      dialogLoading: false,
      dialogShow: true,
      errors: {},
      selectedValue: [],
      checkedValue: [],
      playbookStatus:'Active'
    });
   }

  createPlaybook = (action, data = null) => {
    if (action == "Close") {
      this.setState({
        dialogTitle: "",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: false,
        title: "",
        errors: {},
        selectedValue: [],
        selectedValuePlayBook: [],
        selectedPlaybookType:[],
        checkedValue: [],
        actionId: "",
      });
    }

    if (action == "Add") {
      this.setState({
        dialogTitle: "Add",
        dialogMessage: "",
        dialogLoading: false,
        dialogShow: true,
        title: "",
        pb_id: "",
        errors: {},
        pb_flag: 0,
        selectedPlaybookType:[],
        disabled:false
      });
    }

    if (action === "Added") {
      var obj = this;
      this.validateForm(this.state, function (errors) {
        obj.setState({ errors });
        if (Object.keys(errors).length === 0) {
          obj.setState({ dialogLoading: true,disabled:true });
          api.marketing
            .addplaybook({
              title: obj.state.title,
              bucket: obj.state.checkedValue,
              play_id: obj.state.pb_id,
			        playbook_bucket_type :obj.state.selectedPlaybookType
            })
            .then((data) => {
              obj.setState({ dialogLoading: false });
              try {
                if (data.success) {
                  setTimeout(() => {
                    obj.datable.loadData({});
                  }, 2000);
                  obj.createPlaybook("Close");
                  obj.showToaster(
                    "green",
                    "Playbook has been added successfully."
                  );
                  utilFunc.scrollTop(800);
                } else {
                  this.showToaster("red", data.data);
                  utilFunc.scrollTop(800);
                }
              } catch (err) {
                console.log(err);
              }
            });
        }
      });
    }

    if (action == "Edit") {
      if (data.bucketList.length > 0) {
        new Promise((resolve) => {
          let t = 0;
          var chkArr = [];
          data.bucketList.forEach(function (userItem1) {
            chkArr.push(userItem1.id);
            if (t == data.bucketList.length - 1) resolve(chkArr);
            t++;
          });
        }).then((chkArr) => {
          this.setState({
            dialogTitle: "Edit",
            dialogMessage: "",
            dialogLoading: false,
            dialogShow: true,
            title: data.title,
            pb_id: "",
            errors: {},
            selectedValue: data.bucketList,
            checkedValue: chkArr,
            actionId: data.id,
            pb_flag: 1,
            selectedPlaybookType:data.bucket_type ? JSON.parse(data.bucket_type) : []
          });
	        });
      } else {
        var chkArr = [];
        this.setState({
          dialogTitle: "Edit",
          dialogMessage: "",
          dialogLoading: false,
          dialogShow: true,
          title: data.title,
          pb_id: "",
          errors: {},
          selectedValue: data.bucketList,
          checkedValue: chkArr,
          actionId: data.id,
          pb_flag: 1,
          selectedPlaybookType:data.bucket_type ? JSON.parse(data.bucket_type) : []
        });
      }
    }

    if(action == "validateDelete"){
       this.validatePlaybookDelete(action,data);
    }

    if (action == "Edited") {
      var obj = this;
      this.validateForm(this.state, function (errors) {
        obj.setState({ errors });
        if (Object.keys(errors).length == 0) {
          obj.setState({ dialogLoading: true });
          console.log("checkedValue in Edited:", obj.state.checkedValue);
          api.marketing
            .editplaybook({
              id: obj.state.actionId,
              title: obj.state.title,
              bucket: obj.state.checkedValue,
			  playbook_bucket_type :obj.state.selectedPlaybookType
            })
            .then((data) => {
              obj.setState({ dialogLoading: false });
              console.log(data);

              try {
                //sagar
                if (data.success) {
                  setTimeout(() => {
                    obj.datable.loadData({});
                  }, 2000);

                  obj.createPlaybook("Close");
                  obj.showToaster(
                    "green",
                    "Playbook has been edited successfully."
                  );
                  utilFunc.scrollTop(800);
                } else {
                  obj.showToaster("red", "Unable to edit playbook");
                  utilFunc.scrollTop(800);
                }
              } catch (err) {
                console.log(err);
              }
            });
        }
      });
    }

    if (action == "Delete") {
      this.setState({
        dialogTitle: "Delete",
        dialogMessage: "Are you sure you want to delete it?",
        dialogLoading: false,
        dialogShow: true,
        title: data.title,
        errors: {},
        selectedValue: [],
        checkedValue: [],
        actionId: data.id,
        playbookStatus:data.status
      });
    }

    if (action == "Deleted") {
      var obj = this;
      obj.setState({ dialogLoading: true });
      api.marketing.deleteplaybook({ pid: obj.state.actionId }).then((data) => {
        //sagar-> pid
        obj.setState({ dialogLoading: false });
        console.log("deleted response", data);
        try {
          if (data.success) {
            setTimeout(() => {
              obj.datable.loadData({});
            }, 1500);
            obj.createPlaybook("Close");
            obj.showToaster("green", "Playbook has been deleted successfully.");
            utilFunc.scrollTop(800);
          } else {
            obj.showToaster("red", "Unable to delete playbook");
            utilFunc.scrollTop(800);
          }
        } catch (err) {
          console.log(err);
        }
      });
    }
  };


  validateForm = (data, callback) => {
    const errors = {};
    if (data.title.trim().length === 0) {
      errors["title"] = "This field is required.";
    }
    if (data.selectedPlaybookType.length === 0) {
      errors["selectedPlayBktType"] = "This field is required.";
    }
    
    callback(errors);
  };
  loadBuckets = () => {
	api.marketing.getBucketList({type:this.state.selectedPlaybookType}).then((res) => {
		try {
		  if (res.success) {
			let bucketListOptions = res.data.map((item) => {
			  return {
				id: item.id,
				name: item.bucket_title,
			  };
			});
			this.setState({
			  loading: false,
			  bucketList: res.data,
			  bucketListOptions: bucketListOptions,
			});
      let availableBucketId = res.data.map((buckets => buckets.id));
      let selecteBuckets = this.state.checkedValue.filter( (buckets => availableBucketId.includes(buckets)));
      this.setState({checkedValue:selecteBuckets});
      let availablebucketname = res.data.map((buckets => buckets.bucket_title));
      let selecteBucketsoptoins = this.state.selectedValue.filter( (buckets => {
        return availablebucketname.includes(buckets.name)
      }));
      this.setState({selectedValue:selecteBucketsoptoins});
      this.setState({
        bucketloader:false,
        checkedValue:selecteBuckets
      });

		  } else {
        this.showToaster("red", res.message);
        utilFunc.scrollTop(800);
		  }
     
		} catch (err) {
		  console.log(err);
		}
	});
  }
  onSelectPlaybookType = (selectedList, selectedItem) => {
    this.setState({
      bucketloader:true
    });
    let checkedValueP = this.state.selectedPlaybookType;
    if(!checkedValueP.includes(selectedItem.id))
    if (checkedValueP.filter(e => e.id === selectedItem.id).length == 0) {
      checkedValueP.push(selectedItem);
    }
    let errList = this.state.errors;
    delete errList["selectedBkt"];
    this.setState({
      selectedPlaybookType: checkedValueP,
      errors: errList,
    },() => this.loadBuckets());
  }
  
  onRemovePlaybookType = (selectedList, removedItem) => {

    let checkedValueP = this.state.selectedPlaybookType;
      checkedValueP = checkedValueP.filter(e => e.id !== removedItem.id);
      this.setState({
        selectedPlaybookType: checkedValueP,
      });
      
      if (checkedValueP.length > 0) {
        this.setState({
          bucketloader:true
        });
        let errList = this.state.errors;
        errList["selectedBkt"] = "";
        this.setState({
        errors: errList,
        },() => this.loadBuckets());
      }else{
        this.setState({
          loading: false,
          bucketListOptions: [],
        });
      }
  };

  onSelect = (selectedList, selectedItem) => {
    this.setState({selectedValue:selectedList});
    let checkedValueP = this.state.checkedValue;
    checkedValueP.push(selectedItem.id);
    let errList = this.state.errors;
    delete errList["selectedBkt"];
    this.setState({
      checkedValue: checkedValueP,
      errors: errList,
    });
  };

  onRemove = (selectedList, removedItem) => {
    this.setState({selectedValue:selectedList});
    let checkedValueP = this.state.checkedValue;
    checkedValueP.splice(checkedValueP.indexOf(removedItem.id), 1);
    this.setState({
      checkedValue: checkedValueP,
    });
    if (this.state.checkedValue.length == 0) {
      let errList = this.state.errors;
      errList["selectedBkt"] = "";
      this.setState({
        errors: errList,
      });
    }

  };

  onchange = (e) => {
    if (e.target.value != "") {
      let errList = this.state.errors;
      delete errList[e.target.name];
      this.setState({ errors: errList });
    } else {
      let errList = this.state.errors;
      errList[e.target.name] = "This field is required.";
      this.setState({ errors: errList });
    }
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    const { errors } = this.state;
    // const pbList = this.state.playbookList;
    console.log("selectedPlaybookType",this.state.selectedPlaybookType.length);
    return (
      <div
        className="mh-ktContainer kt-container kt-body  kt-grid kt-grid--ver completedchatmain"
        id="kt_body"
      >
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
          <div className="kt-content kt-grid__item kt-grid__item--fluid yr-mt-20 yr-manage-contact">
            <Message
              color={
                this.state.userToasterColor
                  ? this.state.userToasterColor
                  : "teal"
              }
              style={{
                display: this.state.userToaster ? "block" : "none",
                marginTop: 30,
              }}
            >
              {this.state.userToasterMsg}
            </Message>
            {this.state.loading && <div className="ui loading form"></div>}
            {!this.state.loading && (
              <div>
                {" "}
                <DatatableList
                  ref={(datable) => {
                    this.datable = datable;
                  }}
                  createPlaybook={this.createPlaybook}
                />{" "}
              </div>
            )}
          </div>
        </div>

        <div
          className={this.state.dialogShow ? "modal fade show" : "modal fade"}
          role="dialog"
          style={{ display: this.state.dialogShow ? "block" : "none" }}
        >
          <div
            className="modal-dialog modal-dialog-centered modalcenter-box"
            role="document"
            style={{ maxWidth: 600 }}
          >
            {this.state.bucketloader && (
              <div className="ui loading form click_false"></div>
            )}
            <div className="modal-content zindex-0">
              <button
                type="button"
                className="close linear-gradient"
                data-dismiss="modal"
                aria-label="Close"
                onClick={(e) => this.createPlaybook("Close")}
              >
                X
              </button>
              <div className="modal-header">
                <h5 className="modal-title">
                  {this.state.dialogTitle == "Add"
                    ? "Add Playbook"
                    : this.state.dialogTitle == "Edit"
                    ? "Edit Playbook"
                    : this.state.dialogTitle == "Delete"
                    ? "Confirm your action"
                    : "Alert"}
                </h5>
              </div>
              <div className="modal-body">
                <div className="px-4">
                  {this.state.dialogTitle == "Add" ||
                  this.state.dialogTitle == "Edit" ? (
                    <div>
                      <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                          Title :
                        </label>
                        <div className="col-lg-8">
                          <div className="field">
                            <div className="ui fluid input">
                              <input
                                autocomplete="off"
                                name="title"
                                placeholder=""
                                type="text"
                                value={this.state.title}
                                onChange={(e) => this.onchange(e)}
                              />
                            </div>
                            {errors && errors.hasOwnProperty("title") && (
                              <div className="red">{errors["title"]}</div>
                            )}
                          </div>
                        </div>
                      </div>
					  {/* bucket type selection  */}
					  <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                          Campaign Type :
                        </label>
                          <div className="col-lg-8">
                            <div className="field">
                              <div
                                className="ui fluid input ui__selector"
                                style={{ marginTop: "5px" }}
                              >
                                <Multiselect
                                  showArrow={true}
                                  options={this.state.playbookBucketType} // Options to display in the dropdown       //Sagar
                                  selectedValues={this.state.selectedPlaybookType} // Preselected value to persist in dropdown
                                  onSelect={this.onSelectPlaybookType} // Function will trigger on select event
                                  onRemove={this.onRemovePlaybookType} // Function will trigger on remove event
                                  displayValue="name" // Property name to display in the dropdown options
                                  closeIcon="circle"
                                  avoidHighlightFirstOption={true}
                                />
                              </div>
                              {errors &&
                                errors.hasOwnProperty("selectedPlayBktType") && (
                                  <div className="red">
                                    {errors["selectedPlayBktType"]}
                                  </div>
                                )}
                            </div>
                          </div>
                      </div>
					    {/* multiple select for bucket type */}
                        {
                          this.state.selectedPlaybookType.length > 0 && 
                          (<div className="form-group row">
                            <label className="col-lg-4 col-form-label">
                              Buckets :
                            </label>
                            {this.state.bucketList.length == 0 ? (
                              <div>
                                <a
                                  className="add-gradient"
                                  href="/marketing/contacts"
                                  style={{ marginTop: "23px" }}
                                >
                                  Add Buckets
                                </a>
                              </div>
                            ) : (
                              <div className="col-lg-8">
                                <div className="field">
                                  <div
                                    className="ui fluid input ui__selector"
                                    style={{ marginTop: "5px" }}
                                  >
                                    <Multiselect
                                      showArrow={true}
                                      options={this.state.bucketListOptions} // Options to display in the dropdown       //Sagar
                                      selectedValues={this.state.selectedValue} // Preselected value to persist in dropdown
                                      onSelect={this.onSelect} // Function will trigger on select event
                                      onRemove={this.onRemove} // Function will trigger on remove event
                                      displayValue="name" // Property name to display in the dropdown options
                                      closeIcon="circle"
                                      avoidHighlightFirstOption={true}
                                    />
                                  </div>
                                  {errors &&
                                    errors.hasOwnProperty("selectedBkt") && (
                                      <div className="red">
                                        {errors["selectedBkt"]}
                                      </div>
                                    )}
                                </div>
                              </div>
                            )}
                          </div>)
                        }
                      
                      {/* Start  Rohit  */}
                      {this.state.pb_flag == 0 ? (
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Choose template :
                          </label>
                          <div className="col-lg-8">
                            <div className="field">
                              <div
                                className="ui fluid input"
                                style={{ marginTop: "5px", marginLeft: 0 }}
                              >
                                <Dropdown
                                  options={this.state.playbookList}
                                  placeholder="Select"
                                  value={this.state.pb_id}
                                  search
                                  selection
                                  onChange={this.onSelectPlaybook}
                                  className="form-control-2"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="form-group row"></div>
                      )}

                      {/* End Rohit  */}
                    </div>
                  ) : (
                    this.state.dialogMessage
                  )}
                </div>
              </div>
              {this.state.dialogTitle == "Add" ? (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                    onClick={(e) => this.createPlaybook("Close")}
                    data-dismiss="modal"
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    type="button"
                    className="btn linear-gradient yr-submitbtn btn-sm"
                    onClick={(e) => this.createPlaybook("Added")}
                    disabled={this.state.disabled}
                  >
                    Next
                  </button>
                </div>
              ) : this.state.dialogTitle == "Edit" ? (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                    onClick={(e) => this.createPlaybook("Close")}
                    data-dismiss="modal"
                  >
                    <span>Cancel</span>
                  </button>
                  <button
                    type="button"
                    className="btn linear-gradient yr-submitbtn btn-sm"
                    onClick={(e) => this.createPlaybook("Edited")}
                  >
                    Save
                  </button>
                </div>
              ) : this.state.dialogTitle == "Delete" && this.state.playbookStatus !== 'Active' ? (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
                    onClick={(e) => this.createPlaybook("Close")}
                    data-dismiss="modal"
                  >
                    <span>No</span>
                  </button>
                  <button
                    type="button"
                    className="btn linear-gradient yr-submitbtn btn-sm"
                    onClick={(e) => this.createPlaybook("Deleted")}
                  >
                    Yes
                  </button>
                </div>
              ) : (
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn linear-gradient yr-submitbtn btn-sm"
                    onClick={(e) => this.createPlaybook("Close")}
                  >
                    Ok
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};

export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  toggleBreadcrum,
})(PlayBook);
