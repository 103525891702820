import axios from "../../config/axios";

const dialogflow = {
  loadDialogFlow: (params) =>
    axios
      .post("/integration/GoogleMyBusiness/", params)
      .then((resp) => (resp ? resp.data : null)),

  createWebhook: (params) =>
    axios
      .post("/integration/GoogleMyBusiness/create", params)
      .then((resp) => (resp ? resp.data : null)),

//   deleteWebhook: (params) =>
//     axios
//       .post("/integration/googlemybusiness/delete", params)
//       .then((resp) => (resp ? resp.data : null)),
};

export default dialogflow;