import React, { useState } from "react";
import { Form, Dropdown } from "formsy-semantic-ui-react";
import { Message } from "semantic-ui-react";
import validationRule from "../../../../../validation";
import "../../../../Modal/dialog.css";
import DatePicker from "react-datepicker";
import api from "../../../../../api";
import util from "../../../../../util";

import "react-datepicker/dist/react-datepicker.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
validationRule.isRequired();
validationRule.minCustomLength(6);
validationRule.usPhoneLength();

const DialogBoxAddEdit = ({
  obj,
  type,
  modalClose,
  onValidSubmit,
  onValidSubmitCSV,
  handleCheck,
  checkPhoneSms,
  checkPhone,
  checkNumberFormat,
  handleDrop,
  handleDropSms,
  onImageLoaded,
  onCropComplete,
  onCropChange,
  handleFile,
  getCroppedImg,
  handleRemovePic,
  handleRemovePicAdd,
  userdata,
  defaultCountryCodeArr,
  remailCountryCodeArr,
  phoneNumberValue,
  bid,
}) => {
  let show = obj.modalstatusAddEdit;
  let title = obj.modaltitle;
  let phone_number = obj.phone_number;
  let modaldata = obj.modaldata;
  let opt = modaldata.opt;
  let submitBtnName = modaldata.submitBtnName;
  let modalType = obj.modalType;
  const errorLabel = <div className="red" />;
  let currentContactData = obj.currentContactLabelDataOnEdit;

  const [duplicateEmailError, setDuplicateError] = useState('');
  const [startDate, setStartDate] = useState("");
  const [isdCode, setIsdCode] = useState("+1");
  const [modalFormLoader, setModalFormLoader] = useState(false);
  const [modalErrorMsg, setModalErrorMsg] = useState("none");
  const [modalErrorMsg1, setModalErrorMsg1] = useState("none");
  const [modalErrorMsgCSV, setModalErrorMsgCSV] = useState("none");
  const [modalErrorMsgCSVMessage, setModalErrorMsgCSVMessage] = useState("");
  const [browse, setBrowse] = useState("Browse");
  const [csvErrorDisplay, setCsvErrorDisplay] = useState("none");
  const [csvErrorMsg, setCsvErrorMsg] = useState("");
  const [csvFileValue, setCsvFileValue] = useState("");
  const [formError, setFormError] = useState('')
	const postcardVariable = ['First Name', 'Last Name', 'Postcard - Address Line 2', 'Postcard - Address Line 1', 'Postcard - City','Postcard - State', 'Postcard - Zip Code', 'Postcard - Country'];

  const mystyle = {
    padding: "3.25rem",
    maxHeight: "500px",
    overflow: "auto",
  };
  let redButton = {
    marginTop: "-11px",
  };
  // const imageUrl = userdata.assetPath + "/teamImg/" + modaldata.team_src;
  // const renderDropDownDefault = (defaultCountryCodeArr, countryId = null) => {
  //   console.log("KRA", defaultCountryCodeArr);
  //   return defaultCountryCodeArr.map((option, index) => {
  //     return (
  //       <option
  //         key={index}
  //         value={option.country_code}
  //         selected={countryId == option.country_code ? "selected" : ""}
  //       >
  //         {option.country_name}
  //       </option>
  //     );
  //   });
  // };

  const renderDropDownRemaining = (remailCountryCodeArr, countryId = null) => {
    return remailCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : ""}
        >
          {option.country_name}
        </option>
      );
    });
  };

  const drawLabelAndFields = (e) => {
    const currentContactLabelData = obj.currentContactLabelData;
    const types = obj.btype;
    console.log(types, 'datatype')
    return currentContactLabelData.map((el, idx) => {
      return(
        <div className="form-group row">
          <label className="col-lg-5 col-form-label">{el.title.split('_').join(' ')} :</label>
          <div className="col-lg-7">
            {/* {idx==0 ?
              <Form.Input
              fluid
              validations="isRequired"
              onBlur={checkEmail}
              autoComplete="off"
              name={el.title.trim()}
              validationErrors={{
                isRequired: "This field is required.",
              }}
              errorLabel={errorLabel}
            />:<Form.Input
              fluid
              autoComplete="off"
              name={el.title.trim()}
              errorLabel={errorLabel}
            />
          }
           {(duplicateEmailError && idx==0) && (
                                <div class="red" style={redButton}>
                                  {duplicateEmailError}.
                                </div>
                              )} */}
            {displayInputType(types, el.title)}
          </div>
        </div> 
    )
  
  });
  };

  
  
  const displayInputType = (type, text, ivalue = '') => {
		let flag = null;
		if((type == 1 || type == 4 || type == 5 || type == 7) && text == 'Email'){
			flag = true;
			return <><Form.Input
          fluid
          validations="isRequired"
          onChange={checkEmail}
          autoComplete="off"
          value={ivalue}
          name={text}
          validationErrors={{
            isRequired: "This field is required.",
          }}
          errorLabel={errorLabel}
        />{duplicateEmailError && (
					<div className="red" style={{marginTop: -12}}>
						{duplicateEmailError}.
					</div>
				)}
        </>
		}
		if((type == 3 || type == 5 || type == 6 || type == 7) && (text == "Phone Number" || text == "Phone_Number")){
			flag = true;
			return <Form.Input
				fluid
				autoComplete="off"
				validations="isRequired"
				validationErrors={{
					isRequired: "This field is required.",
				}}
        onChange={checkNumberFormat}
				errorLabel={errorLabel}
				name={text.trim()}
				value={phoneNumberValue}
				maxLength={13}
			/>
		}
		
		if((type == 2 || type == 4 || type == 6 || type == 7) && (postcardVariable.includes(text))){
			flag = true;
			return <Form.Input
				fluid
				autoComplete="off"
				validations="isRequired"
				value={ivalue}
				validationErrors={{
					isRequired: "This field is required.",
				}}
				errorLabel={errorLabel}
				name={text.trim()}
			/>
		}

		if(flag == null){
			return <Form.Input
				fluid
				autoComplete="off"
				name={text.trim()}
				value={ivalue}
			/>
		}
	}

  const drawLabelAndFieldsOnEdit = (e) => {
    const currentContactLabelData = obj.currentContactLabelDetailDataOnEdit;
    return currentContactLabelData.map(({ title, type, hid, value }, idx) => (
      <div className="form-group row">
        <label className="col-lg-5 col-form-label">{title} :</label>
        <div className="col-lg-7">
          {type == "text" ? (
            <Form.Input
              fluid
              autoComplete="off"
              name={type + "_" + hid}
              value={value}
            // placeholder={title}
            />
          ) : type == "textarea" ? (
            <Form.TextArea
              rows="10"
              value={value}
              fluid
              autoComplete="off"
              name={type + "_" + hid}
            ></Form.TextArea>
          ) : type == "dob" ? (
            <span>
              <DatePicker
                selected={"1986-07-11"}
                showMonthDropdown={true}
                showYearDropdown={true}
                onChange={(date) => setStartDate(date)}
              />
              <Form.Input
                name={type + "_" + hid}
                type="hidden"
                value={startDate == "" ? value : startDate}
              />
            </span>
          ) : (
            ""
          )}
        </div>
      </div>
    ));
  };

  const FiledsArr = (e) => {
    const currentContactLabelData = obj.currentContactLabelData;
    return currentContactLabelData.map(({ title, type, hid }, idx) => (
      <span class="csvColumnFields">{title}</span>
    ));
  };

  const handleDropHere = (e) => {
    setIsdCode(e.target.value);
  };

  const onValidSubmitHere = (sendData) => {
    let loginVia=util.getCookie("loginVia");
    let fsendData=Object.assign({},sendData,{loginvia:loginVia});
    setModalFormLoader(true);
    api.marketing.addContactList(fsendData).then((data) => {
      // this.setState({ modalFormLoader: false });
      setModalFormLoader(false);

      try {
        console.log(data);
        if (data.status == false && data.message == "alreadyExist") {
          setModalErrorMsg("block");
          setTimeout(function () {
            setModalErrorMsg("none");
          }, 5000);
          document.getElementById("AddEditContactFormModalBody").scroll({
            top: 0,
            behavior: "smooth", // 👈
          });
        } else {
          if (data.status == false) {
            setFormError(data.data);
            setModalErrorMsg1('block');
            setTimeout(function () {
              setModalErrorMsg1("none");
            }, 5000);
          } else {
            onValidSubmit(sendData);
          }
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const  checkEmail = (key) => {
    if (key.target.value.length == 0) {
      setDuplicateError('');
      return false;
    }
    let sendData = {
      email: key.target.value,
      modalType: 'Add',
      bid: bid,
    };
    api.marketing.checkEmail(sendData).then((data) => {
      try {

        if (data.data == 123) {
         setDuplicateError("This email id is invalid")
        } else if (data.data > 0 && data.data != 123) {
          setDuplicateError("This email id is already exits");
        } else {
          setDuplicateError("")
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const onValidSubmitEditHere = (sendData) => {
    sendData["bid"] = bid;
    sendData["cid"] = currentContactData.cid;
    sendData["user_id"] = userdata.user_id;
    sendData["source"] = "static";

    // console.log(sendData)
    // return;
    setModalFormLoader(true);
    api.marketing.editContact(sendData).then((data) => {
      // this.setState({ modalFormLoader: false });
      setModalFormLoader(false);

      try {
        console.log(data);
        if (data.status == false && data.message == "alreadyExist") {
          setModalErrorMsg("block");
          setTimeout(function () {
            setModalErrorMsg("none");
          }, 5000);
          document.getElementById("AddEditContactFormModalBody").scroll({
            top: 0,
            behavior: "smooth", // 👈
          });
        } else {
          onValidSubmit(sendData);
        }
      } catch (err) {
        console.log(err);
      }
    });
    // onValidSubmit(e)
  };

  const onValidSubmitHereForCSV = (e) => {
    let loginVia=util.getCookie("loginVia");
    if (csvErrorDisplay == "block") {
      return true;
    }

    if (csvFileValue == "") {
      setCsvErrorDisplay("block");
      setCsvErrorMsg("Please upload csv file.");
      return true;
    }

    var myform = document.getElementById("AddEditContactFormModalCSV");
    var formData = new FormData(myform);

    formData.append("bid", bid);
    formData.append("user_id", userdata.user_id);
    formData.append("loginvia", loginVia);
    setModalFormLoader(true);
    api.marketing.addContactListByCSV(formData).then((data) => {
      // this.setState({ modalFormLoader: false });
      setModalFormLoader(false);
      try {
        if (
          data.data.status == false &&
          data.data.message == "headingMismatch"
        ) {
          setModalErrorMsgCSVMessage(
            "CSV heading are not matching as given below titles."
          );
          setModalErrorMsgCSV("block");
          setTimeout(function () {
            setModalErrorMsgCSV("none");
          }, 5000);
          document.getElementById("AddEditContactFormModalBody").scroll({
            top: 0,
            behavior: "smooth", // 👈
          });
        }else if(
					data.data.status === false &&
					data.data.message ==  "limitExceeds"
				) {
					setModalErrorMsgCSVMessage(
						data.data.data
					);
					setModalErrorMsgCSV("block");
					setTimeout(function () {
						setModalErrorMsgCSV("none");
					}, 5000);
					document.getElementById("AddEditContactFormModalBody").scroll({
						top: 0,
						behavior: "smooth", // 👈
					});
				} else {
          document.getElementById("file-upload").value = "";

          setCsvErrorDisplay("none");
          setBrowse("Browse");
          setCsvFileValue("");
          onValidSubmitCSV(data.data);
        }
      } catch (err) {
        console.log(err);
      }
    });
  };

  const modalCloseHere = (e) => {
    setCsvErrorDisplay("none");
    setBrowse("Browse");
    setCsvFileValue("");
    modalClose(false, "AddEditContactFormModal");
    try {
      document.getElementById("file-upload").value = "";
    } catch (e) { }
  };

  const changeFileInputCSV = (e) => {
    setCsvErrorDisplay("none");

    try {
      var fileName = e.target.files[0].name;
      var index = fileName.split(".")[fileName.split(".").length - 1];
      if (index.toLowerCase() == "csv") {
      } else {
        setCsvErrorDisplay("block");
        setCsvErrorMsg("Please upload only csv file.");
      }
      setCsvFileValue(e.target.files[0].name);
      setBrowse(e.target.files[0].name);
    } catch (e) {
      setBrowse("Browse");
      setCsvFileValue("");
    }
    // console.log(e.target.files[0].name)
  };

  return (
    <React.Fragment>
      <div
        className={show ? "modal fade show" : "modal fade"}
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: show ? "block" : "none" }}
        id="AddEditContactModal"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={() => modalCloseHere()}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{title}</h5>
            </div>
            {modalType == "Add" ? (
              opt == "static" ? (
                <Form
                  noValidate
                  autoComplete="off"
                  id="AddEditContactFormModal"
                  onValidSubmit={onValidSubmitHere}
                >
                  {modalFormLoader && <div className="ui loading form"></div>}
                  <div
                    id="AddEditContactFormModalBody"
                    className="modal-body"
                    style={mystyle}
                  >
                    <div className="kt-portlet__body py-0">
                      <div className="kt-section kt-section--first">
                        <Message color="red" style={{ display: modalErrorMsg }}>
                          This email-id already exists.
                        </Message>
                        <Message color="red" style={{ display: modalErrorMsg1 }}>
                          {formError}
                        </Message>
                        <div className="kt-section__body">
                          {drawLabelAndFields()}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="kt-portlet__foot">
                    <div className="kt-form__actions">
                      <div className="row">
                        <div className="col-lg-12 d-flex justify-content-end">
                          <button
                            type="button"
                            onClick={() => modalClose(false, "formManageUser")}
                            className="btn linear-gradient-cancel yr-cancelbtn mg-r"
                          >
                            <span> Cancel </span>
                          </button>
                          <button type="submit" className="btn linear-gradient yr-submitbtn">
                            {submitBtnName}
                          </button>
                          <Form.Input type="Hidden" name="bid" value={bid} />
                          <Form.Input
                            type="Hidden"
                            name="user_id"
                            value={userdata.user_id}
                          />
                          <Form.Input
                            type="Hidden"
                            name="source"
                            value={"static"}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              ) : (
                <Form
                  noValidate
                  autoComplete="off"
                  id="AddEditContactFormModalCSV"
                  onValidSubmit={onValidSubmitHereForCSV}
                >
                  <div class="kt-portlet__body">
                    <div class="kt-section kt-section--first">
                      <div class="kt-section__body" style={{ padding: 20 }}>
                        <Message
                          color="red"
                          style={{ display: modalErrorMsgCSV }}
                        >
                          {modalErrorMsgCSVMessage}
                        </Message>
                        <div class="form-group row">
                          <label
                            class="col-lg-12 col-form-label"
                            style={{ color: "#20536b" }}
                          >
                            Please browse CSV. Before uploading CSV please make
                            sure your CSV must have column heading and your
                            column heading must match below titles.{" "}
                          </label>
                          <label
                            class="col-lg-12"
                            style={{ maxHeight: 104, overflow: "auto" }}
                          >
                            {/* {<span class="csvColumnFields">Email</span>} */}
                            {/* <span class="csvColumnFields">Isd Code</span>
                          <span class="csvColumnFields">Phone Number</span> */}
                            {FiledsArr()}
                          </label>
                        </div>

                        <div class="form-group row automatedMarketing">
                          <div class="col-lg-12">
                            <div class="yr-workspace">
                              <div class="uploadfile">
                                <label
                                  for="file-upload"
                                  class="custom-file-upload"
                                >
                                  <i class="fa fa-cloud-upload"></i>
                                  <div class="mh-CSVbrowse" title={browse}>
                                    {browse}
                                  </div>
                                  <div
                                    class="mh-CSVbrowseError"
                                    style={{ display: csvErrorDisplay }}
                                  >
                                    {csvErrorMsg}
                                  </div>
                                </label>
                                <input
                                  id="file-upload"
                                  onChange={changeFileInputCSV}
                                  name="contact_csv"
                                  onClick={(event)=> { 
               event.target.value = null
          }}
                                  type="file"
                                  style={{ display: "none" }}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="kt-portlet__foot">
                    <div class="kt-form__actions">
                      <div class="row">
                        <div class="col-lg-12 d-flex justify-content-end">
                          <button
                            onClick={() => modalCloseHere()}
                            type="reset"
                            class="btn yr-cancelbtn linear-gradient-cancel mg-r"
                          >
                            <span> Cancel </span>
                          </button>
                          <button type="submit" class="btn linear-gradient yr-submitbtn">
                            {submitBtnName}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )
            ) : (
              <Form
                noValidate
                autoComplete="off"
                id="AddEditContactFormModalEdit"
                onValidSubmit={onValidSubmitEditHere}
              >
                {modalFormLoader && <div className="ui loading form"></div>}
                <div
                  id="AddEditContactFormModalBody"
                  className="modal-body"
                  style={mystyle}
                >
                  <div className="kt-portlet__body py-0">
                    <div className="kt-section kt-section--first">
                      <Message color="red" style={{ display: modalErrorMsg }}>
                        This email-id already exists.
                      </Message>
                      <div className="kt-section__body">
                        <div className="form-group row">
                          <label className="col-lg-4 col-form-label">
                            Email1 :
                          </label>
                          <div className="col-lg-8">
                            <Form.Input
                              fluid
                              autoComplete="off"
                              name="email"
                              value={currentContactData.email}
                              placeholder=""
                              validations="isEmail,isRequired"
                              onBlur={checkEmail}
                              validationErrors={{
                                isEmail: "Please enter a valid email address.",
                                isRequired: "This field is required.",
                              }}
                              errorLabel={errorLabel}
                            />
                            {obj.duplicateEmailError && (
                              <div class="red" style={redButton}>
                                {obj.duplicateEmailError}.
                              </div>
                            )}
                          </div>
                        </div>
                        {/* <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                          Phone Number :
                        </label>
                        <div className="col-lg-4">
                        <select class="form-control Country_code"  name="isd_code" onChange={handleDropHere}  id="isd_code_id_review" >                                                            
                        {renderDropDownDefault(defaultCountryCodeArr, currentContactData.isd_code) }
                        <optgroup label="Other countries">
                        {renderDropDownRemaining(remailCountryCodeArr,currentContactData.isd_code) } 
                        </optgroup>
                         </select>
                         <Form.Input type="Hidden" name="isd_code" value={isdCode} />
                        </div>
                        <div className="col-lg-4">
                          <Form.Input
                            fluid
                            name="phone_number"
                            value={obj.phone_number}
                            placeholder=""
                            validations="isRequired,usPhoneLength" 
                            onChange={checkPhone}
                            validationErrors={{
                              isRequired: "This field is required.",
                              usPhoneLength:"Phone number must be at least 10 numbers."
                            }}
                            errorLabel={errorLabel}
                          />
                          {obj.duplicatePhoneError && (
                            <div class="red" style = {redButton}>{obj.duplicatePhoneError}.</div>
                          )}
                        </div>
                      </div> */}
                        {drawLabelAndFieldsOnEdit()}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="kt-portlet__foot">
                  <div className="kt-form__actions">
                    <div className="row">
                      <div className="col-lg-12 d-flex justify-content-end">
                        <button
                          type="button"
                          onClick={() => modalClose(false, "formManageUser")}
                          className="btn linear-gradient-cancel yr-cancelbtn mg-r"
                        >
                          <span> Cancel </span>
                        </button>
                        <button type="submit" className="btn linear-gradient yr-submitbtn">
                          {submitBtnName}
                        </button>
                        {/* <Form.Input type="Hidden" name="cid" value={currentContactData.cid} />
                      <Form.Input type="Hidden" name="bid" value={bid} />
                      <Form.Input type="Hidden" name="user_id" value={userdata.user_id} />
                      <Form.Input type="Hidden" name="source" value={"static"} /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default DialogBoxAddEdit;
