import React from "react";
import { Link, Redirect } from "react-router-dom";
import api from "../../../../../api";
import "../../../../Grid/grid.css";
import { Segment, Table, Pagination } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import ChatFilter from "./ChatFilter";
import DialogBoxDetail from "./DialogBoxDetail";
import DatatableDeleteBox from "./DatatableDeleteBox";
import DialogBoxAddEdit from "./DialogBoxAddEdit";
import { DatatableRow } from "./DatatableRow";
import { DatatablePageSizeSelect } from "./DatatablePageSizeSelect";
import Modal from "../../../ChatSetup/components/Modal";
import CustomErrorField from "../../../../Form/CustomErrorField";

import { CSVLink, CSVDownload } from "react-csv";
import utilFunc from "../../../../../util_funs";
import DialogContactDetail from "./DialogContactDetail";
import DialogBoxContactVia from "./DialogBoxContactVia";
import { Message } from "semantic-ui-react";
import DialogBoxDetailOnCSVUploadError from "./DialogBoxDetailOnCSVUploadError";
import DialogBoxContactViaZapier from "../ContactList/dataTables/DialogBoxContactViaZapier"; // added by Sagar
import DialogBoxContactViaZapierMsg from "../ContactList/dataTables/DialogBoxContactViaZapierMsg"; // added by Sagar

const tableHeader = [
  {
    title: "",
    width: "1",
    sort: "",
  },
  {
    title: "",
    width: "1",
    sort: "",
  },
  {
    title: "",
    width: "1",
    sort: "",
  },
  {
    title: "",
    width: "3",
    sort: "",
  },
  {
    title: "",
    width: "2",
    sort: "",
  },
  {
    title: "",
    width: "1",
    sort: "",
  },
  ,
];
const queryParams = [
  "_limit",
  "_order",
  "_sort",
  "q",
  "_page",
  "title",
  "added_datetime",
];
const APIURL = "http://localhost/zyratalk/api/premium/settings/manageuser/";
export default class DatatableList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lists: [],
      _sort: "bid",
      _page: 1,
      _order: "desc",
      _limit: 10,
      q: "",
      title: "",
      added_datetime: "",
      startdaterange: "",
      enddaterange: "",
      totalCount: 0,
      loading: false,
      modalstatus: false,
      modalstatusDelete: false,
      modaltitle: null,
      modalbuttonmsg: {},
      modalType: null,
      datatableHeader: null,
      datatableRows: null,
      modalErrorMsg: false,
      modalstatusAddEdit: false,
      modaldata: {},
      modalFormLoader: false,
      modalbody: "",
      modalfooter: "",
      datatableHeader: null,
      datatableRows: null,
      auto_id: "",
      csvdata: [],
      rename: "",
      modal_id: "",
      errors: [],
      btype: '',
      modaldetailstatus: false,
      modalContactVia: false,
      modalContactViaObject: {
        show: false,
        title: "Please select one of the below option",
        loading: false,
      },
      currentContactLabelData: [],
      userToaster: false,
      userToasterMsg: "",
      userToasterColor: "",
      modalstatusCSVfailure: false,
      csvFailArr: [],
      currentContactLabelDataOnEdit: [],
      currentContactLabelDetailDataOnEdit: [],
      //sagar
      modalContactViaObjectZapier:{
        show: false,
        title: "Please select one of the below option",
        loading: false,
      },
      modalContactViaObjectZapierMsg: {
        showMsg: false,
        title: "Please select one of the below option",
        loading: false,
      },
      webhooks_title: "",
      webhooks_url: "",
      checkedOnOFF: "OFF",
      switchValue: "",
      webhooks_id: null,
      phoneNumberValue: '',
      val_on_off:""
      //sagar end
    };
    this.csvLink = React.createRef();
  }

  componentDidMount() {
    this.loadData({});
  }

  componentDidUpdate(prevProps) {
    if (this.props.bucketList !== prevProps.bucketList) {
      this.loadData({})
    }
  }

  static directionConverter(order) {
    if (order === "asc") {
      return "ascending";
    } else if (order === "desc") {
      return "descending";
    } else {
      return null;
    }
  }

  /*
  handleSort = (clickedColumn) => {
    const { _sort, _order } = this.state;

    let newOrder = _order === "asc" ? "desc" : "asc";
    if (_sort !== clickedColumn) {
      newOrder = "asc";
    }

    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: newOrder,
    });
  };
*/
  


  handleSort = (clickedColumn, order) => {
    this.loadData({
      _sort: clickedColumn,
      _page: 1,
      _order: order,
    });
  };

  checkNumberFormat = (e) => {
		this.setState({
			phoneNumberValue: e.target.value
		})
		this.phoneFormat(e);
	}

	phoneFormat = (e) => {
		let phone = e.target.value;
		let p = phone;
		var obj = this;
		p = p.replace(/[^\d]*/gi, "");
		if (p.length < 3) {
			setTimeout(function () {
				obj.setState({ phoneNumberValue: p });
			}, 100);
		} else if (p.length == 3) {
			let pp = p;
			let d4 = p.indexOf("(");
			let d5 = p.indexOf(")");
			if (d4 == -1) {
				pp = "(" + pp;
			}
			if (d5 == -1) {
				pp = pp + ")";
			}
			obj.setState({ phoneNumberValue: pp });
		} else if (p.length > 3 && p.length < 7) {
			p = "(" + p;
			let l30 = p.length;
			let p30 = p.substring(0, 4);
			p30 = p30 + ")";

			let p31 = p.substring(4, l30);
			let pp = p30 + p31;

			setTimeout(function () {
				obj.setState({ phoneNumberValue: pp });
			}, 100);
		} else if (p.length >= 7) {
			p = "(" + p;
			let l30 = p.length;
			let p30 = p.substring(0, 4);
			p30 = p30 + ")";

			let p31 = p.substring(4, l30);
			let pp = p30 + p31;

			let l40 = pp.length;
			let p40 = pp.substring(0, 8);
			p40 = p40 + "-";

			let p41 = pp.substring(8, l40);
			let ppp = p40 + p41;
			let maxphonelength = 13;
			let finalPhone = ppp.substring(0, maxphonelength);
			setTimeout(function () {
				obj.setState({ phoneNumberValue: finalPhone });
			}, 100);
		}
	};

  onChangeLimit = (event, data) => {
    if (data.value !== this.state._limit) {
      this.loadData({ _limit: data.value, _page: 1 });
    }
  };

  onSubmitFilter = (filter, val) => {
    console.log(filter,val);
    this.loadData({ [filter]: val, _page: 1 });
  };

  onChangePage = (event, data) => {
    const { activePage } = data;
    if (activePage !== this.state._page) {
      this.loadData({ _page: activePage });
    }
  };

  //ANKIT START
  modalCloseContactBox = (flag, fm) => {
    this.setState({
      modaldetailstatus: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
    });
  };

  addContact = (bid,btype) => {
    //alert(bid);
    let modalContactViaObject = {
      show: true,
      title: "Add contact via",
      loading: false,
    };
    this.setState({
      modalContactViaObject: modalContactViaObject,
      bid: bid,
      phoneNumberValue: '',
      btype:btype
    });
  };
  modalCloseContactViaBox = () => {
    let modalContactViaObject = {
      show: false,
      title: "Please select one of the below option",
      loading: false,
    };
    this.setState({
      modalContactViaObject: modalContactViaObject,
      btype:""
    });
  };

  openContactAddEdit = (opt) => {
    this.setState({ loading: true });
    var submitBtnName = "Save";
    if (opt == "csv") var submitBtnName = "Submit";
    api.marketing
      .getContactLabel({
        bid: this.state.bid,
        user_id: this.props.userdata.user_id
      })
      .then((data) => {
        this.modalCloseContactViaBox();
        this.setState({
          modalstatusAddEdit: true,
          modaltitle: "Add Contact",
          modalType: "Add",
          modaldata: { opt: opt, submitBtnName: submitBtnName },
          selectedOptions: [],
          currentContactLabelData: data.data,
          loading: false,
          btype: data.type
        });


        if (opt == "static")
          document.getElementById("AddEditContactFormModal").reset();
      });
  };
/* Saagr */
addContactZapier = (opt) => {
  this.setState({ loading: true });
  this.modalCloseContactViaBox();
  api.marketing
    .getwebhookZapier({
      bid: this.state.bid,
      user_id: this.props.userdata.user_id,

    })
    .then((data) => {
      this.setState({ loading: false });
      //alert(data.checkedOnOFF);
      //document.getElementById("AddEditContactFormModal").reset();
      let modalContactViaObjectZapier = {
        show: true,
        title: "Add contact via zapier",
        loading: false,
      };
      this.setState({
        modalContactViaObjectZapier: modalContactViaObjectZapier,
        webhooks_title: data.webhooks_title,
        webhooks_url: data.webhooks_url,
        checkedOnOFF: data.checkedOnOFF,
        switchValue: data.switchValue,
        webhooks_id: data.webhooks_id,
      });
    });
};

changeSwitch = (e) => {
  let val = "";
  if (e.target.value == "ON") {
    val = "OFF";
    api.marketing
      .checkwebhooksUrl({
        bid: this.state.bid,
        user_id: this.props.userdata.user_id,
        val_on_off: val,
      })
      .then((data) => {
        this.setState({ switchValue: val, checkedOnOFF: "" });
      });
  } else {
    val = "ON";
    //alert('Hi');
    let modalContactViaObjectZapierMsg = {
      showMsg: true,
      title: "Add contact via zapier",
      loading: false,
    };
    api.marketing
      .checkwebhooksUrl({
        bid: this.state.bid,
        user_id: this.props.userdata.user_id,
        val_on_off: val,
      })
      .then((data) => {
        this.setState({
          switchValue: val,
          checkedOnOFF: "checked",
          modalContactViaObjectZapierMsg: modalContactViaObjectZapierMsg,
        });
      });
  }
};

saveWebhooks = (webhookTitle, webhooksId) => {
  //alert(webhookTitle);
  api.marketing
    .savecontactZapier({
      webhook_title: webhookTitle,
      webhook_id: webhooksId,
    })
    .then((data) => {
    
      this.modalCloseContactViaZapier();
      this.showUserToaster("green", "Save successfully.");
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    });
};

onChangesWebhooks = (e) => {
  this.setState({
    webhooks_title: e.target.value,
  });
};

modalCloseContactViaZapier = () => {
  let modalContactViaObjectZapier = {
    show: false,
    title: "",
    loading: false,
  };
  this.setState({
    modalContactViaObjectZapier: modalContactViaObjectZapier,
  });
};

modalCloseContactViaZapierMsg = () => {
  let modalContactViaObjectZapierMsg = {
    showMsg: false,
    title: "",
    loading: false,
  };
  this.setState({
    modalContactViaObjectZapierMsg: modalContactViaObjectZapierMsg,
  });
};


offAginMsg = () => {
  this.modalCloseContactViaZapierMsg();
  document.getElementById("ctl00_bc_custom_element_43").click();
  //this.changeSwitchMsg('OFF');
  //document.getElementById("ctl00_bc_custom_element_43").checked = true
  //this.setState({ switchValue: 'OFF', checkedOnOFF: '' });
};

/* End of code */
  editContact = (id) => {
    let recordlist = this.state.lists.findIndex((list) => list.cid === id);
    let modaldata = this.state.lists[recordlist];
    console.log(modaldata);
    let editDateModule = [];
    let moduleList = this.state.accessModuleList;

    moduleList.forEach((item, index) => {
      Object.keys(item).forEach(function (key) {
        if (modaldata.linksetting.includes(key)) editDateModule.push(key);
      });
    });
    this.setState({
      country_code: modaldata.country_code,
    });

    this.setState({
      phone_number: modaldata.phone_number,
    });
    if (modaldata.notification_text == "1") {
      this.setState({
        notification_text: 1,
      });
    } else {
      this.setState({
        notification_text: 0,
      });
    }

    if (modaldata.notification_email == "1") {
      this.setState({
        notification_email: 1,
      });
    } else {
      this.setState({
        notification_email: 0,
      });
    }
    

    api.marketing
      .getContactLabelAndDataOnEdit({
        bid: this.state.bid,
        user_id: this.props.userdata.user_id,
        cid: id,
      })
      .then((data) => {
        this.setState({
          modalstatusAddEdit: true,
          modaltitle: "Edit Contact",
          modalType: "Edit",
          modaldata: modaldata,
          modaldata: { opt: "static", submitBtnName: "Update" },
          selectedOptions: editDateModule,
          currentContactLabelDataOnEdit: data.data.data.contact[0],
          currentContactLabelDetailDataOnEdit: data.data.data.contactDetail,
          phone_number: data.data.data.contact[0].phone,
        });
      });
  };

  modalClose = (flag, fm) => {
    // document.getElementById("isd_code_id_review").selectedIndex = 0;
    // document.getElementById("isd_code_id_review_sms").selectedIndex = 0;
    this.setState({
      modalstatus: false,
      modalstatusAddEdit: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
      selectedOptions: [],
      duplicateEmailError: "",
      duplicatePhoneError: "",
      currentContactLabelData: [],
      currentContactLabelDetailDataOnEdit: [],
    });
  };

  modalCloseDeleteBox = (flag, fm) => {
    this.setState({
      modalstatusDelete: false,
      modaltitle: "",
      modalmessage: "",
      modalbuttonmsg: {},
      modalType: "",
      modaldata: {},
    });
  };

  showUserToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ userToaster: false });
      }.bind(this),
      5000
    );
  };

  showCustomToaster = (color, msg) => {
    this.setState({
      userToaster: true,
      userToasterMsg: msg,
      userToasterColor: color,
    });
  };

  onValidSubmit = (sendData) => {
    if (this.state.modalType == "Add") {
      var $msg = "User has been added successfully.";
    } else {
      var $msg = "User has been updated successfully.";
    }
    this.loadData({});
    this.setState({
      modalstatusAddEdit: false,
      currentContactLabelData: [],
      currentContactLabelDetailDataOnEdit: [],
    });
    this.showUserToaster("green", $msg);
    utilFunc.scrollTop(800);
  };

  onValidSubmitCSV = (e) => {
    this.loadData({});

    this.setState({
      modalstatusAddEdit: false,
    });

    if (e.failStatus == false) {
      this.showUserToaster("green", "CSV User has been added successfully.");
      utilFunc.scrollTop(800);
    } else {
       if(e.failArray.length>0){
      this.setState({
        modalstatusCSVfailure: true,
        modaltitle: "CSV upload error",
        modalmessage: "Are you sure you want to delete?",
        modalbuttonmsg: { submit: "Yes", cancel: "No" },
        modalType: "Details",
        csvFailArr: e.failArray,
      });
    }
    }
  };
  modalCloseCSVError = (e) => {
    this.setState({
      modalstatusCSVfailure: false,
    });
  };
  //ANKIT END

  loadData = (params) => {
    console.log(params)
    const newState = Object.assign({}, this.state, params, { loading: false });
    console.log(newState);
    queryParams.forEach(function (element) {
      if (!(element in params)) {
        params[element] = newState[element];
      }
    });

    let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
    let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
    let time_zone = clientTimeZone;

    params["time_zone"] = time_zone;

    const esc = encodeURIComponent;
    const query = Object.keys(params)
      .map((k) => esc(k) + "=" + esc(params[k]))
      .join("&");

    let totalCountQuery = "";
    if (params.q !== "") {
      totalCountQuery = `q=${params.q}`;
    }
    this.setState(newState, () => {
      this.setState({ loading: true });
      api.marketing.getbucket(query).then((data) => {
        try {
          let datatableRowstemp;
          this.setState({ lists: data.data.list });

          if (data.data.totalrecord) {
            datatableRowstemp = this.state.lists.map((list, index) => (
              <DatatableRow
                channgeEvent={this.checkboxChangeEvent}
                openContactList={this.openContactList}
                key={index}
                list={list}
                bucketAction={this.bucketAction}
                editContact={this.editContact}
                addContact={this.addContact}
                userdata={this.props.userdata}
              />
            ));
          } else {
            datatableRowstemp = (
              <Table.Row>
                <Table.Cell colSpan={7} textAlign="center">
                  No record found.
                </Table.Cell>
              </Table.Row>
            );
          }
          this.setState({
            datatableRows: datatableRowstemp,
            totalCount: data.data.totalrecord,
            lists: data.data.list,
          });
        } catch (err) {
          console.log(err);
        }
        const newState = Object.assign({}, this.state, params, {
          loading: false,
        });
        this.setState(newState);
      });
    });
  };

  checkAll = (e) => {
    let datalist = this.state.lists;
    datalist.map(function (list, index) {
      list["checked"] = e.target.checked;
    });
    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          openContactList={this.openContactList}
          key={index}
          list={list}
          bucketAction={this.bucketAction}
        />
      )),
      lists: datalist,
    });
  };

  checkboxChangeEvent = (auto_id) => {
    let datalist = this.state.lists;
    let recordlist = datalist.findIndex((list) => list.auto_id === auto_id);

    if (datalist[recordlist]["checked"]) {
      datalist[recordlist]["checked"] = false;
    } else {
      datalist[recordlist]["checked"] = true;
    }

    this.setState({
      datatableRows: datalist.map((list, index) => (
        <DatatableRow
          channgeEvent={this.checkboxChangeEvent}
          openContactList={this.openContactList}
          key={index}
          list={list}
        />
      )),
      lists: datalist,
    });
  };

  bucketAction = (tab, id) => {
    if (tab == "edit") {
      let list_data = this.state.lists;
      let recordid = list_data.findIndex((list_data) => list_data.bid == id);

      this.setState(
        {
          errors: [],
          rename: list_data[recordid]["title"],
          modaltitle: "Rename Bucket",
          modalstatus: true,
          modal_id: id,
          modalfooter: this.modalFooter("", "edit"),
        },
        function () {
          let modalbody = this.modalBody("edit");

          this.setState({ modalbody: modalbody });
        }
      );
    }

    if (tab == "delete") {
      this.setState({
        errors: [],
        modalstatus: true,
        modal_id: id,
        modaltitle: "Confirm your action",
        modalfooter: this.modalFooter("", "delete"),
        modalbody: "Deleting the bucket will remove all future scheduled emails. Are you sure, you want to delete it?",
      });
    }
  };

  // modalClose = () => {
  //   this.setState({
  //     modalstatus: false,
  //   });
  // };

  modalFooter = (id, type) => {
    if (type == "edit") {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            onClick={() => this.updateBucketName(this.state.modal_id)}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Save</span>
          </button>
        </div>
      );
    }
    if (type == "delete") {
      return (
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => this.modalClose()}
            className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
            data-dismiss="modal"
          >
            <span>Cancel</span>
          </button>
          <button
            type="button"
            onClick={() => this.deleteBucketName(this.state.modal_id)}
            className="btn linear-gradient yr-submitbtn btn-sm"
          >
            <span>Delete</span>
          </button>
        </div>
      );
    }
  };

  modalBody = (tab) => {
    if (tab == "edit") {
      //alert( this.state.rename)
      let time = Date.now();
      //this.setState({rename:})
      const { rename } = this.state;
      return (
        <div className="col-lg-12 col-md-12 col-sm-12">
          <label className="form-control-label">
            Title <span>*</span>
          </label>
          <input
            type="text"
            className={
              Object.keys(this.state.errors).length > 0 &&
                typeof this.state.errors["rename_data"] != "undefined"
                ? "form-control is-invalid focusError"
                : "form-control"
            }
            key={time}
            placeholder="Enter Summary"
            defaultValue={rename}
            onChange={(e) => this.onChangeInput("rename", e)}
          />
          {Object.keys(this.state.errors).length > 0 &&
            typeof this.state.errors["rename_data"] != "undefined" && (
              <CustomErrorField
                message={this.state.errors["rename_data"]}
                id="help-error"
                className="error invalid-feedback"
              />
            )}
        </div>
      );
    }
    if (tab == "delete") {
      return "Are you sure";
    }
  };

  onChangeInput = (type, data) => {
    this.setState({ rename: data.target.value });
  };
  updateBucketName = (id) => {
    let error = [];
    if (this.state.rename == "") {
      error["rename_data"] = "This field is required";
    }
    if (Object.keys(error).length > 0) {
      this.setState({ errors: error }, function () {
        let modalbody = this.modalBody("edit");
        this.setState({ modalbody: modalbody });
      });
    } else {
      this.setState({ loading: true });

      api.marketing
        .updateBucketName({ bid: id, title: this.state.rename })
        .then((data) => {
          try {
            if (data.status == false) {
              let error = [];
              error["rename_data"] = data.message;
              this.setState({ errors: error, loading: false }, function () {
                let modalbody = this.modalBody("edit");
                this.setState({ modalbody: modalbody });
              });
            } else {
              let list_data = this.state.lists;
              let recordid = list_data.findIndex(
                (list_data) => list_data.bid == id
              );
              list_data[recordid].title = this.state.rename;

              this.setState(
                {
                  loading: false,
                  lists: list_data,
                  modalstatus: false,
                  modal_id: "",
                },
                function () {
                  let datatableRowstemp = this.state.lists.map(
                    (list, index) => (
                      <DatatableRow
                        channgeEvent={this.checkboxChangeEvent}
                        openContactList={this.openContactList}
                        key={index}
                        list={list}
                        bucketAction={this.bucketAction}
                        userdata={this.props.userdata}
                      />
                    )
                  );
                  this.setState({ datatableRows: datatableRowstemp });
                }
              );
            }
          } catch (err) {
            console.log(err);
          }
        });
    }
  };

  deleteBucketName = (id) => {
    api.marketing.deleteBucketName({ bid: id }).then((data) => {
      try {
        console.log("data after api", data);
        if (data.status) {
          let list_data = this.state.lists;
          let recordid = list_data.findIndex((list_data) => list_data.bid == id);
          list_data.splice(recordid, 1);
          this.setState(
            {
              loading: false,
              lists: list_data,
              modalstatus: false,
              modal_id: "",
              userToaster: true,
              userToasterMsg: data.message,
            },
            function () {
              let datatableRowstemp = this.state.lists.map((list, index) => (
                <DatatableRow
                  channgeEvent={this.checkboxChangeEvent}
                  openContactList={this.openContactList}
                  key={index}
                  list={list}
                  bucketAction={this.bucketAction}
                />
              ));
              this.setState({ datatableRows: datatableRowstemp });
            }
          );
        } else {
          this.setState(
            {
              loading: false,
              modalstatus: false,
              modal_id: "",
              userToaster: true,
              userToasterMsg: data.message,
              userToasterColor: "red",
            })
        }
        utilFunc.scrollTop(800);
        setTimeout(() => {
          this.setState({
            userToaster: false,
            userToasterMsg: "",
            userToasterColor: "",
          });
        }, 4000);
      } catch (err) {
        console.log(err);
      }
    });
  };

  render() {
    return (
      <Segment style={{ width: "100%", maxWidth: "1280px" }}>
        <Message
          color={
            this.state.userToasterColor ? this.state.userToasterColor : "green"
          }
          style={{
            display: this.state.userToaster ? "block" : "none",
            marginTop: 30,
          }}
        >
          {this.state.userToasterMsg}
        </Message>
        {this.state.loading && <div className="ui loading form"></div>}
        <ChatFilter
          filter={{
            chatstatus: this.state.chatstatus,
            chatfrequency: this.state.chatfrequency,
            strsearch: this.state.q,
            startdaterange: this.state.startdaterange,
            enddaterange: this.state.enddaterange,
          }}
          isST={this.props.isServiceTitan}
          handleSort={this.handleSort}
          totalCount={this.state.totalCount}
          onSubmitFilter={this.onSubmitFilter}
          loading={this.state.loading}
          csvdata={this.state.csvdata}
          createBucket={this.props.createBucket}
          createServiceTitanBucket={this.props.createServiceTitanBucket}
        />
        <DatatableTable
          lists={this.state.lists}
          datatableHeader={tableHeader}
          datatableRows={this.state.datatableRows}
          totalCount={this.state.totalCount}
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          currentPage={this.state._page}
          onChangePage={this.onChangePage}
          column={this.state._sort}
          direction={DatatableList.directionConverter(this.state._order)}
          handleSort={this.handleSort}
          onChangeLimit={this.onChangeLimit}
          limit={this.state._limit.toString()}
          checkAll={this.checkAll}
        />
        {/* <DialogBoxDetail
          show={this.state.modalstatus}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalClose={this.modalClose}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
        /> */}
        <DatatableDeleteBox
          show={this.state.modalstatusDelete}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalCloseDeleteBox={this.modalCloseDeleteBox}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
        />
        <CSVLink
          id="chatcsv"
          data={this.state.csvdata}
          filename="chat.csv"
          className="hidden"
          target="_blank"
        />
        <DialogBoxAddEdit
          obj={this.state}
          type={this.props.type}
          modalClose={this.modalClose}
          onValidSubmit={this.onValidSubmit}
          onValidSubmitCSV={this.onValidSubmitCSV}
          handleCheck={this.handleCheck}
          checkEmail={this.checkEmail}
          checkPhone={this.checkPhone}
          checkPhoneSms={this.checkPhoneSms}
          checkNumberFormat={this.checkNumberFormat}
          handleDrop={this.handleDrop}
          handleDropSms={this.handleDropSms}
          onImageLoaded={this.onImageLoaded}
          onCropComplete={this.onCropComplete}
          onCropChange={this.onCropChange}
          handleFile={this.handleFile}
          getCroppedImg={this.getCroppedImg}
          handleRemovePic={this.handleRemovePic}
          handleRemovePicAdd={this.handleRemovePicAdd}
          userdata={this.props.userdata}
          phoneNumberValue={this.state.phoneNumberValue}
          bid={this.state.bid}
        />
        <DialogContactDetail
          show={this.state.modaldetailstatus}
          title={this.state.modaltitle}
          detail_data={this.state.detail_data}
          modalClose={this.modalCloseContactBox}
          loading={this.state.loading}
        />
        <DialogBoxContactVia
          obj={this.state}
          modalCloseContactViaBox={this.modalCloseContactViaBox}
          openContactAddEdit={this.openContactAddEdit}
          addContactZapier={this.addContactZapier}            //sagar 
        />

        {/* Added by Sagar */}
        <DialogBoxContactViaZapier
          obj={this.state}
          modalCloseContactViaZapier={this.modalCloseContactViaZapier}
          changeSwitch={this.changeSwitch}
          saveWebhooks={this.saveWebhooks}
          onChangesWebhooks={this.onChangesWebhooks}
          webhooks_title={this.state.webhooks_title}
          switchValue={this.state.switchValue}
          checkedOnOFF={this.state.checkedOnOFF}
          webhooks_url={this.state.webhooks_url}
          webhooks_id={this.state.webhooks_id}
        />
        <DialogBoxContactViaZapierMsg
          obj={this.state}
          modalCloseContactViaZapierMsg={this.modalCloseContactViaZapierMsg}
          offAginMsg={this.offAginMsg}
        />
        {/* End of code */}

        <DialogBoxDetailOnCSVUploadError
          obj={this.state}
          show={this.state.modalstatusCSVfailure}
          title={this.state.modaltitle}
          message={this.state.modalmessage}
          modalType={this.state.modalType}
          modalCloseCSVError={this.modalCloseCSVError}
          buttonmsg={this.state.modalbuttonmsg}
          modalbuttofun={this.modalbuttofun}
          loading={this.state.loading}
        />
        <DatatablePageSizeSelect
          limit={this.state._limit.toString()}
          onChangeLimit={this.onChangeLimit}
        />{" "}
        Showing 1 - {this.state._limit.toString()} of {this.state.totalCount}
        <Pagination
          totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
          activePage={this.state._page}
          onPageChange={this.onChangePage}
        />

        <Modal
          show={this.state.modalstatus}
          footer={this.state.modalfooter}
          onclose={this.modalClose}
          title={this.state.modaltitle}
          body={this.state.modalbody}
          modaltranscript={this.state.modalTranscript}
        />
      </Segment>


    );
  }
}
