import React from "react";
import { connect } from "react-redux";
import './YourReviews.css';
import { Form, Dropdown, Input } from "formsy-semantic-ui-react";
import { Message} from "semantic-ui-react";
import utilFunc from "../../../util_funs";
import config from "../../../config/index";
import RequestFormBulk from './RequestFrombulk'
import {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setSubHeaderTitle,
  setNavigationName,
  setBreadCrum,
  toggleBreadcrum,
} from "../../../store/Page";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import { Link } from "react-router-dom";
import api from "../../../api";
const queryString = require("query-string");

const bulk_option = [
  {text:"Individual", value:"0"},
  {text:"Bulk", value:"1"}
]

const phoneHeaders = ["Customer Name","Country Code","Phone Number"]
const emailHeaders = ["Customer Name","Email"]
const options_type = [
  { text: "Select", value: "" },
  { text: "Text", value: "2" },
  { text: "Email", value: "1" },
];
const options_source = [
  { text: "Select", value: "" },
  { text: "ZyraTalk", value: "1" },
  { text: "Facebook", value: "2" },
  { text: "Google", value: "3" },
  { text: "BBB", value: "4" },
];
class RequestAReview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      emailToaster: false,
      emailToasterMsg: "",
      emailToasterColor: "",
      textToaster: false,
      textToasterMsg: "",
      textToasterColor: "",
      linkToaster: false,
      linkToasterMsg: "",
      linkToasterColor: "",
      reviewToaster: false,
      reviewToasterMsg: "",
      reviewToasterColor: "",
      reviewValidTempToaster: false,
      reviewValidTempToasterMsg: "",
      reviewValidTempToasterColor: "",

      reviewValidSignToaster: false,
      reviewValidSignToasterMsg: "",
      reviewValidSignToasterColor: "",

      reviewValidFacebookToaster: false,
      reviewValidFacebookToasterMsg: "",
      reviewValidFacebookToasterColor: "",
      reviewValidGoogleToaster: false,
      reviewValidGoogleToasterMsg: "",
      reviewValidGoogleToasterColor: "",

      reviewValidWorkFlowToaster: false,
      reviewValidWorkFlowToasterMsg: "",
      reviewValidWorkFlowToasterColor: "",

      templDataText: "",
      templDataEmail: "",
      emailInputState: 0,
      phoneInputState: 0,
      facebookInputState: 0,
      googleInputState: 0,
      bbbInputState: 0,
      socialLink: "",
      renderOption1: [],
      renderOption2: [],
      country_code: "+1",
      phone: "",
      submitButton: 0,
      teamData: [],
      tempSignature: "",
      tempTextSignature: "",
      facebookList: [],
      googleList: [],
      FacebookValue: "0",
      GoogleValue: "0",
      loading: true,
      subject: "",
      BlankTextEmail: "",
      BlankTextText: "",
      allowforreviews: "",
      ontimesave:0,
      //added by sagar
      bulkOption: 0,
      uploadCSV: "",
      fileHeaders:[],
      fileContent:[],
      tempFileContent:[],
      error:'',
      modalIsOpen:false,
      type:"",
      errorType:"",
      uniqueValuesArray:[],
      confirmationModalIsOpen:false,
      idToBeDeleted:"",
      //added by sk
      custom_link: "",
      isRedirectURLValid: true,
      //end
      loadinggoogle: true,
      loadingfb: true,
    };
    this.riviewLink = "{Review Link}";
    this.befereChengeLink = "";
    this.befereChengeLinkText = "";
    this.udatefbLink();
    this.updateData();
    this.country_code();
    this.teamData();
    this.GoogleData();
    this.FacebookData();
    this.setDefaultTemplate();
    this.setDefaultTemplateAuto();
  }

  udatefbLink() {
    api.reviews.addFbLink().then((data) => {
      try {
      } catch (err) {
        console.log(err);
      }
    });
  }

  teamData() {
    api.manageTeam.getTeamNew().then((data) => {
      try {
        this.setState({
          teamData: data.data.map((item, index) => ({
            value: item["id"],
            text: item["member_name"],
          })),
        });
      } catch (err) {
        console.log(err);
      }
    });
  }
  setDefaultTemplate() {
    //alert('hi');
    api.reviews.setDefaultTemplate().then((data) => {
      try {
       
      } catch (err) {
        console.log(err);
      }
    });
  }


  setDefaultTemplateAuto() {
    //alert('hi');
    api.reviews.setDefaultTemplateAuto().then((data) => {
      try {
       
      } catch (err) {
        console.log(err);
      }
    });
  }


  GoogleData() {
    this.setState({
      loadinggoogle: true,
    });
    api.googleReview.getLocationList().then((data) => {
      try {
        data.list.map((item, index) => {
          if (item["activeLinkStatus"] == 1) {
            this.setState({
              GoogleValue: item["id"],
            });
          }
        });

        this.setState({
          googleList: data.list.map((item, index) =>
            item["type"] == 1
              ? { value: item["id"], text: item["locationName"] }
              : { value: item["id"], text: item["link"] }
          ),
        });
        this.state.googleList.unshift({
          value: "0",
          text: "Hide google as an option",
        });
        this.setState({
          loadinggoogle: false,
        });
      } catch (err) {
        console.log(err);
      }
    });
  }
  FacebookData() {
    this.setState({
      loadingfb: true,
    });
    api.reviews.getFacebookList().then((data) => {
      //console.log(data)
      try {
        if (data.data_visible.length > 0) {
          this.setState({
            allowforreviews: data.data_visible[0].visible_value,
            custom_link: data.data_visible[0].custom_link,
          });
        } else {
          this.setState({ allowforreviews: "Y", custom_link: "" });
        }
        data.list.map((item, index) => {
          if (item["activeLinkStatus"] == 1) {
            this.setState({
              FacebookValue: item["id"],
            });
          }
        });
        this.setState({
          facebookList: data.list.map((item, index) =>
            item["type"] == 1
              ? { value: item["id"], text: item["page_name"] }
              : { value: item["id"], text: item["link"] }
          ),
        });
        this.state.facebookList.unshift({
          value: "0",
          text: "Hide facebook as an option",
        });
        this.setState({
          loading: false,
          loadingfb: false,
        });
      } catch (err) {
        console.log(err);
      }
    });
  }

  apendData = (key, data) => {
    if (data.value == "1") {
      this.setState({
        emailInputState: 1,
        phoneInputState: 0,
      });
    } else if (data.value == "2") {
      this.setState({
        emailInputState: 0,
        phoneInputState: 1,
      });
    } else {
      this.setState({
        emailInputState: 0,
        phoneInputState: 0,
      });
    }
  };

  CallSocialLink = (key, data) => {
    api.reviews.socialLink({ source_type: data.value }).then((response) => {
      try {
        this.setState({
          socialLink: response.data.link,
        });
      } catch (err) {
        console.log(err);
      }
    });

    if (data.value == "2") {
      this.setState({
        facebookInputState: 1,
        googleInputState: 0,
        bbbInputState: 0,
      });
    } else if (data.value == "3") {
      this.setState({
        facebookInputState: 0,
        googleInputState: 1,
        bbbInputState: 0,
      });
    } else if (data.value == "4") {
      this.setState({
        facebookInputState: 0,
        googleInputState: 0,
        bbbInputState: 1,
      });
    } else {
      this.setState({
        facebookInputState: 0,
        googleInputState: 0,
        bbbInputState: 0,
      });
    }
  };

  updateData = (e) => {
    api.reviews.emailTempl().then((data) => {
      try {
        var str = data.data.text;
        var n = str.search(this.riviewLink);

        if (n != "-1") {
          var text = data.data.text;
        } else {
          var text = data.data.text;
          text += "\n";
          text += this.riviewLink;
        }
        //console.log(text);
        this.setState({
          templDataEmail: text,
          tempSignature: data.data.signature,
          subject: data.data.subject,
        });
      } catch (err) {
        this.setState({
          templDataEmail: this.riviewLink,
        });
        console.log(err);
      }
    });

    api.reviews.textTempl().then((data) => {
      try {
        var str = data.data.text;
        var n = str.search(this.riviewLink);
        if (n != "-1") {
          var text = data.data.text;
        } else {
          var text = data.data.text;
          text += "\n";
          text += this.riviewLink;
        }

        this.setState({
          templDataText: text,
        });
      } catch (err) {
        this.setState({
          templDataText: this.riviewLink,
        });
        console.log(err);
      }
    });
  };

  checkReviewLink = (e) => {
    var str = e.target.value;
    var n = str.search(this.riviewLink);

    if (n == "-1") {
      if (this.befereChengeLink == "") {
        this.befereChengeLink = this.state.templDataEmail;
      }
      this.setState(
        {
          templDataEmail: "",
        },
        () => {
          this.setState({
            templDataEmail: this.befereChengeLink,
          });
        }
      );
    } else {
      this.befereChengeLink = str;
    }
  };
  checkReviewLinkText = (e) => {
    var str = e.target.value;
    var n = str.search(this.riviewLink);

    if (n == "-1") {
      if (this.befereChengeLinkText == "") {
        this.befereChengeLinkText = this.state.templDataText;
      }
      this.setState(
        {
          templDataText: "",
        },
        () => {
          this.setState({
            templDataText: this.befereChengeLinkText,
          });
        }
      );
    } else {
      this.befereChengeLinkText = str;
    }
  };

  onValidSubmitTemplatesText = (e) => {
    this.setState({
      loading: true,
    });
    const sendData = {
      text: e.text,
      type: e.type,
      signature: e.tempTextSignature,
    };
    api.reviews.addTemplates(sendData).then((data) => {
      this.setState({
        submitButton: 0,
        loading: false,
      });
      this.updateData();

      this.showToasterTextTempl(
        "green",
        "Text templates has been saved successfully."
      );
    });
  };

  /*Add new function validation for custom link sk*/

  handleOnChange = (e) => {
    const regExQ =
      /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
    if (e.target.value.match(regExQ)) {
      this.setState({
        [e.target.name]: e.target.value,
        isRedirectURLValid: true,
      });
    } else if (e.target.value == "") {
      this.setState({
        [e.target]: e.target.value,
        isRedirectURLValid: true,
      });
    } else {
      this.setState({
        [e.target]: e.target.value,
        isRedirectURLValid: false,
      });
    }
  };

  /*  end function*/

  /* function customize for custom link by sk*/

  onValidSubmitLink = (e) => {
    if (this.state.isRedirectURLValid == true) {
      let customlinks = e.custom_link;
      if (
        e.googleID == 0 &&
        e.facebookID == 0 &&
        this.state.allowforreviews == "N"
      ) {
        document.getElementById("msg-new").style.display = "block";
        setTimeout(function () {
          document.getElementById("msg-new").style.display = "none";
        }, 2000);
      } else {
        this.setState({
          loading: true,
        });
        const sendData = {
          googleID: e.googleID,
          facebookID: e.facebookID,
          custom_link: customlinks,
          visible_value: this.state.allowforreviews,
        };
        //console.log(sendData);
        api.reviews.addLinks(sendData).then((data) => {
          this.setState({
            submitButton: 0,
            loading: false,
          });
          this.GoogleData();
          this.FacebookData();
          this.showToasterLink("green", "Links has been saved successfully.");
          utilFunc.scrollTop(800);
        });
      }
    }
  };

  /*end function  */

  onValidSubmitTemplatesEmail = (e) => {
    this.setState({
      loading: true,
    });
    
    const sendData = {
      text: e.text,
      type: e.type,
      signature: e.tempSignature,
      subject: e.subject,
      textemail: e.textemail,
    };

    api.reviews.addTemplates(sendData).then((data) => {
      this.setState({
        submitButton: 0,
        loading: false,
        ontimesave:0,
      });
      this.updateData();
      this.showToasterEmailTempl(
        "green",
        "Templates has been saved successfully."
      );
      utilFunc.scrollTop(800);
    });
  };

  /*function changes by sk*/

  onTimeTemplateSave = (e) => {
    if(this.state.ontimesave==1){
      const sendData = {
        text: this.state.templDataText,
        type: '',
        signature: '',
        subject: this.state.subject,
        textemail: this.state.templDataEmail,
        ontimesave:0
      };
      api.reviews.addTemplates(sendData).then((data) => {

        this.onValidSubmitReviews(e);
      });
    } else {
      this.onValidSubmitReviews(e);

    }
  };

  onValidSubmitReviews = (e) => {
    this.setState({
      loading: true,
    });
    this.setState({
      submitButton: 1,
    });
    if (e.type == 2) {
      var customer_phone_email = this.state.phone;
    } else {
      var customer_phone_email = e.customer_phone_email;
    }
    const sendData = {
      customer_name: e.customer_name,
      customer_phone_email: customer_phone_email,
      team_id: e.team_id,
      type: e.type,
      country_code: this.state.country_code,
      base_url: config.baseUrl,
      link: e.link,
      source_type: e.source_type,
      //FacebookValue: this.state.FacebookValue,
      FacebookValue: "",
      //GoogleValue: this.state.GoogleValue,
      GoogleValue: "",
      riviewLink: this.riviewLink,
      //custom_link: this.state.custom_link,
      custom_link: "",
      //allowforreviews: this.state.allowforreviews,
      allowforreviews: "",
    };
    api.reviews.addReviewsNew(sendData).then((data) => {
      try {
        if (data.status) {
          this.setState(
            {
              phone: "",
              submitButton: 0,
              loading: false,
            },
            () => {
              //console.log(this.state.submitButton);
            }
          );
          this.showToasterReviews(
            "green",
            "Review has been sent successfully."
          );
          document.getElementById("reviewForm").reset();
          utilFunc.scrollTop(800);
          document.querySelector('.right-bar').scrollTop = 0;
        } else {
          this.setState({
            loading: false,
          });
          if (data.data.error == "WorkFlow") {
            this.showToasterReviewsValidWorkFlow("red", data.message);
          }
          if (data.data.error == "Temp") {
            this.showToasterReviewsValidTemp("red", data.message);
          }
          // if(data.data.error=="Sign"){
          //   this.showToasterReviewsValidSign('red', data.message);
          // }
          if (data.data.error == "Facebook") {
            this.showToasterReviewsValidFacebook("red", data.message);
          }
          if (data.data.error == "Google") {
            this.showToasterReviewsValidGoogle("red", data.message);
          }
        }
      } catch (err) {
        console.log(err);
      }
    });
  };
  

  /*end*/

  showToasterTextTempl = (color, msg) => {
    this.setState({
      textToaster: true,
      textToasterMsg: msg,
      textToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ textToaster: false });
      }.bind(this),
      3000
    );
  };

  showToasterLink = (color, msg) => {
    this.setState({
      linkToaster: true,
      linkToasterMsg: msg,
      linkToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ linkToaster: false });
      }.bind(this),
      3000
    );
  };

  showToasterEmailTempl = (color, msg) => {
    this.setState({
      emailToaster: true,
      emailToasterMsg: msg,
      emailToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ emailToaster: false });
      }.bind(this),
      3000
    );
  };

  showToasterReviews = (color, msg) => {
    this.setState({
      reviewToaster: true,
      reviewToasterMsg: msg,
      reviewToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ reviewToaster: false });
      }.bind(this),
      3000
    );
  };

  showToasterReviewsValidTemp = (color, msg) => {
    this.setState({
      reviewValidTempToaster: true,
      reviewValidTempToasterMsg: msg,
      reviewValidTempToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ reviewValidTempToaster: false });
      }.bind(this),
      5000
    );
  };

  showToasterReviewsValidWorkFlow = (color, msg) => {
    this.setState({
      reviewValidWorkFlowToaster: true,
      reviewValidWorkFlowToasterMsg: msg,
      reviewValidWorkFlowToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ reviewValidWorkFlowToaster: false });
      }.bind(this),
      10000
    );
  };

  showToasterReviewsValidSign = (color, msg) => {
    this.setState({
      reviewValidSignToaster: true,
      reviewValidSignToasterMsg: msg,
      reviewValidSignToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ reviewValidSignToaster: false });
      }.bind(this),
      5000
    );
  };

  showToasterReviewsValidFacebook = (color, msg) => {
    this.setState({
      reviewValidFacebookToaster: true,
      reviewValidFacebookToasterMsg: msg,
      reviewValidFacebookToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ reviewValidFacebookToaster: false });
      }.bind(this),
      5000
    );
  };

  showToasterReviewsValidGoogle = (color, msg) => {
    this.setState({
      reviewValidGoogleToaster: true,
      reviewValidGoogleToasterMsg: msg,
      reviewValidGoogleToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ reviewValidGoogleToaster: false });
      }.bind(this),
      5000
    );
  };

  componentDidMount() {
    const {
      setPageTitle,
      toggleNavigation,
      location,
      PageMessage,
      setMessage,
      setSubHeaderTitle,
      setNavigationName,
      setBreadCrum,
      toggleBreadcrum,
    } = this.props;

    setPageTitle({
      title: "Request A Review",
      subHeaderTitle: "Review Management",
    });
    setSubHeaderTitle({ subHeaderTitle: "Review Management" });
    setNavigationName({ navigationName: "reviewManagement" });
    toggleNavigation({ toggle: true });
    setBreadCrum({ breadcrum: false });
    toggleBreadcrum({ toggle: false });

    const params = queryString.parse(location.search);

    // this.setState({}, function () {
    //   this.loadData();
    // });
  }

  country_code = (params) => {
    api.manageTeam.callCountryCode().then((data) => {
      try {
        this.setState({ renderOption1: data.data.defaultCountryCodeArr });
        this.setState({ renderOption2: data.data.remailCountryCodeArr });
      } catch (err) {
        console.log(err);
      }
    });
  };

  renderDropDownDefault = (defaultCountryCodeArr, countryId = null) => {
    return defaultCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : ""}
        >
          {option.country_name}
        </option>
      );
    });
  };
  renderDropDownRemaining = (remailCountryCodeArr, countryId = null) => {
    return remailCountryCodeArr.map((option, index) => {
      return (
        <option
          key={index}
          value={option.country_code}
          selected={countryId == option.country_code ? "selected" : ""}
        >
          {option.country_name}
        </option>
      );
    });
  };

  //sagar
  bulkTypeHandler=(type)=>{
    console.log("type",type)
    if(type==="bulk"){
      this.setState({bulkOption:1})
    }else{
      this.setState({bulkOption:0,uploadCSV:""})
    }
  }

  utilHeader =(headers,csvHeadersArray)=>{
    let error = []
    let valid = true
    // if(JSON.stringify(headers) !==JSON.stringify(csvHeadersArray)){
    //   error.push(`Please match the headers as per sample CSV.`)
    //   this.setState({error:error,errorType:"csvHeader"})
    // }

      if(headers.length!==csvHeadersArray.length){
        error.push(`Please match the headers as per sample CSV.`)
        this.setState({error:error,errorType:"csvHeader"})
        return valid = false
      }
      valid = csvHeadersArray.every(element=>{
       return  headers.includes(element)
      })
      if(valid===false){
        // if(headers ===csvHeadersArray){
          error.push(`Please match the headers as per sample CSV.`)
          this.setState({error:error,errorType:"csvHeader"})
          console.log("Invalid headeers*******")
          return valid   
      }
  }

  validateHeader=(headers)=>{
    if(this.state.emailInputState===1){
      this.utilHeader(headers,emailHeaders)
    }

    if(this.state.phoneInputState===1){
      this.utilHeader(headers,phoneHeaders)
    }
  }

  csvFileToArray = (string) => {
    const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
    const csvRows = string.slice(string.indexOf("\n") + 1).split("\n")
      //remove the last index,since it's going to be empty
    csvRows.pop()
    console.log(csvRows)
    const ModifiedCsvHeader = csvHeader.map(element=>element.trim())
    const newModifiedHeader = ModifiedCsvHeader.filter(h=>h!=="")
    this.validateHeader(newModifiedHeader)
    if(this.state.errorType==="csvHeader"){
      this.setState({modalIsOpen:true,})
    }else{
      
      const array = csvRows.map(i => {
        const values = i.split(",");
        console.log(values)
        const obj = csvHeader.reduce((object, header, index) => {
          object[header.replace(/(\r?)/gm,"").trim()] = values[index].replace(/(\r?)/gm,"").trim();       
          return object;
        }, {});
        return obj;
      });
      console.log(array)
      console.log(csvHeader)
      this.checkValidity(array)
      // this.removeDuplicates(this.state.tempFileContent)
      this.setState({fileContent: this.state.uniqueValuesArray,fileHeaders:csvHeader})
    }
  };

  readFileOnLoad=(file)=>{
    this.setState({loading:true})
    const fileReader = new FileReader();
    if(file){
      fileReader.onload = (event)=> {
        const csvOutput = event.target.result;
        console.log(csvOutput)
        this.csvFileToArray(csvOutput)
      }
      fileReader.readAsText(file);
    }
    this.setState({loading:false})
  }

  userCSV = (e)=>{
    console.log(e.target.files[0].type)
    if(e.target.files[0].type==="text/csv"){
      this.setState({uploadCSV:e.target.files[0],error:"",type:"text/csv",errorType:""},()=>{
          this.readFileOnLoad(this.state.uploadCSV);
      })
    }else{
      this.setState({error:'Invalid file format.Only "csv" files are supported.',type:"",errorType:""})
    }
  }

  onBulkRequestAReviewSubmit=(e)=>{
    if(this.state.ontimesave==1){
      const sendData = {
        text: this.state.templDataText,
        type: '',
        signature: '',
        subject: this.state.subject,
        textemail: this.state.templDataEmail,
        ontimesave:0
      };
      api.reviews.addTemplates(sendData).then((data) => {

        this.onValidSubmitBulkReviews(e);
      });
    } else {
      this.onValidSubmitBulkReviews(e);

    }
  }

  onValidSubmitBulkReviews=(e)=>{
    this.setState({
      loading: true,
    });
    this.setState({
      submitButton: 1,
    });

    const sendData = {
      file_content: this.state.fileContent,
      team_id: e.team_id,
      type: e.type,
      // country_code: this.state.country_code,
      base_url: config.baseUrl,
      link: e.link,
      source_type: e.source_type,
      //FacebookValue: this.state.FacebookValue,
      FacebookValue: "",
      //GoogleValue: this.state.GoogleValue,
      GoogleValue: "",
      riviewLink: this.riviewLink,
      //custom_link: this.state.custom_link,
      custom_link: "",
      //allowforreviews: this.state.allowforreviews,
      allowforreviews: "",
    };
    api.reviews.addBulkReviews(sendData).then((data) => {
      try {
        if (data.status) {
          this.setState(
            {
              phone: "",
              submitButton: 0,
              loading: false,
              emailInputState:0,
              phoneInputState:0,
              uploadCSV:""
            },
            () => {
              document.getElementById("reviewFormBulk").reset();
            }
          );
          this.showToasterReviews(
            "green",
            "Review has been sent successfully."
          );
          // this.setState({,})
          utilFunc.scrollTop(800);
          document.querySelector('.right-bar').scrollTop = 0;
        } else {
          this.setState({
            loading: false,
          });
          if (data.data.error == "WorkFlow") {
            this.showToasterReviewsValidWorkFlow("red", data.message);
          }
          if (data.data.error == "Temp") {
            this.showToasterReviewsValidTemp("red", data.message);
          }
          // if(data.data.error=="Sign"){
          //   this.showToasterReviewsValidSign('red', data.message);
          // }
          if (data.data.error == "Facebook") {
            this.showToasterReviewsValidFacebook("red", data.message);
          }
          if (data.data.error == "Google") {
            this.showToasterReviewsValidGoogle("red", data.message);
          }
        }
      } catch (err) {
        console.log(err);
      }
    });
  }

  checkValidity=(fileContent)=>{
    let error = []
    if(fileContent?.length>0){
      this.setState({tempFileContent:fileContent})
      let validContent = true;
     
      // fileContent.map((users,index)=>{
      for(let usersIdx in fileContent){
         
        if(this.state.phoneInputState ==1){
          var [cust_name,country_code,value] = Object.values(fileContent[usersIdx])
        }else{
          if(this.state.emailInputState ==1){
            var [cust_name,value] = Object.values(fileContent[usersIdx])
          }
        }
        console.log(cust_name,value)
        // for(let se of Object.values(fileContent[usersIdx])){
        //   console.log(fileContent[usersIdx])
        //   console.log(cust_name,value)
        if((cust_name.length>0 && value.length>0)){
            console.log(cust_name.trim().length);
            if(this.state.phoneInputState ==1){
              console.log(value)
              validContent = this.phoneFormatForCSV(country_code,value)
              if(!validContent){
                //  error["invalidPhoneFormat"]=`Please Enter a valid phone number in row ${+usersIdx + 1}`;
                error.push(` Invalid phone number/country code in (Row: ${+usersIdx + 2})`);
                const array = this.state.tempFileContent.filter(user=>user["Phone Number"]!== fileContent[usersIdx]["Phone Number"])
                this.setState({error:error,tempFileContent:array})
                // return;
              }
            }else if(this.state.emailInputState ==1){
              validContent = this.validateEmail(value)
              if(!validContent){
                // error["invalidEmail"] = `Please enter a valid email in row ${+usersIdx + 1}`;
                error.push(`Invalid email in: (Row: ${+usersIdx + 2})`);
                const array = this.state.tempFileContent.filter(user=>user["Email"]!== fileContent[usersIdx]["Email"])
                this.setState({error:error,tempFileContent:array})
              // return
              }
            }
          }else{
            // error["invalidEntry"] = `Please populate field in row ${+usersIdx + 1}`;
            error.push(`Invalid field entry in: (Row: ${+usersIdx + 2})`);
            if(this.state.phoneInputState ==1){
              const array = this.state.tempFileContent.filter(user=>user!== fileContent[usersIdx])
              this.setState({error:error,tempFileContent:array})
            }else{
              if(this.state.emailInputState ==1){
                // const array = this.state.tempFileContent.filter(user=>user["Email"]!== fileContent[usersIdx]["Email"])
                const array = this.state.tempFileContent.filter(user=>user!== fileContent[usersIdx])
                this.setState({error:error,tempFileContent:array})
              }
            }
            
          } 
      }

      if(this.state.error.length>0){
        this.setState({modalIsOpen:true})
      }
      this.removeDuplicates(this.state.tempFileContent)
    }else{
        error.push("No data found in CSV file.")
      this.setState({error:error,type:"empty"})
    }
  }


  validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  
  closeModal = ()=>{
    this.setState({modalIsOpen:false,error:[]})
  }

  displayErrors=(errors)=>{
    return <div style={{padding:"10px"}}>
      {errors.map((error,index)=>{
      return (<div key={index} style={{display:'flex'}}><p class="text-danger"><span className="pr-1">{`${+index + 1}. `}</span>{error}</p></div>)}
      )}
    </div>
  }

  removeDuplicates = (array)=>{
    let uniqueValuesArray =[]
    if(this.state.emailInputState===1){
      uniqueValuesArray = array.filter((value, index, self) =>
      index === self.findIndex((t) => (
      t["Email"] === value["Email"] && t["Customer Name"] === value["Customer Name"]
  ))
) }
    if(this.state.phoneInputState===1){
      uniqueValuesArray = array.filter((value, index, self) =>
      index === self.findIndex((t) => (
    t["Customer Name"] === value["Customer Name"] && t["Country Code"] === value["Country Code"] && t["Phone Number"] === value["Phone Number"]  )))
    }
    console.log(uniqueValuesArray)
    // this.checkValidity(uniqueValuesArray)
    this.setState({uniqueValuesArray:uniqueValuesArray})
    // return uniqueValuesArray

  }

  deleteCustomer = (e,id)=>{
    const modifiedTableContent = [...this.state.fileContent]
    modifiedTableContent.splice(id,1)
    console.log(id)
    console.log("modifiedTableContent",modifiedTableContent)
    this.setState({fileContent:modifiedTableContent,confirmationModalIsOpen:false})
  }

  deleteConfirmationModal = (e,id)=>{
    this.setState({confirmationModalIsOpen:true,idToBeDeleted:id})
  }
  closeConfirmationModal = ()=>{
    this.setState({confirmationModalIsOpen:false})
  }
  /* End of code */

  handleDrop = (key) => {
    const target = key.target.value;
    this.setState({
      country_code: target,
    });
  };

  checkPhone = (key) => {
    this.setState({
      phone: key.target.value,
    });
    this.phoneFormat(key);
  };
  phoneFormat = (e) => {
    let phone = e.target.value;
    let p = phone;
    var obj = this;

    p = p.replace(/[^\d]*/gi, "");
    if (p.length < 3) {
      setTimeout(function () {
        obj.setState({ phone: p });
      }, 100);
    } else if (p.length == 3) {
      let pp = p;
      let d4 = p.indexOf("(");
      let d5 = p.indexOf(")");
      if (d4 == -1) {
        pp = "(" + pp;
      }
      if (d5 == -1) {
        pp = pp + ")";
      }
      obj.setState({ phone: pp });
    } else if (p.length > 3 && p.length < 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      setTimeout(function () {
        obj.setState({ phone: pp });
      }, 100);
    } else if (p.length >= 7) {
      p = "(" + p;
      let l30 = p.length;
      let p30 = p.substring(0, 4);
      p30 = p30 + ")";

      let p31 = p.substring(4, l30);
      let pp = p30 + p31;

      let l40 = pp.length;
      let p40 = pp.substring(0, 8);
      p40 = p40 + "-";

      let p41 = pp.substring(8, l40);
      let ppp = p40 + p41;
      let maxphonelength = 13;
      let finalPhone = ppp.substring(0, maxphonelength);
      setTimeout(function () {
        obj.setState({ phone: finalPhone });
      }, 100);
    }
  };

  /* Sagar */
  phoneFormatForCSV=(country_code,phone)=>{
    const p = phone.replace(/[^\d]*/gi, "");
    if(p.trim().length===10){
      if(country_code.length===0){
        return false
      }
      return true
    }else{
      return false
    } 
  }
  /* End of code */

  handleLink = (key, data) => {
    if (data.name == "facebookID") {
      this.setState({
        FacebookValue: data.value,
      });
    }
    if (data.name == "googleID") {
      this.setState({
        GoogleValue: data.value,
      });
    }
  };

  handleCheck = (key) => {
    const target = key.target;
    if (target.checked) {
      this.setState({
        [target.name]: "Y",
      });
    } else {
      this.setState({
        [target.name]: "N",
      });
    }
  };

  OntimeTextEdit = (e) => {
    this.setState({ ontimesave:1, templDataText: e.target.value});
  };

  OntimeSubjectEdit = (e) => {
    this.setState({ ontimesave:1, subject: e.target.value});
  };

  OntimeEmailEdit = (e) => {
    this.setState({ ontimesave:1, templDataEmail: e.target.value});
  };
  //ANKIT END

  render() {
    const errorLabel = <div className="red" />;
    const options = [];
    api.manageTeam.getTeam().then((data) => {
      try {
        data.data.forEach((item, index2) => {
          options.push({ value: item["id"], text: item["member_name"] });
        });
      } catch (err) {
        console.log(err);
      }
    });
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
        <div className="kt-container kt-body  kt-grid kt-grid--ver ml-0">
          <div className="kt-content kt-grid__item kt-grid__item--fluid">
            <div className="yr-body-p">
              <div className="row">
                {this.state.loading && <div className="ui loading form"></div>}
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="kt-portlet no-shadow">
                    <div className="kt-portlet__body request-a-review yr-pd0-lr yr-mt-20 yr-pd0-mob">
                    <div className="d-flex" >
                        <div className="box-title mx-lg-3">Message Templates</div>
                        <p style={{ fontWeight: 600 }} className="mt-0 ml-4">
                          <Link
                            className="blue"
                            to={
                              "/settings/settings-customization?tab=d"
                            }
                          >
                            Click here to edit your workflows
                          </Link>
                        </p>
                       
                      </div>
                      <div className="row">                        
                        <div className="col-lg-8">
                          <div className="yr-chat-round-box custom-fix">
                          <Message
                                        color={
                                          this.state.emailToasterColor
                                            ? this.state.emailToasterColor
                                            : "green"
                                        }
                                        style={{
                                          display: this.state.emailToaster
                                            ? "block"
                                            : "none",
                                          marginTop: 30,
                                        }}
                                      >
                                        {this.state.emailToasterMsg}
                                      </Message>

                              <Form onValidSubmit={this.onValidSubmitTemplatesEmail}>
                                <div class="row">
                                  <div className="col-lg-6">
                                    <div className="kt-form">
                                    
                                      <div className="form-group">
                                        <label className="bold pb-2">
                                          Request a review - Text message:
                                        </label>
                                        <Form.TextArea
                                          rows="10"
                                          value={this.state.templDataText}
                                          ref={this.myRef}
                                          name="text"
                                          id="boxText"
                                          onChange={this.OntimeTextEdit}
                                          validations="isRequired"
                                          validationErrors={{
                                            isRequired: "This field is required.",
                                          }}
                                          errorLabel={errorLabel}
                                        ></Form.TextArea>
                                      </div>
                                      <Input name="type" type="hidden" value="2" />
                                      <div className="row">
                                        <div className="col-lg-12 d-flex justify-content-end pt-4">
                                          {/* <button
                                            type="submit"
                                            className="btn zy-save-btn"
                                          >
                                            Save Template
                                          </button> */}
                                        </div>
                                      </div>
                                    </div>
                                  
                                  </div>
                                  <div className="col-lg-6">

                                    <div className="kt-form">

                                      <div className="form-group">
                                        <label className="col-form-label bold mt-0">
                                          Request a review - Email subject line:
                                        </label>
                                        <div className="">
                                          <Form.Input
                                            type="text"
                                            autoComplete="off"
                                            value={this.state.subject}
                                            name="subject"
                                            id="subject"
                                            onChange={this.OntimeSubjectEdit}
                                            validations="isRequired"
                                            validationErrors={{
                                              isRequired: "This field is required.",
                                            }}
                                            errorLabel={errorLabel}

                                          />
                                        </div>
                                      </div>
                                      <div className="form-group">
                                        <label className="bold pb-2">
                                          Request a review - Email body:
                                        </label>
                                        <Form.TextArea
                                          rows="10"
                                          value={this.state.templDataEmail}
                                          id="box"
                                          onChange={this.OntimeEmailEdit}
                                          name="textemail"
                                          validations="isRequired"
                                          validationErrors={{
                                            isRequired: "This field is required.",
                                          }}
                                          errorLabel={errorLabel}
                                        ></Form.TextArea>
                                       
                                      </div>
                                      <Input name="type" type="hidden" value="1" />
                                      <div className="row">
                                        <div className="col-lg-12 d-flex justify-content-end">
                                          <button
                                            type="submit"
                                            className="btn linear-gradient zy-save-btn"
                                          >
                                            Save Template
                                          </button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </Form>
                          </div>
                        </div>
                        <div className="col-lg-4">
                        {/* <div class="col-md-12 kt-margin-b-20-tablet-and-mobile "> */}
                        <div class="col-md-12 ">
                          <div class="kt-portlet__head">
                            <div class="kt-portlet__head-toolbar">
                              <ul
                                class="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
                                role="tablist"
                              >
                                <li class="nav-item">
                                  <a
                                    onClick={()=>{this.bulkTypeHandler("individual")}}
                                    class="nav-link active"
                                    data-toggle="tab"
                                    href="#kt_portlet_base_demo_1_1_tab_content"
                                    role="tab"
                                  >
                                    Individual
                                  </a>
                                </li>
                                <li class="nav-item">
                                  <a
                                    // onClick={(e) => this.props.getListByStatus("Active")}
                                    onClick={()=>{this.bulkTypeHandler("bulk")}}
                                    class="nav-link"
                                    data-toggle="tab"
                                    href="#kt_portlet_base_demo_1_2_tab_content"
                                    role="tab"
                                  >
                                    Bulk
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                          </div>
                          <div className="yr-chat-round-box  custom-fix right-bar">
                            <div className="form-group">
                            <label className="form-group-label bold">Send a manual review request</label>       
                            </div>
                            <Message
                              color={
                                this.state.reviewToasterColor
                                  ? this.state.reviewToasterColor
                                  : "green"
                              }
                              style={{
                                display: this.state.reviewToaster
                                  ? "block"
                                  : "none",
                                marginTop: 30,
                              }}
                            >
                              {this.state.reviewToasterMsg}
                            </Message>

                            <Message
                              color={
                                this.state.reviewValidFacebookToasterColor
                                  ? this.state.reviewValidFacebookToasterColor
                                  : "green"
                              }
                              style={{
                                display: this.state.reviewValidFacebookToaster
                                  ? "block"
                                  : "none",
                                marginTop: 30,
                              }}
                            >
                              {this.state.reviewValidFacebookToasterMsg}
                            </Message>

                            <Message
                              color={
                                this.state.reviewValidGoogleToasterColor
                                  ? this.state.reviewValidGoogleToasterColor
                                  : "green"
                              }
                              style={{
                                display: this.state.reviewValidGoogleToaster
                                  ? "block"
                                  : "none",
                                marginTop: 30,
                              }}
                            >
                              {this.state.reviewValidGoogleToasterMsg}
                            </Message>

                            <Message
                              color={
                                this.state.reviewValidTempToasterColor
                                  ? this.state.reviewValidTempToasterColor
                                  : "green"
                              }
                              style={{
                                display: this.state.reviewValidTempToaster
                                  ? "block"
                                  : "none",
                                marginTop: 30,
                              }}
                            >
                              {this.state.reviewValidTempToasterMsg}
                            </Message>

                            <Message
                              color={
                                this.state.reviewValidSignToasterColor
                                  ? this.state.reviewValidSignToasterColor
                                  : "green"
                              }
                              style={{
                                display: this.state.reviewValidSignToaster
                                  ? "block"
                                  : "none",
                                marginTop: 30,
                              }}
                            >
                              {this.state.reviewValidSignToasterMsg}
                            </Message>

                            <Message
                              color={
                                this.state.reviewValidWorkFlowToasterColor
                                  ? this.state.reviewValidWorkFlowToasterColor
                                  : "green"
                              }
                              style={{
                                display: this.state.reviewValidWorkFlowToaster
                                  ? "block"
                                  : "none",
                                marginTop: 30,
                              }}
                            >
                              {this.state.reviewValidWorkFlowToasterMsg}{" "}
                              <a href="/settings/settings-customization?tab=d">
                                Click here
                              </a>
                            </Message>

                            {this.state.bulkOption === 0?<Form
                              noValidate
                              autoComplete="off"
                              id="reviewForm"
                              onValidSubmit={this.onTimeTemplateSave}
                            >
                              <div className="kt-form requestReviewForm">
                                {/* <div className="form-group row">
                                            <label className="col-lg-3 col-form-label bold">Source:</label>
                                            <div className="col-lg-8">
                                                <Dropdown
                                                name="sourceType"
                                                placeholder="Select"
                                                selection
                                                validations={{
                                                customValidation: (values, value) => !(!value || value.length < 1), 
                                                }}
                                                validationErrors={{
                                                customValidation: 'Please select atleast one.',
                                                }}
                                                errorLabel={errorLabel}
                                                onChange = {this.CallSocialLink}
                                                options={options_source}
                                                />                          												
                                            </div>																	
                                        </div> */}
                                {this.state.facebookInputState ? (
                                  <div className="form-group row socialReview">
                                    <Input
                                      type="hidden"
                                      name="source_type"
                                      value="2"
                                    />
                                    <label className="col-lg-3 col-form-label bold">
                                      Facebook Link:
                                    </label>
                                    <div className="col-lg-8">
                                      <Form.Input
                                        type="text"
                                        readOnly={true}
                                        value={this.state.socialLink}
                                        autoComplete="off"
                                        name="link"
                                        validations="isRequired"
                                        validationErrors={{
                                          isRequired: "This field is required.",
                                        }}
                                        errorLabel={errorLabel}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {this.state.googleInputState ? (
                                  <div className="form-group row socialReview">
                                    <Input
                                      type="hidden"
                                      name="source_type"
                                      value="3"
                                    />
                                    <label className="col-lg-3 col-form-label bold">
                                      Google Link:
                                    </label>
                                    <div className="col-lg-8">
                                      <Form.Input
                                        type="text"
                                        readOnly={true}
                                        autoComplete="off"
                                        value={this.state.socialLink}
                                        name="link"
                                        validations="isRequired"
                                        validationErrors={{
                                          isRequired: "This field is required.",
                                        }}
                                        errorLabel={errorLabel}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {this.state.bbbInputState ? (
                                  <div className="form-group row socialReview">
                                    <Input
                                      type="hidden"
                                      name="source_type"
                                      value="4"
                                    />
                                    <label className="col-lg-3 col-form-label bold">
                                      BBB Link:
                                    </label>
                                    <div className="col-lg-8">
                                      <Form.Input
                                        type="text"
                                        readOnly={true}
                                        autoComplete="off"
                                        value={this.state.socialLink}
                                        name="link"
                                        validations="isRequired"
                                        validationErrors={{
                                          isRequired: "This field is required.",
                                        }}
                                        errorLabel={errorLabel}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                <div className="form-group ">
                                  <label className="col-form-label bold">
                                    Send review via:
                                  </label>
                                  <div className="">
                                    <Dropdown
                                      name="type"
                                      placeholder="Select"
                                      selection
                                      validations={{
                                        customValidation: (values, value) =>
                                          !(!value || value.length < 1),
                                      }}
                                      validationErrors={{
                                        customValidation:
                                          "Please select atleast one.",
                                      }}
                                      errorLabel={errorLabel}
                                      onChange={this.apendData}
                                      options={options_type}
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="col-form-label bold">
                                    Send review as:
                                  </label>
                                  <div className="">
                                    <Dropdown
                                      name="team_id"
                                      placeholder="Select"
                                      selection
                                      validations={{
                                        customValidation: (values, value) =>
                                          !(!value || value.length < 1),
                                      }}
                                      validationErrors={{
                                        customValidation:
                                          "Please select atleast one.",
                                      }}
                                      autoComplete="off"
                                      errorLabel={errorLabel}
                                      options={this.state.teamData}
                                    />
                                  </div>
                                </div>
                                <div className="form-group">
                                  <label className="col-form-label bold">
                                    Customer name:
                                  </label>
                                  <div>
                                    <Form.Input
                                      type="text"
                                      fluid
                                      autoComplete="off"
                                      name="customer_name"
                                      validations="isRequired"
                                      validationErrors={{
                                        isRequired: "This field is required.",
                                      }}
                                      errorLabel={errorLabel}
                                    />
                                  </div>
                                </div>
                                {this.state.emailInputState ? (
                                  <div className="form-group">
                                    <label className="col-form-label bold">
                                      Email:
                                    </label>
                                    <div className="">
                                      <Form.Input
                                        type="text"
                                        autoComplete="off"
                                        name="customer_phone_email"
                                        validations="isEmail,isRequired"
                                        validationErrors={{
                                          isRequired: "This field is required.",
                                          isEmail:
                                            "Please enter a valid email address.",
                                        }}
                                        errorLabel={errorLabel}
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {this.state.phoneInputState ? (
                                  <div>
                                    <label className="col-form-label bold">
                                      Phone number:
                                    </label>
                                    <div className="form-group row">
                                      <div className="col-lg-3">
                                        <select
                                          class="form-control"
                                          name="isd_code"
                                          onChange={this.handleDrop}
                                          id="isd_code_id_review"
                                        >
                                          {this.renderDropDownDefault(
                                            this.state.renderOption1
                                          )}
                                          <optgroup label="Other countries">
                                            {this.renderDropDownRemaining(
                                              this.state.renderOption2
                                            )}
                                          </optgroup>
                                        </select>
                                      </div>
                                      <div className="col-lg-9">
                                        <Form.Input
                                          name="customer_phone_email"
                                          validations="isRequired,usPhoneLength"
                                          value={this.state.phone}
                                          onChange={this.checkPhone}
                                          validationErrors={{
                                            isRequired:
                                              "This field is required.",
                                            usPhoneLength:
                                              "Phone number must be at least 10 numbers.",
                                          }}
                                          errorLabel={errorLabel}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}

                                
                                <div className="d-flex justify-content-end pt-4">
                                  <button
                                    type="submit"
                                    disabled={
                                      this.state.submitButton === 1
                                        ? true
                                        : false
                                    }
                                    className="btn linear-gradient zy-save-btn"
                                  >
                                    Send Request
                                  </button>
                                </div>
                              </div>
                            </Form>:
                              <RequestFormBulk 
                              userCSV = {this.userCSV}
                              uploadCSV = {this.state.uploadCSV}
                              fileHeaders = {this.state.fileHeaders}
                              fileContent= {this.state.fileContent}
                              apendData = {this.apendData}
                              teamData={this.state.teamData}
                              errorLabel={errorLabel}
                              options_type={options_type}   
                              emailInputState={this.state.emailInputState}
                              phoneInputState = {this.state.phoneInputState}
                              onBulkRequestAReviewSubmit = {this.onBulkRequestAReviewSubmit}  
                              deleteCustomer = {this.deleteCustomer}
                              submitButton = {this.state.submitButton}
                              error = {this.state.error}
                              showToasterReviews = {this.showToasterReviews}
                              modalIsOpen = {this.state.modalIsOpen}
                              closeModal = {this.closeModal}
                              displayErrors = {this.displayErrors}
                              type={this.state.type}
                              errorType = {this.state.errorType}
                              deleteConfirmationModal = {this.deleteConfirmationModal}
                              closeConfirmationModal = {this.closeConfirmationModal}
                              confirmationModalIsOpen = {this.state.confirmationModalIsOpen}
                              idToBeDeleted = {this.state.idToBeDeleted}
                              />
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    PageMessage: state.page.message,
    user: state.user.company,
  };
};
export default connect(mapStateToProps, {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setSubHeaderTitle,
  setNavigationName,
  setBreadCrum,
  toggleBreadcrum,
})(RequestAReview);
