import React from "react";
import api from "../../../../../api";
import "../../../../Grid/grid.css";
import { Item, Segment, Table, Message } from "semantic-ui-react";
import { DatatableTable } from "./DatatableTable";
import { DatatableRow } from "./DatatableRow";
import utilFunc from "../../../../../util_funs";
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import config from "../../../../../config";
import { connect } from "react-redux";
import "./AdjustHour.css";
import DialogBoxAdjustHour from "./DialogBoxAdjustHour";
import MemberListNotification from "./MemberListNotification";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const WEEKDAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const tableHeader = [
    {
        title: "Actions",
        width: "1",
        sort: "",
    },
    {
        title: "Notification Type",
        width: "2",
        sort: "",
    },
    {
        title: "Name",
        width: "2",
        sort: "",
    },
    {
        title: "Email/Phone",
        width: "3",
        sort: "",
    },
    {
        title: "Notify via",
        width: "2",
        sort: "",
    }
];
const queryParams = ["_limit", "_order", "_sort", "q", "_page"];
class ScheduleCalendar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            lists: [],
            _sort: "user_id",
            _page: 1,
            _order: "desc",
            _limit: 10,
            q: "",
            totalCount: 0,
            loading: false,
            modalstatus: false,
            modaltitle: null,
            modalbuttonmsg: {},
            modalType: null,
            datatableHeader: null,
            datatableRows: null,
            modalErrorMsg: false,
            modalErrorMsgLegacy: false,
            modalstatusAddEdit: false,
            modaldata: {},
            modalFormLoader: false,
            scheduledate: new Date(),
            schedule_hour_type: 2,
            currentDays: WEEKDAYS[new Date().getDay()],
            currentDaysStatus: false,
            scheduleMemberButton: false,
            userToaster: false,
            userToasterMsg: '',
            userToasterColor: '',
            specificdaystatus: false,
            modalStatusAdjustHour: false,
            priority: false
        };
    }

    componentDidMount() {
        this.loadData({});
    }

    static directionConverter(order) {
        if (order === "asc") {
            return "ascending";
        } else if (order === "desc") {
            return "descending";
        } else {
            return null;
        }
    }

    handleSort = (clickedColumn) => {
        const { _sort, _order } = this.state;

        let newOrder = _order === "asc" ? "desc" : "asc";
        if (_sort !== clickedColumn) {
            newOrder = "asc";
        }

        this.loadData({
            _sort: clickedColumn,
            _page: 1,
            _order: newOrder,
        });
    };

    onChangeLimit = (event, data) => {
        if (data.value !== this.state._limit) {
            this.loadData({ _limit: data.value, _page: 1 });
        }
    };

    onSubmitFilter = (filter) => {
        if (filter !== this.state.q) {
            this.loadData({ q: filter, _page: 1 });
        }
    };

    onChangePage = (event, data) => {
        const { activePage } = data;
        if (activePage !== this.state._page) {
            this.loadData({ _page: activePage });
        }
    };

    modalbuttofun = (data) => {
        if (data.modalType == "Delete") {
            this.onDeleteCompletedChatAction();
        }
        // if (data.modalType == 'Popup') {
        //   this.setState({
        //     modalstatus: false,
        //     modaltitle: '',
        //     modalmessage: '',
        //     modalbuttonmsg: {},
        //     modalType: ''
        //   });
        // }
    };


    modalClose = (type = '') => {
        this.setState({
            modalStatusAdjustHour: false,
            modaltitle: "",
            modalmessage: "",
            modalbuttonmsg: {},
            modalType: "",
            modaldata: {},
            selectedOptions: [],
            modalIndex: ''
        });
    };
    reloadDataTable = () => {
        alert("datatable");
        this.loadData();
    };


    loadData = (params) => {
        const newState = Object.assign({}, this.state, params, { loading: false });
        queryParams.forEach(function (element) {
            if (!(element in params)) {
                params[element] = newState[element];
            }
        });

        let u = new Date().toString().match(/([-\+][0-9]+)\s/)[1];
        let clientTimeZone = u.substring(0, 3) + ":" + u.substring(3, u.length);
        let time_zone = clientTimeZone;

        params["time_zone"] = time_zone;
        const ed = this.state.scheduledate;
        const day = (ed.getDate() < 10 ? '0' : '') + ed.getDate();
        const month = (ed.getMonth() + 1 < 10 ? '0' : '') + (ed.getMonth() + 1);
        const year = ed.getFullYear(); // 4-digit year
        const dateFormat = `${year}-${month}-${day}`;
        params["schedule_date"] = dateFormat;
        params['schedule_hour_type'] = this.state.schedule_hour_type;
        params['current_day'] = this.state.scheduledate.getDay();
        const esc = encodeURIComponent;
        const query = Object.keys(params)
            .map((k) => esc(k) + "=" + esc(params[k]))
            .join("&");

        let totalCountQuery = "";
        if (params.q !== "") {
            totalCountQuery = `q=${params.q}`;
        }

        this.setState(newState, () => {
            this.setState({ loading: true });
            api.manageUser.getVoiceScheduleList(query).then((data) => {
                try {
                    let datatableRowstemp;
                    if (data.data.totalrecord) {
                        datatableRowstemp = data.data.list.map((list, index) => (
                            <DatatableRow
                                key={index}
                                list={list}
                                listindex={index}
                                handleSelectMember={this.onSelectMember}
                            />
                        ));
                    } else {
                        datatableRowstemp = (
                            <Table.Row>
                                <Table.Cell colSpan={7} textAlign="center">
                                    No record found.
                                </Table.Cell>
                            </Table.Row>
                        );
                    }
                    this.setState({
                        datatableRows: datatableRowstemp,
                        totalCount: data.data.totalrecord,
                        lists: data.data.list,
                        currentDaysStatus: data.data.current_day_status,
                        priority: data.data.priority
                    });
                } catch (err) {
                    console.log(err);
                }
                const newState = Object.assign({}, this.state, params, {
                    loading: false,
                });
                // console.log(newState);
                this.setState(newState);
            });
        });
    };
    handleCalendarChange = (e) => {

        this.setState({ scheduledate: e, currentDays: WEEKDAYS[e.getDay()] }, () => {
            // load list based on selected date
            this.loadData({});
        });
    }
    onChangeScheduleHourType = (e) => {
        this.setState({
            schedule_hour_type: e.target.value
        }, () => {
            this.loadData({});
        })

    }

    // Function to find index of a given time in the time list
    findIndex = (time, timeList) => {
        return timeList.findIndex(element => element === time);
    }


    onHadnleHourSelect = (e, memberId, scheduleId, index, listItem) => {
        e.preventDefault();
        const alllist = this.state.lists;
        const hourList = Object.keys(this.props.hourList);

        let availableHourData = alllist[index].hasOwnProperty('availableHour') ? alllist[index].availableHour : [];
        alllist[index].availableHour = availableHourData;
        if (availableHourData.length) {
            // Finding start index
            const startIndex = this.findIndex(availableHourData[0], hourList);

            // Finding end index
            const endIndex = this.findIndex(availableHourData[availableHourData.length - 1], hourList);
            alllist[index].startIndexHour = startIndex;
            alllist[index].endIndexHour = endIndex;
        } else {
            alllist[index].startIndexHour = null;
            alllist[index].endIndexHour = null;
            if (this.state.schedule_hour_type == 1) {
                const currentDayData = this.props.timeZoneData.listdata[this.state.scheduledate.getDay()];
                if (currentDayData.working) {
                    alllist[index].availableHour = hourList;
                }
                if (!currentDayData.working && !currentDayData.closed) {
                    alllist[index].availableHour = hourList.filter((item, index) => index>= currentDayData.open_hour_start_index && index <=currentDayData.open_hour_end_index);
                }
            }

            if (this.state.schedule_hour_type == 2) {
                const currentDayData = this.props.timeZoneData.listdata[this.state.scheduledate.getDay()];                
                if (currentDayData.closed) {
                    alllist[index].availableHour = hourList;
                }
                if (!currentDayData.working && !currentDayData.closed) {                    
                    alllist[index].availableHour = hourList.filter((item, index) => (index < currentDayData.open_hour_start_index) || (index > currentDayData.open_hour_end_index));                    
                }
            }
        }
        this.setState({
            lists: alllist
        }, () => {
            this.setState({
                modalStatusAdjustHour: true,
                modaltitle: "Adjust notification hour",
                modalmessage: "",
                modalbuttonmsg: {},
                modalType: "",
                modaldata: this.state.lists[index],
                selectedOptions: [],
                modalIndex: index
            });
        })        
    }

    clearHour = () => {
        // let modaldata = this.state.modaldata;
        // modaldata.availableHour = [];
        // modaldata.startIndexHour = null;
        // modaldata.endIndexHour = null;
        let allList = this.state.lists;
        allList[this.state.modalIndex].availableHour = [];
        allList[this.state.modalIndex].startIndexHour = null;
        allList[this.state.modalIndex].endIndexHour = null;
        this.setState({
            lists: allList,
            modaldata: allList[this.state.modalIndex]
        })
    }

    onSaveAvailableHour = () => {
        // let modaldata = this.state.modaldata;
        // console.log(modaldata);
    }

    handleNotificationSentTime = (e) => {
        const allList = this.state.lists;
        allList[this.state.modalIndex].notification_sent_times = e.target.value;
        this.setState({
            lists: allList
        });
    }

    handleAvailableHour = (hour, index) => {
        let modaldata = this.state.modaldata;
        const allList = this.state.lists;
        
        // if ((!modaldata.startIndexHour && modaldata.startIndexHour !== 0) && !modaldata.endIndexHour) {
        //     modaldata.startIndexHour = index;
        // } else if (modaldata.startIndexHour && modaldata.startIndexHour && index < modaldata.startIndexHour) {
        //     modaldata.startIndexHour = index;
        // } else if (modaldata.startIndexHour != null && index !== modaldata.startIndexHour) {
        //     modaldata.endIndexHour = index;
        // }

        // const hourList = Object.keys(this.props.hourList);
        // let aaa = [];
        // if (modaldata.startIndexHour != null && modaldata.endIndexHour) {
        //     aaa = hourList.filter((item, index) => index >= modaldata.startIndexHour && index <= modaldata.endIndexHour);
        // } else if ((modaldata.startIndexHour === 0 || modaldata.startIndexHour) && !modaldata.endIndexHour) {
        //     aaa = hourList.filter((item, index) => index === modaldata.startIndexHour);
        // }
        const findIndexHour = allList[this.state.modalIndex].availableHour.findIndex((item, index) => item === hour);
        if (findIndexHour === -1) {
            allList[this.state.modalIndex].availableHour.push(hour);
        }else{
            allList[this.state.modalIndex].availableHour.splice(findIndexHour, 1);
        }
        // modaldata.availableHour = aaa;

        this.setState({
            lists: allList,
            modaldata: allList[this.state.modalIndex]
        })
    }

    onSelectMember = (e, memberId, scheduleId, index, listItem) => {
        const alllist = this.state.lists;
        alllist[index].hasSchedule = !alllist[index].hasSchedule;
        this.setState({
            lists: alllist
        }, () => {
            let datatableRowstemp = this.state.lists.map((list, index) => (
                <DatatableRow
                    key={index}
                    list={list}
                    listindex={index}
                    handleSelectMember={this.onSelectMember}
                    handleHourSelect={this.onHadnleHourSelect}
                />
            ));
            this.setState({
                datatableRows: datatableRowstemp
            })
        })
    }

    handleSetPriority = (e, memberId, scheduleId, index, listItem) => {
        const alllist = this.state.lists;

        // filter priority that selected first        
        alllist[index].priority = e.target.value;
        if (e.target.value !== '' || e.target.value !== '#') {
            if (!alllist[index].hasSchedule) {
                alllist[index].hasSchedule = true
            }
        }
        this.setState({
            lists: alllist
        });
    }

    handleCurrentDayStatus = () => {
        this.setState({
            currentDaysStatus: !this.state.currentDaysStatus
        })
    }

    handleSpecificdaystatus = () => {
        this.setState({
            specificdaystatus: !this.state.specificdaystatus
        })
    }

    saveScheduleMember = () => {
        const allList = this.state.lists;                
        const checkSelected = this.state.priority ? allList.filter((item, index) => item.hasSchedule && (item.priority !== '#' && item.priority !== "0" && item.priority !== null)) : allList.filter((item, index) => item.hasSchedule);
        const deletedList = allList.filter((item, index) => !item.hasSchedule && item.scheduleId);
        console.log(checkSelected);
          
        if (this.state.priority && !checkSelected.length) {
            toast.error('You have checked the Set Priority List option but not selected any member. Please uncheck Set Priority List option or select at least one member', {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "dark",
                
			});
            return;
        }
        
        this.setState({
            loading: true
        });
        const ed = this.state.scheduledate;
        const day = (ed.getDate() < 10 ? '0' : '') + ed.getDate();
        const month = (ed.getMonth() + 1 < 10 ? '0' : '') + (ed.getMonth() + 1);
        const year = ed.getFullYear(); // 4-digit year
        const dateFormat = `${year}-${month}-${day}`;
        const objectToSend = {
            list: checkSelected,
            deletedList: deletedList,
            schedule_date: dateFormat,
            schedule_hour_type: this.state.schedule_hour_type,
            currentDaysStatus: this.state.currentDaysStatus,
            currentDay: this.state.scheduledate.getDay(),
            specificdaystatus: this.state.specificdaystatus,
            priorityStatus: this.state.priority
        };
        api.manageUser.saveVoiceScheduleMember(objectToSend).then((res) => {            
            if (res.status) {
                this.setState({
                    loading: false,
                    userToasterColor: 'green',
                    userToaster: true,
                    userToasterMsg: 'Notification schedule has been successfully saved.'
                });
                this.loadData({ _page: this.state._page });
                setTimeout(() => {
                    this.setState({
                        userToasterColor: '',
                        userToaster: false,
                        userToasterMsg: ''
                    });
                }, 3000);
            }
        }).catch((err) => {
            console.log(err);
        })
    }

    createExternalLink = (e) => {
        e.preventDefault();
        const base_url = config.serverBaseURL;
        let externalLink = `${base_url}/voiceNotificationSetting/schedule/${btoa(this.props.userData.user_id)}`;
        document.getElementById('externallink').innerText = externalLink;
        document.getElementById('externallinkDiv').style.display = 'block';
    }

    copyLink = (id) => {
        const linktocopy = document.getElementById(id).innerText;
        navigator.clipboard.writeText(linktocopy);
        const linkcopysuccess = document.getElementById('linkcopysuccess');
        linkcopysuccess.style.display = 'inline';
        setTimeout(() => {
            linkcopysuccess.style.display = 'none';
        }, 1000);
    }

    handlePrioritySelect = () => {
        this.setState({
            priority : !this.state.priority
        }, () => {
            if (!this.state.priority) {
                const allList = this.state.lists;
                allList.forEach((item, index) => {
                    allList[index].priority = '#';
                });
                this.setState({
                    lists: allList
                });
            }
        })
    }
    render() {
        return (
            <div id='schedulenotification'>
                <Segment>
                    {this.state.loading && <div className="ui loading form"></div>}
                    <div className='col-md-12' style={{ marginBottom: 10 }}>
                        <Message
                            color={
                                this.state.userToasterColor ? this.state.userToasterColor : "teal"
                            }
                            style={{
                                display: this.state.userToaster ? "block" : "none",
                                marginTop: 30,
                            }}
                        >
                            {this.state.userToasterMsg}
                        </Message>
                    </div>
                    <div className='row'>
                        <div className='col-md-5'>
                            <Calendar
                                onChange={this.handleCalendarChange}
                                value={this.state.scheduledate}
                                calendarType="US"
                            />
                            <p><a onClick={(e) => this.createExternalLink(e)} href='#'>Click here for an external URL that will update this schedule</a></p>
                            <div id="externallinkDiv" style={{ display: 'none' }}><span id="externallink"></span> <i className="fa fa-copy" style={{ marginLeft: 20, cursor: 'pointer' }} onClick={() => this.copyLink('externallink')}></i> <i id="linkcopysuccess" className="fa fa-check" style={{ color: 'green', fontSize: '1.3em !important', marginLeft: 5, display: 'none' }}></i></div>
                        </div>
                        <div className='col-md-7'>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <div className='col-md-6'>
                                    <div className='form-group'>
                                        <label>Notification List</label>
                                        <select
                                            className='form-control'
                                            value={this.state.schedule_hour_type}
                                            name='schedule_hour_type'
                                            onChange={(e) => this.onChangeScheduleHourType(e)}
                                        >
                                            <option value={2}>After Hours</option>
                                            <option value={1}>Open Hours</option>

                                        </select>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <input checked={this.state.priority} type="checkbox" onChange={this.handlePrioritySelect} id="priority"  /> <label htmlFor="priority" style={{marginBottom: 0, fontSize: '1.12rem', fontWeight: 500, color: '#848588', cursor: 'pointer'}}>Set priority list</label>
                                </div>
                            </div>

                            <div className='clearfix'></div>
                            {/* {this.state.lists.length && */}
                                <MemberListNotification
                                    lists={this.state.lists}
                                    datatableRows={this.state.datatableRows}
                                    totalCount={this.state.totalCount}
                                    totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
                                    currentPage={this.state._page}
                                    onChangePage={this.onChangePage}
                                    column={this.state._sort}
                                    onChangeLimit={this.onChangeLimit}
                                    limit={this.state._limit.toString()}
                                    handleSelectMember={this.onSelectMember}
                                    handleHourSelect={this.onHadnleHourSelect}
                                    priority={this.state.priority}
                                    handleSetPriority={this.handleSetPriority}
                                />
                            {/* } */}
                            {/* <DatatableTable
                                lists={this.state.lists}
                                datatableRows={this.state.datatableRows}
                                totalCount={this.state.totalCount}
                                totalPages={Math.ceil(this.state.totalCount / this.state._limit)}
                                currentPage={this.state._page}
                                onChangePage={this.onChangePage}
                                column={this.state._sort}
                                onChangeLimit={this.onChangeLimit}
                                limit={this.state._limit.toString()}
                            /> */}
                            <p className='alldaysschedule'>
                                <span>
                                    <input
                                        type='checkbox'
                                        name='alldaysschedule'
                                        id='alldaysschedule'
                                        checked={this.state.currentDaysStatus}
                                        onChange={this.handleCurrentDayStatus}
                                    />
                                </span>
                                <span>
                                    <label htmlFor='alldaysschedule'>Use these notifications rules for all {this.state.currentDays}'s</label>
                                </span>
                            </p>

                            {/* <p className='alldaysschedule'>
                                <span>
                                    <input
                                        type='checkbox'
                                        name='specificday'
                                        id='specificday'
                                        checked={this.state.specificdaystatus}
                                        onChange={this.handleSpecificdaystatus}
                                    />
                                </span>
                                <span>
                                    <label htmlFor='specificday'>Use these notifications rules for selected date only</label>
                                </span>
                            </p> */}
                            <div className="clearfix"></div>
                            <button
                                className="btn linear-gradient yr-add-new float-right"
                                onClick={this.saveScheduleMember}
                                disabled={this.state.scheduleMemberButton}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                    {this.state.modalStatusAdjustHour &&
                        <DialogBoxAdjustHour
                            show={this.state.modalStatusAdjustHour}
                            modalClose={this.modalClose}
                            hourList={this.props.hourList}
                            modalData={this.state.lists[this.state.modalIndex]}
                            handleAvailableHour={this.handleAvailableHour}
                            clearHour={this.clearHour}
                            saveAvailableHour={this.onSaveAvailableHour}
                            openHourData={this.props.timeZoneData.listdata[this.state.scheduledate.getDay()]}
                            schedule_hour_type={this.state.schedule_hour_type}
                            handleNotificationSentTime={this.handleNotificationSentTime}
                        />
                    }
                </Segment>
                <ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
				/>
            </div>
        );
    }
}


export default React.forwardRef((props, ref) => <ScheduleCalendar 
ref={ref} {...props}
/>);
