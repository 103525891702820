import React, { useState } from 'react'
import { Message } from 'semantic-ui-react';
import "../../../../Modal/dialog.css";
import CustomErrorField from "../../../../Form/CustomErrorField";

const DialogBoxAdjustHour = (props) => {
    const selecteRangeBox = (hour, index, hourInOpen) => {
        if (hourInOpen) {
            return;
        }
        props.handleAvailableHour(hour, index);
    }
    return (
        <>
            <div
                className={props.show ? "modal fade show" : "modal fade"}
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
                aria-labelledby="staticBackdrop"
                aria-hidden="true"
                style={{ display: props.show ? "block" : "none" }}
            >
                <div className="modal-dialog modal-dialog-centered modal-width" role="document">
                    <div className="modal-content">
                        <button
                            type="button"
                            className="close linear-gradient"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => props.modalClose('adjustHour')}
                        >
                            X
                        </button>
                        <div className="modal-header">
                            <h5 className="modal-title">{props.title}</h5>
                        </div>
                        {props.modalFormLoader && <div className="ui loading form"></div>}
                        <div className="modal-body">
                            <div className="kt-portlet__body py-0">
                                <div className="kt-section kt-section--first">
                                    <div>
                                        <ul class="range-labels">
                                            {Object.keys(props.hourList).map((hour, index) => {
                                                let hourInOpen = false;
                                                const {open_hour_start_index, open_hour_end_index, working} = props.openHourData;
                                                if (open_hour_start_index && open_hour_end_index) {
                                                    if (index >= open_hour_start_index && index <= open_hour_end_index && props.schedule_hour_type == 2) {
                                                        hourInOpen = true;
                                                    }                                                    
                                                }
                                                if (props.schedule_hour_type == 2 && working) {
                                                    hourInOpen = true;
                                                }                                        
                                                return (
                                                    <li key={index} className={hourInOpen ? "disabled" : ''} onClick={() => selecteRangeBox(hour, index, hourInOpen)}>
                                                        <label>{hour}</label>
                                                        <div className={props.modalData?.availableHour.includes(hour) ? 'rangeBox selected' : 'rangeBox'}></div>
                                                    </li>
                                                )

                                            })}
                                        </ul>
                                        <div>
                                            <p style={{fontWeight: 500}}>Send notification &nbsp;
                                            <select name='' value={props.modalData?.notification_sent_times} onChange={(e) => props.handleNotificationSentTime(e)}>
                                                <option value={1}>1</option>
                                                <option value={2}>2</option>
                                                <option value={3}>3</option>
                                            </select>

                                            &nbsp; time
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="kt-portlet__foot">
                            <div className="kt-form__actions">
                                <div className="row">
                                    <div className="col-lg-12 d-flex justify-content-end">
                                        <input name="subaccount_id" type="hidden" value={''} />
                                        <button type="reset" onClick={() => props.modalClose('adjustHour')} className="btn linear-gradient-cancel yr-cancelbtn mg-r"><span> Close </span></button>
                                        <button type='reset' className="btn linear-gradient-cancel yr-cancelbtn mg-r" onClick={() => props.clearHour()}><span>Clear</span></button>
                                        {/* <button type="button" onClick={() => props.saveAvailableHour()} className="btn linear-gradient yr-submitbtn">Submit</button> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DialogBoxAdjustHour
