import React from "react";
import CustomErrorField from "../../../../Form/CustomErrorField";
import './parseData.css';
import api from "../../../../../api";
class DataParseRule extends React.Component {
    constructor(props){
        super(props)
        this.state={
            listOfData : [ {rule:"",edit:false}],
            showNotification:false,
            alertData:{type:"sucess",message:"Data Parse Rule Updated Successfully."}
        }
    }
    onload = () => {
        api.botbuilder.getParseDaataConfigration({workspace_id:atob(this.props.workspace_id)}).then( res => {
            console.log(res,"called");
            if(res.data && res.data.length > 0){
                this.setState({
                    listOfData : res.data
                })
            }else{
                this.setState({
                    listOfData : [ {rule:"",edit:true}]
                })
            }
        }
        )
        .catch((err) => {
            console.log(err)
        })
    }
    componentDidMount(){
        this.onload()
    }
    renderParseConfigration = () => {
            return (
            this.state.listOfData.map((rule,index) => <li className="row mb-3">
            <div className="col-10">
                <input type="text" className="form-control" placeholder="Parse Rule" onChange={(event => this.handleParseRuleChange(index,event.target.value) )} value={rule.rule} defaultValue={rule.rule} disabled={!rule.edit}/> 
            </div>
           {/* { !rule.edit && <div className="col-1"><button type="button" className="icon-btn-spl" onClick={this.handleEdit(index)}><i class="la la-edit blue" title="Edit"></i></button></div>} */}
            {this.state.listOfData.length > 1 && 
            <div className="col-1"><button type="button" className="icon-btn-spl" onClick={this.removeParseRule(index)}><i class="la la-trash red" title="Delete"></i></button>
            </div>}
        </li>))
    }
    handleEdit = index => () => {
        this.setState({
            listOfData : this.state.listOfData.map((rule,i) => i === index ? {...rule,edit:!rule.edit} : rule)
        })
    }
    handleParseRuleChange = (index,value) => {
        this.setState({
            listOfData : this.state.listOfData.map((rule,i) => i === index ? {...rule,rule:value} : rule)
        })
    }
    removeParseRule = (index) => () => {
        this.setState({
            listOfData : this.state.listOfData.filter((rule,i) => i !== index)
        })
    }
    addParseRule = () => {
        this.setState({
            listOfData : [...this.state.listOfData,{rule:"",edit:true}]
        })
    }
    importParseRule = () => {
        api.botbuilder.importParseDaataConfigration({workspace_id:this.props.workspace_id}).then( res => {
            if(res.status){
                 this.onload();
                 this.setState({
                 listOfData : [...res.data,{rule:"",edit:true}]
                });
            }
            this.showNotification("import");
           
        }).catch((err) => {
            console.log(err)
        })
        
    }
    saveDataParsetRule = () => {
        let data = {workspace_id:atob(this.props.workspace_id),data:[...this.state.listOfData]}
        let filteredData = data.data.filter( datt => datt.rule.trim() != '')
        console.log(filteredData,"filteredData");
        if(filteredData.length > 0){
            data = {...data,data:filteredData}
            api.botbuilder.saveParseDaataConfigration({data:data}).then( res => {
                this.showNotification();
                this.onload()
            }).catch((err) => {
                
            })
        }else{
            this.showNotification('alert');
        }
    }
    showNotification = (msg = "") => {
        if(msg == "alert"){
            this.setState({
                alertData:{type : "red",message:"Please set at least on data parsing rule."}
            })
        }
        if(msg=='import'){
            this.setState({
                alertData:{type : "sucess",message:"Data imported successfully."}
            })
        }   
        this.setState({
            showNotification:true
        })
        setTimeout(() => {
            this.setState({
                showNotification:false,
                alertData:{type : "sucess",message:"Data Parse Rule Updated Successfully."}
            })
        }, 2000);
    }
    render(){
        return (
            <>
            <div>
                <div className="yr-chat-round-box yr-closebtn">
                    <button
                        type="button"
                        class="close linear-gradient"
                        onClick={() => this.props.popupClose()}
                    >
                        &#10006;
                    </button>
                    <div className="box-title">Add Data Parse Rule 
                        <button type="button" title="Add Data Parse Rule." className="btn btn-sm btn-clean btn-icon" onClick={(e) => this.addParseRule()}>
                            <img src="../../assets/media/icons/plus.svg" alt="" width={15} />
                        </button>
                        {this.props.bot_type=='WEB' && <button
                                    type="button"
                                    title="Import Data Parse Rule From Voice."
                                    className="btn linear-gradient-cancel yr-cancelbtn mg-r"
                                    onClick={(e) => this.importParseRule()}
                                  >
                                    <span> Import from turbo </span>
                                  </button>
                       }
                    </div>
                    <div className="row">
                        {this.state.showNotification && <div className={`ui message ${this.state.alertData.type === "sucess" ? "green" : "red"}`} >{this.state.alertData?.message}</div>}
                        <ul className="col-12">
                            {this.renderParseConfigration()}
                        </ul>
                    </div>
                    <div className="row">
                        <div className="col-12 d-flex justify-content-end">
                            <button
                            type="reset"
                            class="btn yr-cancelbtn linear-gradient-cancel mg-r"
                            onClick={() => this.props.popupClose()}
                            >
                                <span> Close </span>
                            </button>
                            <button
                            type="submit"
                            className="btn linear-gradient yr-submitbtn"
                            onClick={() => this.saveDataParsetRule()}
                            >
                                Save
                            </button>
                        </div>
                    </div>
                 </div>
            </div>
            </>
        )
    }
}

export default DataParseRule;
