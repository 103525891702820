import React from "react";
import { Table } from "semantic-ui-react";
import PropTypes from "prop-types";
import { Link, Redirect } from "react-router-dom";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import "../email.css";
import util_funs from "../../../../../util_funs";

function createMarkup(html) {
  return { __html: html };
}
const status = (status) => {
  if (status == "Active") {
    var str = '<span><i class="fa fa-circle green"></i>Active</span>';
  } else if (status == "Stopped") {
    var str = '<span><i class="fa fa-circle red"></i>Stopped</span>';
  } else if (status == "Draft") {
    var str =
      '<span><i class="fab fa-firstdraft" style="color: rgb(57, 129, 181);"></i>Draft</span>';
  } else if (status == "Archived") {
    var str =
      '<span><i class="fas fa-archive" style="color: rgb(57, 129, 181);"></i>Archived</span>';
  } else if (status == "Paused") {
    var str =
      '<span><i class="fas fa-pause" style="color: rgb(57, 129, 181);"></i> Paused</span>';
  }
  return ReactHtmlParser(str);
};

export const DatatableRow = (props) => {
  //Sagar
  const [...bucketList] = props.list.bucketList;
  const bucketType = JSON.parse(props.list.bucket_type)
  const hasEmail = bucketType.filter(bucket=>bucket.id.includes('email'))
  const emailTypeIsPresent = hasEmail.length>0
  //Sagar End
  return (
    <Table.Row>
      <Table.Cell>
        <div style={{ width: "100px" }}>
          {props.list.user_type != "main" ? (
            <span>
              {
                props.list.status != "Active" && 
                <a
                  ls_user_id={props.list.id}
                  className="btn btn-icon"
                  onClick={() => props.createPlaybook("Edit", props.list)}
                  title="Edit"
                >
                  <i className="la la-edit blue"></i>
                </a>
              }
              <a
                onClick={props.list.status=='Active' ? () =>  props.createPlaybook("validateDelete", props.list.status) : () => props.createPlaybook("Delete", props.list)}
                title="Delete"
                className="btn btn-icon gray"
              >
                <i className="la la-trash"></i>
              </a>{" "}
            </span>
          ) : (
            ""
          )}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div style={{ width: "200px" }}>
          <a href={"/marketing/playbook/" + util_funs.encrypt_url(props.list.id)}>
            {props.list.title}
          </a>
        </div>
      </Table.Cell>
      <Table.Cell>
        <div style={{ width: "150px" }}>
        {bucketType.length > 0
            ? bucketType.map((bucket) => {
              return (
                <span className="mh-bucketNameTag mh-bucketNaming" key={bucket.name}>
                  {bucket.name}
                </span>
              );
            })
            : null}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div style={{ width: "150px" }}>{props.list.display_start_date}</div>
      </Table.Cell>
      <Table.Cell>
        <div style={{ width: "150px" }}>{props.list.display_next_start_date}</div>
      </Table.Cell>
      <Table.Cell>
        <div style={{ width: "150px" }}>
          {props.list.display_next_schedule_date}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div style={{ width: "150px" }}>{props.list.selected_time_zone}</div>
      </Table.Cell>
      <Table.Cell>
        <div style={{ width: "150px" }}>
          {/* Sagar Start */}
          {bucketList.length > 0
            ? bucketList.map((bucket) => {
              return (
                <span className="mh-bucketNameTag mh-bucketNaming" key={bucket.id}>
                  {bucket.name}
                </span>
              );
            })
            : null}
          {/* Sagar End */}
        </div>
      </Table.Cell>
      <Table.Cell>
        <div style={{ width: "150px" }}>{props.list.eCount}</div>
      </Table.Cell>
      <Table.Cell>
        <div style={{ width: "100px" }}>{status(props.list.status)}</div>
      </Table.Cell>

      <Table.Cell>
        <div style={{ width: "150px" }}>{props.list.added_datetime}</div>
      </Table.Cell>
      <Table.Cell style={{ textAlign: "right" }}>
        <a
          style={{ color: "#087ab6" }}
          href="#"
          class="btn btn-icon dropdown-toggle"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
        >
          Manage{" "}
        </a>
        <div className="dropdown-menu dropdown-menu-right yr-custom-menu">
          <ul className="table-list-bg">
            <li>
              <a
                className="cursorpointer"
                onmouseover="adClass()"
                onmouseleave="rdClass()"
                onClick={() => props.createPlaybook("Edit", props.list)}
              >
                <i class="far fa-edit"></i>
                <span>Edit</span>
              </a>
            </li>
            {emailTypeIsPresent && <li className="analiytics-link-email">
              <Link
                to={`playBookBranchWiseStats/${util_funs.encrypt_url(props.list.id)}`}
                className="cursorpointer"
              >
                <i class="fa fa-chart-bar"></i>
                <span>Analiytics</span>
              </Link>
            </li>}
            <li>
              <a
                className="cursorpointer"
                onClick={() => props.bucketAction("duplicate", props.list.id)}
              >
                <i class="fa fa-copy"></i>
                <span>Duplicate</span>
              </a>
            </li>
            {props.list.status != "Active" && props.list.status != "Archived" && props.list.status != "Paused" && (
              <li>
                <a
                  className="cursorpointer"
                  onClick={() => props.bucketAction("selectdate", props.list)}
                >
                  <i class="far fa-sticky-note"></i>
                  <span>Start</span>
                </a>
              </li>
            )}
            {props.list.status === "Active" && (
              <>
                <li>
                  <a
                    className="cursorpointer"
                    onClick={() => props.bucketAction("edit_campaign", props.list)}
                  >
                    <i class="fas fa-mail-bulk"></i>
                    <span>Edit Campaign </span>
                  </a>
                </li>
                <li>
                  <a
                    className="cursorpointer"
                    onClick={() =>
                      props.bucketAction("pause_campaign", props.list.id)
                    }
                  >
                    <i class="fas fa-pause"></i>
                    <span>Pause Campaign </span>
                  </a>
                </li>
              </>
            )}

            {props.list.status != "Archived" && props.list.status != "Paused" && (
              <li>
                <a
                  className="cursorpointer"
                  onClick={() => props.bucketAction("archive", props.list.id)}
                >
                  <i class="far fa-folder-open"></i>
                  <span>Archive</span>
                </a>
              </li>
            )}
            {props.list.status == "Paused" && (
              <li>
                <a
                  className="cursorpointer"
                  onClick={() => props.bucketAction("resume", props.list.id)}
                >
                  <i class="fa fa-play"></i>
                  <span>Resume</span>
                </a>
              </li>
            )}
            {props.list.status === "Archived" && (
              <li>
                <a
                  className="cursorpointer"
                  onClick={() => props.bucketAction("restore", props.list.id)}
                >
                  <i class="fas fa-trash-restore"></i>
                  <span>Restore</span>
                </a>
              </li>
            )}
          </ul>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

DatatableRow.propTypes = {
  list: PropTypes.object.isRequired,
};
