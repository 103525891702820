import React, { useState, Component } from 'react';
import Picker, { SKIN_TONE_MEDIUM_DARK } from 'emoji-picker-react';
import axios from "../../../../config/axios";
import api from "../../../../api";
import sendChat from "../../../../media/icons/sendChat.png"

const LiveChatBoxFooter = props => {
    const [displayIntoJoinConversation, setDisplayIntoJoinConversation] = useState("block")
    const [chosenEmoji, setChosenEmoji] = useState(null);
    const [textMessage, setTextMessage]=useState('');
    

    const onEmojiClick = (event, emojiObject) => {
        var textAreaVal = document.getElementById(props.state.anonymous_user_id).value;
        document.getElementById(props.state.anonymous_user_id).value = textAreaVal + emojiObject.emoji;
        setTextMessage(textAreaVal + emojiObject.emoji)
    };


    const displayIntoJoinConversationFun = e => {
        // console.log("joininto displayIntoJoinConversationFun", e, props.state.join_into_conversation, props.state.join_into_conversation_fromID, props.sessionUser.user_id, props.closeConversation == "n", (props.state.join_into_conversation == 1 && (props.state.join_into_conversation_fromID == props.sessionUser.user_id) && props.closeConversation == "n"));
        return (
            (props.state.join_into_conversation == 1 && (props.state.join_into_conversation_fromID == props.sessionUser.user_id) && props.closeConversation == "n") ? "none" : "block"
        )
    }

    const isReadOnlyAvailable = e => {
        return (
            (props.state.join_into_conversation == 1 && (props.state.join_into_conversation_fromID == props.sessionUser.user_id) && props.closeConversation == "n") ? false : true
        )
    }

    const joinIntoTheConversation = (anonymous_user_id) => {
        // console.log("joininto", props.state.join_into_conversation_fromID, props.sessionUser.user_id, props.closeConversation, anonymous_user_id);
        if ((props.state.join_into_conversation_fromID != "" && (props.state.join_into_conversation_fromID != props.sessionUser.user_id))) {
            return;
        }

        if ((props.closeConversation == "y")) {
            return;
        }

        // alert("d");
        api.liveChat
            .joinIntoTheConversation({
                anonymous_user_id: anonymous_user_id,
                user_id: props.sessionUser.user_id,
                user_subaccount_id: props.sessionUser.user_subaccount_id,
            })
            .then((resp) => {
                // console.log('joininto response', resp, resp.data);
                if (resp.data && resp.data.status) {
                    var join = resp.data.data.join;
                    var sendTextInProcess = resp.data.data.sendTextInProcess;
                    var defaultJumpInMsg = resp.data.data.default_jump_in_msg;
                    var company = props.sessionUser.company;
                    var chatBot_id = props.sessionUser.chatBot_id;
                    var chat_logo = props.sessionUser.chat_logo;
                    var primary_color = props.sessionUser.primary_color;
                    if (sendTextInProcess == null)
                        sendTextInProcess = sendTextInProcess
                    else
                        sendTextInProcess = sendTextInProcess.trim()

                    if (join == 'success') {
                        // console.log('joininto success', join, defaultJumpInMsg);
                        if (defaultJumpInMsg.trim() != '') {
                            props.sendJumpingMessage(company, chatBot_id, chat_logo, primary_color, anonymous_user_id, defaultJumpInMsg, sendTextInProcess, props.socket)
                        } else {

                            props.sendJumpingMessage(company, chatBot_id, chat_logo, primary_color, anonymous_user_id, null, sendTextInProcess, props.socket)
                        }

                    } else {
                        //alert('A user is already chatting.');
                        // $('#useralreadychatting').show();
                        if (join == 'sameuser') {
                            setDisplayIntoJoinConversation("none")
                        } else {
                            // $('#useralreadychatting').modal('show');
                        }
                    }
                } else {

                }
            })
            .catch((e) => {
                console.log(e);
            });
    }

    const sendMessage=(e)=>{
        console.log(textMessage)
        props.sendAgent(props.state.anonymous_user_id, textMessage)
        document.getElementById(props.state.anonymous_user_id).value = "";
        // e.preventDefault();
        return false;
    }

    const textAreaChange = (e) => {
        // console.log('joininto textarea' , e);
        var key = window.event.keyCode;
        var shiftKey = window.event.shiftKey;
        console.log("shiftkey",key,shiftKey);
        // console.log('joininto textarea key' , key, shiftKey);
        // If the user has pressed enter
        if (key === 13) {
            // console.log('joininto textarea key = 13' , key, shiftKey);
            if (shiftKey == true) {
                // console.log('joininto textarea key = 13, shiftKey == true' , key, shiftKey);
            } else {
                // console.log('joininto textarea key = 13, shiftKey == false' , key, shiftKey, props.state.anonymous_user_id, e.target.value);
                props.sendAgent(props.state.anonymous_user_id, e.target.value)
                document.getElementById(props.state.anonymous_user_id).value = "";
                e.preventDefault();
                return false;
            }
        }
        else {
            // console.log('joininto textarea key != 13' , key, shiftKey);
            return true;
        }
    }
    return (

        <div className="kt-portlet__foot right-footer" style={{ display: props.state.displayTextArea }}>
            <div className="btn-group">
                <button type="button" className="btn d-flex">
                    <span className="chat-img mh-chatImg">
                        <img className="kt-widget3__img mh-companyChatLogo" src={props.sessionUser.logoUrl} alt="" />

                    </span>
                    {/* <Dropdown icon='angle down' className='icon yr-dropdown'>
                                    <Dropdown.Menu>
                                        <Dropdown.Item text='Action 1' />                                        
                                        <Dropdown.Item text='Action 1' />                                        
                                    </Dropdown.Menu>
                                </Dropdown> */}
                </button>
            </div>
            <div className="form-group reply-box join--conversation__box">
                <div
                    onClick={() => joinIntoTheConversation(props.state.anonymous_user_id)}
                    style={{ display: displayIntoJoinConversationFun() }}
                    className="linear-gradient mh-joinIntotheConversation">
                    {(props.closeConversation == "y") ? "This conversation has been closed." : (props.state.join_into_conversation_fromID != "" && (props.state.join_into_conversation_fromID != props.sessionUser.user_id)) ? "Someone is already chatting." : "Join into the conversation."}

                </div>
                <textarea onKeyPress={textAreaChange} onChange={(e)=>setTextMessage(e.target.value)} id={props.state.anonymous_user_id} style={{resize: 'none', height:"25px", paddingTop:"0px"}} readOnly={isReadOnlyAvailable()} type="text" className="form-control mh-commentTextArea" placeholder="Reply to the comment..." ></textarea>
            </div>
            <div>

            </div>
            <div className="btn-group chatty-icon">
            <button type="button" onClick={(e)=>sendMessage()} className="btn"><img style={{height:"20px"}} src={sendChat} title="" alt=""/></button>
                <button type="button" onClick={() => props.toggleEmogiesBar(props.state.displayEmogies, props.state.anonymous_user_id)} style={{pointerEvents: isReadOnlyAvailable() == true ? 'none' : 'auto'}} className="btn"><span className="chat-emoji" /> </button>
                <div style={{ display: props.state.displayEmogies }}>
                    {/* <Picker onEmojiClick={onEmojiClick} /> */}
                    <Picker preload={"true"} onEmojiClick={onEmojiClick} disableAutoFocus={true} groupNames={{ smileys_people: "PEOPLE" }} />
                </div>
            </div>
        </div>
    )
}





export default LiveChatBoxFooter;