import React from "react";
import CustomErrorField from "../../../../Form/CustomErrorField";
import api from "../../../../../api";
import Modal from "../../../ChatSetup/components/Modal";
import MapContainer from "./MapContainer";
import { WithContext as ReactTags } from 'react-tag-input';
import './bulkstyle.css';
import { ToastContainer, toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import ZipcodeBulk from "./ZipcodeBulk";

const KeyCodes = {
	comma: 188,
	enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const validateEmail = (emailText) => {
	// email format
	const formatEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
	if (emailText.match(formatEmail)) {
		return true;
	} else {
		return false;
	}
}
class ZipCodeRules extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
			questions: [""],
			errors: [],
			edit_id: "",
			zipcoderules: [],
			modalstatus: false,
			modaltitle: '',
			modalbody: '',
			deletemodalid: '',
			noservice_branch_id: '',
			noservice_branch_id_bulk: '',
			loading: true,
			receive_chat_notification: '',
			single: 'active',
			bulk: '',
			tags: [],
			emailError: '',
			zipcodeBulkData: [],
			send_lead_within: '',
			csvfile: null, 
			exclude_other_rule: 0,
			updateBulk: false,
			bulkzipid: '',
			loadingBulk: false,
			bulkdisable: false,
			phoneNumber:"",
			country_code:"+1",
			filterkey:'',
			zipfilter:false,
			deleteprogress:false
		};
	}

	componentDidMount() {
		this.onLoad();
	}

	componentDidUpdate(prevProps) {
		if (this.props.indextime != prevProps.indextime) {

			this.onLoad();
		}
	}

	onLoad = () => {
		api.botbuilder.getZipCodeRules({ branch_id: this.props.branch_id,q:this.state.filterkey }).then((data) => {
			try {
				// let branch_detail = this.state.branchDetails;
				// branch_detail.ai_list = data.list;
				// this.setState({ branchDetails: branch_detail });
				var noservice_branch_id = data.data.zipcoderules[0].noservice_branch_id
				if (this.props.zipcodenewbranch_id != '') {
					noservice_branch_id = this.props.zipcodenewbranch_id
				}
				
				const noservice_branch_id_bulk = data.data.zipcoderulesBulk.length>0 ? data.data.zipcoderulesBulk[0].noservice_branch_id :''
				// if (this.props.zipcodenewbranch_id != '') {
				// 	noservice_branch_id_bulk = this.props.zipcodenewbranch_id
				// }
				this.setState({ zipcoderules: data.data.zipcoderules, noservice_branch_id: noservice_branch_id, loading: false, receive_chat_notification: data.data.zipcoderules.length > 0 ? data.data.zipcoderules[0].receive_chat_notification : '0', zipcodeBulkData: data.data.zipcoderulesBulk, exclude_other_rule : data.data.excludeValue, noservice_branch_id_bulk:noservice_branch_id_bulk});
				// callback("success");
			} catch (err) {
				console.log(err);
			}
		});
	};

	changeZipcode = (data, index, type,auto=false) => {
		console.log("zipcode_code",type)
		let zipcode_rule = this.state.zipcoderules

		if (type == 'zipcode') {

			let regexp = /^[0-9\b]+$/

			if (data.target.value === '' || regexp.test(data.target.value)) {
				zipcode_rule[index].zipcode = data.target.value
			}
		}
		if (type == 'willing_travel') {
			//alert(data.target.value)
			zipcode_rule[index].willing_travel = data.target.value
		}

		if(type=="noservice_branch_id_bulk"){
			this.setState({ noservice_branch_id_bulk: data.target.value })
		}

		if (type == 'noservice_branch_id') {
			this.setState({ noservice_branch_id: data.target.value })
		} else {
			this.setState({
				zipcoderules: zipcode_rule,

			})
		}
       if(auto){
			setTimeout(() => {
				this.updateBranchforAll()
			}, 2000);
		
	   }

	}
	updateBranchforAll=()=>{
		let nobranch_id=this.state.noservice_branch_id_bulk;
		if (nobranch_id.length!=0) {
			this.setState({ loading: true })
			api.botbuilder.updateBranchforAll({ branch_id: this.props.branch_id, noservice_branch_id: this.state.noservice_branch_id_bulk, workspace_id: this.props.workspace_id }).then((data) => {
				this.setState({ loading: false });
			});
		}
	}

	phoneFormat = (p) => {
        p = p.replace(/[^\d]*/gi, "");
        if (p.length <= 3) {
            return p;
        } else if (p.length > 3 && p.length < 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;
            return pp;
        } else if (p.length >= 7) {
            p = "(" + p;
            let l30 = p.length;
            let p30 = p.substring(0, 4);
            p30 = p30 + ")";

            let p31 = p.substring(4, l30);
            let pp = p30 + p31;

            let l40 = pp.length;
            let p40 = pp.substring(0, 8);
            p40 = p40 + "-";

            let p41 = pp.substring(8, l40);
            let ppp = p40 + p41;
            let maxphonelength = 13;
            let finalPhone = ppp.substring(0, maxphonelength);
            return finalPhone;
        }
    };

	addLocation = () => {
		let text = { 'id': '', 'user_id': '', 'zipcode': '', 'willing_travel': '', 'branch_id': '', 'noservice_branch_id': '' }
		let push_data = this.state.zipcoderules;
		push_data.push(text);
		this.setState({
			zipcoderules: push_data
		})
	}

	phoneInputHandler = (e)=>{
		let inputValue;
        if (e.target.name == 'phone_for_bulk') {
            inputValue = this.phoneFormat(e.target.value);

        }

		this.setState({phoneNumber:inputValue})
	}

	countryCodeHandler=(e)=>{
		this.setState({country_code:e.target.value})
	}

	deleteLocations = () => {
		// deleteLocations
		this.setState({
			deletemodalid: '',
			modalstatus: true,
			modaltitle: "Confirm your Action",
			modalbody: "Are you sure you want to delete ?",
		})
	}
	modalClose = () => {
		this.setState({
			modalstatus: false,
			modaltitle: "",
			modalbody: "",
			modalindex: "",
			deleteprogress:false
		});
	};

	modalFooter = (type = '') => {
		console.log('type', type);
		if (type == 'bulkzipview') {
			return (
				<div className="modal-footer">
					<button
						type="button"
						onClick={() => this.modalClose()}
						className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
						data-dismiss="modal"
					>
						<span>Close</span>
					</button>
				</div>
			)
		}
		if (type == 'deletebulk') {
			return (
				<div className="modal-footer">
					<button
						type="button"
						onClick={() => this.modalClose()}
						className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
						data-dismiss="modal"
					>
						<span>No</span>
					</button>
					<button
						type="button"
						onClick={() => this.deletebulkzipcode()}
						className="btn linear-gradient yr-submitbtn btn-sm"
					>
						<span>Yes</span>
					</button>
				</div>
			);
		}
		return (
			<div className="modal-footer">
				<button
					type="button"
					onClick={() => this.modalClose()}
					className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small"
					data-dismiss="modal"
				>
					<span>No</span>
				</button>
				<button
					type="button"
					onClick={() => this.confirmDeleteZipcode(this.state.deletemodalid)}
					className="btn linear-gradient yr-submitbtn btn-sm"
				>
					<span>Yes</span>
				</button>
			</div>
		);
	}

	confirmDeleteZipcode = () => {
		let rules = this.state.zipcoderules
		let activeindex = rules.findIndex((list) => list == rules);

		rules.splice(activeindex, 1);
		this.setState({
			zipcoderules: rules
		}, () => {
			this.modalClose();
		})
	}

	saveZipCodeRules = () => {
		let errors = [];
		new Promise(resolve => {
			let zipcoderules = this.state.zipcoderules
			zipcoderules.forEach((element, index) => {

				if (element.zipcode == '') {

					if (typeof errors[index] != 'undefined') {
						errors[index].zipcode = 'This field is required';
					} else {
						let err = { zipcode: 'This field is required' };
						errors[index] = err;
					}


				}

				let recordlist = zipcoderules.filter(
					(list, typeindex) => {

						if (list.zipcode == element.zipcode && index != typeindex) {

							return true
						} else {
							return false;
						}
					}
				);

				if (recordlist != '' && element.zipcode != '') {
					// alert(recordlist);
					if (typeof errors[index] != 'undefined') {
						errors[index].zipcode = 'This zip code already exist';
					} else {
						let err = { zipcode: 'This zip code already exist' };
						errors[index] = err;
					}

				}




			});

			if (this.state.noservice_branch_id == '' || this.state.noservice_branch_id == 0) {

				let tindex = this.state.zipcoderules.length
				if (typeof errors[tindex] != 'undefined') {
					errors[tindex].noservice_branch_id = 'This field is required';
				} else {
					let err = { noservice_branch_id: 'This field is required' };
					errors[tindex] = err;
				}
			}

			resolve(errors);


		}).then((errors) => {

			if (errors.length == 0) {
				this.setState({ loading: true })


				api.botbuilder.saveZipCodeRules({ branch_id: this.props.branch_id, zipcoderules: this.state.zipcoderules, noservice_branch_id: this.state.noservice_branch_id, workspace_id: this.props.workspace_id, receive_chat_notification: this.state.receive_chat_notification }).then((data) => {
					try {
						if (data.status == true) {
							this.setState({ loading: false }, () => {
								this.props.popupClose()
								this.props.updateDeleteZipRule(true)
							});
						} else {
							this.setState({ loading: false }, () => {
								var rules_error = this.state.zipcoderules;
								data.data.map((obj, index) => {
									let activeindex = rules_error.findIndex((list) => list.zipcode == obj);

									if (typeof errors[activeindex] != 'undefined') {
										errors[activeindex].zipcode = 'Sorry! we are not available at this location';
									} else {
										let err = { zipcode: 'Sorry! we are not available at this location' };
										errors[activeindex] = err;
									}



								})


								this.setState({ errors: errors }, () => {

									let scrolltop =
										document.querySelector(".errosbuilderclass").offsetTop -
										document.body.scrollTop + 100;
									window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
								})




							});

						}
						// callback("success");
					} catch (err) {
						console.log(err);
					}
				});


				// this.props.saveAIRules(this.state.aiRules, this.state.copy_rules_branch_id, (data_parm) => {


				//     this.setState({

				//         loading: false
				//     });
				//     this.props.popupClose()
				//     let scrolltop=document.querySelector("#airules").offsetTop - document.body.scrollTop;
				//     window.scroll({top: scrolltop, left: 0, behavior: 'smooth' });
				// });
			} else {
				console.log(errors)
				this.setState({ errors: errors }, () => {

					let scrolltop =
						document.querySelector(".errosbuilderclass").offsetTop -
						document.body.scrollTop + 100;
					window.scroll({ top: scrolltop, left: 0, behavior: "smooth" });
				})
			}
			//console.log(errors)
		})
	}

	receiveNotification = (e) => {


		let val = e.target.checked ? "1" : "0";
		this.setState({
			receive_chat_notification: val,

		})


	}
	toggleTab = (tabActive, tabInactive) => {
		this.setState({
			[tabActive]: 'active',
			[tabInactive]: ''
		})
	}

	handleDelete = (i) => {
		this.setState({
			tags: this.state.tags.filter((tag, index) => index !== i),
		});
	}

	handleAddition = (tag) => {
		let { tags } = this.state;
		if (validateEmail(tag.text)) {
			tags = [...tags, tag];
			this.setState({ tags: tags });
		} else {
			toast.error('Invalid Email', {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
	}
	saveZipCodeRulesBulk = (event) => {
		console.log(event);
		event.preventDefault();
		let emails = this.state.tags.map((itm) => itm.text);
		const sendlead = this.state.send_lead_within;
		const zipbulkinput = document.getElementById('zipbulkinput');
		if(zipbulkinput.value){
			if(!validateEmail(zipbulkinput.value)){
				toast.error('Invalid Email', {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				return;
			}
			emails.push(zipbulkinput.value);
		}
		emails = [... new Set(emails)];
		zipbulkinput.value = "";
		if (!this.state.updateBulk && !this.state.csvfile) {
			toast.error('CSV file is required.', {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			return false;
		}
		// if (this.state.noservice_branch_id_bulk.length<1) {
		// 	toast.error('Branch is required.', {
		// 		position: "top-right",
		// 		autoClose: 3000,
		// 		hideProgressBar: false,
		// 		closeOnClick: true,
		// 		pauseOnHover: true,
		// 		draggable: true,
		// 		progress: undefined,
		// 		theme: "light",
		// 	});
		// 	return false;
		// }
		
		
        if (this.state.phoneNumber.trim() != '' && this.state.phoneNumber.match(/\d/g).length != 10) {
            toast.error('Please Enter Valid Number.', {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			return false;
        }

		if (!emails.length) {
			toast.error('Email is required.', {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			return false;
		}

		if (!sendlead) {
			toast.error('Send Lead within is required', {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			return false;
		}

		// if(this.state.noservice_branch_id_bulk=="0" || this.state.noservice_branch_id_bulk==""){
		// 	toast.error('Zipcode service area is required', {
		// 		position: "top-right",
		// 		autoClose: 3000,
		// 		hideProgressBar: false,
		// 		closeOnClick: true,
		// 		pauseOnHover: true,
		// 		draggable: true,
		// 		progress: undefined,
		// 		theme: "light",
		// 	});
		// 	return false;
		// }
		this.setState({
			bulkdisable: true,
			loadingBulk: true
		})
		
		const bodyFormData = new FormData();
		bodyFormData.append('branch_id', this.props.branch_id);
		bodyFormData.append('exclude_other_rule', this.state.exclude_other_rule);
		bodyFormData.append('send_lead_within', sendlead);
		bodyFormData.append('emails', emails.join(','));
		bodyFormData.append('csvfile', this.state.csvfile);
		if(this.state.phoneNumber==""){
			bodyFormData.append('isd_code', "");
		}else{
			bodyFormData.append('isd_code', this.state.country_code);
		}
		bodyFormData.append('phone', this.state.phoneNumber);
		bodyFormData.append('noservice_branch_id', this.state.noservice_branch_id_bulk);
		bodyFormData.append('bulkzipid', this.state.bulkzipid);
		bodyFormData.append('datetime', new Date().toLocaleString());
		api.botbuilder.saveZipCodeRulesBulk(bodyFormData).then((res) => {
			console.log(res);
			if (res.status) {
				toast.success(res.message, {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				const fileInput = document.getElementById('file-upload');
				fileInput.value = '';
				this.setState({
					tags: [],
					emailError: '',
					zipcodeBulkData: [],
					send_lead_within: '',
					csvfile: null, 
					phoneNumber:"",
					updateBulk: false,
					bulkzipid: '',
					loadingBulk: false,
					bulkdisable: false,
					country_code:"+1"
				})
				this.onLoad();
			}else{
				toast.error(res.message, {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
				this.setState({
					bulkdisable: false,
					loadingBulk: false,
					updateBulk: false,
					bulkzipid: '',
				})
			}
		}).catch((err) => {
			console.log(err);
			toast.error('Something went wrong. Try again.', {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			this.setState({
				bulkdisable: false,
				loadingBulk: false
			})
		})
	}
	//chnages made by satish

	onViewZipCode = (id) => {
		const getZipcode = this.state.zipcodeBulkData.filter((item) => item.id == id);
		let zips=getZipcode[0].zipcode.split(",");
		if(zips.length>1){
			zips=zips.map((val)=>{
				return " "+val;
			})
		}
		let zipstr=zips.join(",");
		if (getZipcode) {
			this.setState({
				modalstatus: true,
				modaltitle: "View zip code list",
				modalbody: `${zipstr}`,
				modalindex: "bulkzipview",
			})
		}

	}

	onViewEmail = (id) => {
		const getZipcode = this.state.zipcodeBulkData.filter((item) => item.id == id);
		if (getZipcode) {
			this.setState({
				modalstatus: true,
				modaltitle: "View email list",
				modalbody: `${getZipcode[0].email}`,
				modalindex: "bulkzipview",
			})
		}
	}
	onViewPhones = (id) => {
		const getZipcode = this.state.zipcodeBulkData.filter((item) => item.id == id);
		if (getZipcode) {
			this.setState({
				modalstatus: true,
				modaltitle: "View phone number",
				modalbody: getZipcode[0].phone!='' && typeof getZipcode[0].phone!='undefined' ? `${getZipcode[0].isd_code} ${getZipcode[0].phone}`: '',
				modalindex: "bulkzipview",
			})
		}
	}
	updatekeyword=(e)=>{
       let val=e.target.value;
		this.setState({filterkey:val},()=>{
			if(this.state.filterkey==""){
				this.onLoad();
			}
		});
		let val1=this.state.filterkey
		if(val1==""){
			this.setState({zipfilter:true})
		}
	    
	}
	filterZips=(e)=>{
		e.preventDefault();
		let key=this.state.filterkey;
		if(key.length>=3){
			this.onLoad();
			this.setState({zipfilter:true})
		}else{
			toast.error('Please enter at least 3 characters/digits.', {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
		}
		
	}
	handleKeyDown=(e)=>{
		let val=e.target.value;
		let key=e.key;
		if (key === 'Enter') {
			let filterkey=this.state.filterkey;
			e.preventDefault();
			if(filterkey.length>=3){
		  		this.onLoad();
				  this.setState({zipfilter:true})
			}else{
				toast.error('Please enter at least 3 characters/digits.', {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
				
		}
	  }

	handleCSV = (e) => {
		const fileInput = document.getElementById('file-upload');
		const filePath = fileInput.value;
		const allowedExtensions = /(\.csv|\.xlsx|\.xls)$/i;
		if(!allowedExtensions.exec(filePath)){
			fileInput.value = '';
			toast.error('Please upload csv file only.', {
				position: "top-right",
				autoClose: 3000,
				hideProgressBar: false,
				closeOnClick: true,
				pauseOnHover: true,
				draggable: true,
				progress: undefined,
				theme: "light",
			});
			return false;
		}else{
			this.setState({
				csvfile : e.target.files[0]
			})
		}
		
	}
	handleselectlead = (e) => {
		this.setState({
			send_lead_within : e.target.value
		})
	}

	excludeRule = (e) => {
		let val = e.target.checked ? "1" : "0";
		this.setState({
			exclude_other_rule: val,
		})
	}

	onDeleteBulk = (e) => {
		this.setState({
			bulkzipid: e,
			modalstatus: true,
			modaltitle: "Confirm your Action",
			modalbody: "Are you sure you want to delete ?",
			modalindex: "deletebulk",
			deleteprogress:true	
		})
		
	}

	onEditBulk = (e) => {
		const getEmails = this.state.zipcodeBulkData.filter((item) => item.id == e);
		const getPhone = this.state.zipcodeBulkData.filter(item=>item.id == e)[0].phone
		const isd_code = this.state.zipcodeBulkData.filter(item=>item.id == e)[0].isd_code
		console.log("getPhone",getPhone)
		console.log("getisd_code",isd_code)
		if (getEmails) {
			const email = getEmails[0].email.split(',').map((item) => ({
				'id' : item,
				'text' : item
			}));
			this.setState({
				bulkzipid : e,
				tags : email,
				send_lead_within: getEmails[0].send_lead_within,
				updateBulk: true,
				phoneNumber:getPhone,
				country_code:isd_code
			})
		}
	}
	
	deletebulkzipcode = () => {
		this.setState({loadingBulk : true})
		api.botbuilder.deletebulkzip({dataid : this.state.bulkzipid, branch_id: this.props.branch_id}).
		then((res) => {
			console.log(res);
			if (res.status) {
				toast.success(res.message, {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
			       

				});
			}else{
				toast.error(res.message, {
					position: "top-right",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
			}
			this.onLoad();
			this.setState({
				modalstatus : false,
				bulkzipid: '',
				modalindex: '',	
				loadingBulk : false,
				
				
			})
			setTimeout(() => {
				this.setState({deleteprogress:false});	
			}, 4000);

		}).catch((err) => {
			console.log(err);
			this.setState({
				modalstatus : false,
				bulkzipid: '',
				modalindex: '',	
				loadingBulk : false,
				
			})
			setTimeout(() => {
				this.setState({deleteprogress:false});	
			}, 4000);
		})
	}

	render() {
		const { errors, edit_id } = this.state;
		return (
			<div>
				<div className="yr-chat-round-box yr-closebtn">
					<button
						type="button"
						class="close linear-gradient"
						onClick={() => this.props.popupClose()}
					>
						&#10006;
					</button>
					<div className="row">
						<div className="col-lg-12 col-md-12 col-sm-12">
							<div className="kt-portlet kt-portlet--tabs yr-mt-20 shadow-none">
								<div className="kt-portlet__head">
									<div className="kt-portlet__head-toolbar">
										<ul
											className="nav nav-tabs nav-tabs-line nav-tabs-line-success nav-tabs-line-3x"
											role="tablist"
										>
											<li className="nav-item">
												<a
													className={"nav-link " + this.state.single}
													data-toggle="tab"
													role="tab"
													onClick={() => this.toggleTab("single", "bulk")}
													style={{ cursor: "pointer" }}
												>
													Zip Code Single
												</a>
											</li>
											<li className="nav-item">
												<a
													className={"nav-link " + this.state.bulk}
													data-toggle="tab"
													style={{ cursor: "pointer" }}
													role="tab"
													onClick={() => this.toggleTab("bulk", "single")}
												>
													Zip Code Bulk
												</a>
											</li>
										</ul>
									</div>
								</div>
								<div className="kt-portlet__body yr-pd0-lr">
									<div className="tab-content">
										<div
											className={"tab-pane " + this.state.single}
											id="kt_portlet_base_demo_1_1_tab_content"
											role="tabpanel"
										>
											<div className="box-title">Add zip code rules</div>

											{this.state.loading && <div className="ui loading form"></div>}

											{this.state.zipcoderules.length > 0 &&
												this.state.zipcoderules.map((obj, index) => {
													return (
														<div class="row" un_key={index}>
															{index > 0 &&
																<div className="col-md-12">
																	<button type="button" style={{ float: "right" }} title="Delete" className="btn btn-icon btn-sm cusrsorpointer" onClick={() => this.deleteLocations(index)}><i className="la la-trash red" /></button>
																</div>
															}
															<label className="col-md-4 pd10botbuilder">
																Zip Code
															</label>
															<div className={
																errors.length > 0 && typeof errors[index] != 'undefined' && errors[index].hasOwnProperty('zipcode')
																	? "col-md-8 errosbuilderclass"
																	: "col-md-8"
															}>
																<div className="form-group" style={{ marginBottom: 10 }}>
																	<input type="text" className={
																		errors.length > 0 && typeof errors[index] != 'undefined' && errors[index].hasOwnProperty('zipcode')
																			? "form-control is-invalid"
																			: "form-control"
																	} value={obj.zipcode} onChange={(e) => this.changeZipcode(e, index, 'zipcode')}></input>
																	{errors.length > 0 && typeof errors[index] != 'undefined' && errors[index].hasOwnProperty('zipcode') && (
																		<CustomErrorField
																			message={errors[index].zipcode}
																			className="error invalid-feedback"
																		/>
																	)}
																</div>
															</div>


															<div className="col-md-4 pd10botbuilder">
																Send Lead Within
															</div>
															<div className="col-md-8">
																<div className="form-group" style={{ marginBottom: 10 }}>
																	<select className="form-control" value={obj.willing_travel} onChange={(e) => this.changeZipcode(e, index, 'willing_travel')}>
																		<option value="">Select</option>
																		{[5, 10, 15, 20, 25, 30, 35, 40].map((x, y) => {
																			return (
																				<option value={x}>{x} Miles</option>
																			)
																		})}

																	</select>

																</div>
															</div>

														</div>

													);
												})}
											{this.state.edit_id == "" && (
												<a
													className="add-gradient form-group cusrsorpointer add-link"
													onClick={() => this.addLocation()}
												>
													+Click to add locations
												</a>
											)}



											<div className="form-group row my-4" className={
												errors.length > 0 && typeof errors[this.state.zipcoderules.length] != 'undefined' && errors[this.state.zipcoderules.length].hasOwnProperty('noservice_branch_id')
													? "form-group row my-4 errosbuilderclass"
													: "form-group row my-4"
											}>
												<label className="col-md-12 col-form-label">If the zip code is outside of our service area. Go to Branch</label>
												<div className="col-md-12">
													<select className={
														errors.length > 0 && typeof errors[this.state.zipcoderules.length] != 'undefined' && errors[this.state.zipcoderules.length].hasOwnProperty('noservice_branch_id')
															? "form-control is-invalid"
															: "form-control"
													} onChange={(e) => this.changeZipcode(e, '', 'noservice_branch_id')} value={this.state.noservice_branch_id}>
														<option value="">Select Branch</option>
														{this.props.branchList && this.props.branchList.map((value, index) => {
															return (
																<option key={index} value={value.branch_id}>{value.branch_name}</option>
															)
														})}
													</select>
													{errors.length > 0 && typeof errors[this.state.zipcoderules.length] != 'undefined' && errors[this.state.zipcoderules.length].hasOwnProperty('noservice_branch_id') && (
														<CustomErrorField
															message={errors[this.state.zipcoderules.length].noservice_branch_id}
															className="error invalid-feedback"
														/>
													)}
												</div>
												<div className="col-md-12">
													<button className="btn add-link" onClick={() => this.props.createNewBranch('zipcoderules')}>+Create New Branch</button>
												</div>
											</div>
											<div className="d-flex align-items-start justify-content-between">
												<p>Receive notification leads from no servive area</p>
												<span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check" style={{ textAlign: "left" }}>
													<label>
														<input type="checkbox" name="chatPopup" onChange={(e) => this.receiveNotification(e)} checked={(this.state.receive_chat_notification == '1') ? 'checked' : ''} />
														<span></span>
													</label>
												</span>
											</div>
											<div className="form-group row my-4">
												<label className="col-md-12 col-form-label">Your service area</label>
												<div className="col-md-12" style={{ MaxWidth: "300px", height: "400px" }}>
													{this.state.loading == false &&
														<MapContainer mapdata={this.state.zipcoderules} />
													}
												</div>
											</div>
											<div className="row">
												<div className="col-12 d-flex justify-content-end">
													<button
														type="reset"
														class="btn yr-cancelbtn linear-gradient-cancel mg-r"
														onClick={() => this.props.popupClose()}
													>
														<span> Close </span>
													</button>
													<button
														type="submit"
														className="btn linear-gradient yr-submitbtn"
														onClick={() => this.saveZipCodeRules()}
													>
														Save
													</button>
												</div>
											</div>

										</div>
										<div
											className={"tab-pane " + this.state.bulk}
											id="kt_portlet_base_demo_1_1_tab_content"
											role="tabpanel"
										>
											<div className="box-title">Add zip code rules bulk</div>
											{this.state.loadingBulk && <div className="ui loading form"></div>}
											<form onSubmit={this.saveZipCodeRulesBulk}>
											<div className="row mb-2">
												<label className="col-md-4 pd10botbuilder">
													Zip Code
												</label>
												<div className="col-md-8">
													<input 
														type="file" 
														className="form-control" 
														id="file-upload"
														name="uploadCSV"
														onChange={(e) => this.handleCSV(e)}
														accept="text/csv"
														/>
													<help><a href="/assets/zipcodesample.csv">Download our sample CSV file</a> to structure your import.</help>
												</div>
											</div>
											<div className="row mb-2">
												<div className="col-md-4 pd10botbuilder">
													Send Lead Within
												</div>
												<div className="col-md-8">

													<select name="sendleadwithin" className="form-control" onChange={(e) => this.handleselectlead(e)} value={this.state.send_lead_within}>
														<option value="">Select</option>
														<option value="exact_match">Exact match with zip code</option>
														{[5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 60, 70, 80, 90, 100, 110, 120].map((x, y) => {
															return (
																<option value={x}>{x} Miles</option>
															)
														})}

													</select>

												</div>
											</div>
											<div className="row mb-2">
												<div className="col-md-4 pd10botbuilder">
													Email
												</div>
												<div className="col-md-8">
													<ReactTags
														tags={this.state.tags}
														// delimiters={delimiters}
														handleDelete={(i) => this.handleDelete(i)}
														handleAddition={(i) => this.handleAddition(i)}
														//   handleAddition={handleAddition}
														//   handleDrag={handleDrag}
														//   handleTagClick={handleTagClick}
														inputFieldPosition="bottom"
														placeholder="Press Enter to add a new email."
														allowDragDrop={false}
														id="zipbulkinput"
													/>
													{this.state.emailError && <small>{this.state.emailError}</small>}

												</div>
											</div>
											<div className="row mb-2">
												<div className="col-md-4 pd10botbuilder">
												Phone Number
												</div>
												<div className="col-md-8 d-flex px-0">
													<div className="col-md-4 col-lg-5 pr-0">
														<select className="form-control" onChange={(e)=>this.countryCodeHandler(e)} value={this.state.country_code} style={{fontSize:'10px',padding:'0'}}>
															{this.props.defaultCountryCodeLists && this.props.defaultCountryCodeLists.map(c=>{
																return <option value={c.country_code}>{c.iso_code} ({c.country_code})</option>
															})}
															<optgroup label="Other">
															{this.props.countryCodeLists && this.props.countryCodeLists.map(c=>{
																return <option value={c.country_code}>{c.iso_code} ({c.country_code}) </option>
															})}
															</optgroup>
															
														</select>
													</div>
													<div className="col-md-8 col-lg-7">
														<input type="text" className="form-control" placeholder="Phone" name="phone_for_bulk" onChange={(e)=>this.phoneInputHandler(e)} value={this.state.phoneNumber}/>
													</div>
													{this.state.phoneError && <small>{this.state.phoneError}</small>}

												</div>
											</div>
											{this.state.zipcodeBulkData.length==0 &&<div className="form-group row my-4" className={
												errors.length > 0 && typeof errors[this.state.zipcoderulesBulk?.length] != 'undefined' && errors[this.state.zipcoderulesBulk?.length].hasOwnProperty('noservice_branch_id_bulk')
													? "form-group row my-4 errosbuilderclass"
													: "form-group row my-4"
											}>
												<label className="col-md-12 col-form-label">If the zip code is outside of our service area. Go to Branch</label>
												<div className="col-md-12">
													<select className={
														errors.length > 0 && typeof errors[this.state.zipcoderulesBulk?.length] != 'undefined' && errors[this.state.zipcoderulesBulk?.length].hasOwnProperty('noservice_branch_id_bulk')
															? "form-control is-invalid"
															: "form-control"
													} onChange={(e) => this.changeZipcode(e, '', 'noservice_branch_id_bulk')} value={this.state.noservice_branch_id_bulk}>
														<option value="">Select Branch</option>
														{this.props.branchList && this.props.branchList.map((value, index) => {
															return (
																<option key={index} value={value.branch_id}>{value.branch_name}</option>
															)
														})}
													</select>
													{errors.length > 0 && typeof errors[this.state.zipcoderulesBulk?.length] != 'undefined' && errors[this.state.zipcoderulesBulk?.length].hasOwnProperty('noservice_branch_id_bulk') && (
														<CustomErrorField
															message={errors[this.state.zipcoderulesBulk?.length].noservice_branch_id}
															className="error invalid-feedback"
														/>
													)}
												</div>
											</div>}
											<div className="d-flex align-items-start justify-content-between">
												<p>Exclude other sending rules</p>
												<span className="kt-switch kt-switch-sm kt-switch--success chat-cus-check" style={{ textAlign: "left" }}>
													<label>
														<input type="checkbox" name="exclude_other_rule" onChange={(e) => this.excludeRule(e)} checked={(this.state.exclude_other_rule == '1') ? 'checked' : ''} />
														<span></span>
													</label>
												</span>
											</div>
											<div className="row mt-4 mb-4">
												<div className="col-12 d-flex justify-content-end">
													<button
														disabled={this.state.bulkdisable}
														type="reset"
														class="btn yr-cancelbtn linear-gradient-cancel mg-r"
														onClick={() => this.props.popupClose()}
													>
														<span> Close </span>
													</button>
													<button
														disabled={this.state.bulkdisable}
														type="submit"
														className="btn linear-gradient yr-submitbtn"
													>
														Save
													</button>
												</div>
											</div>
											</form>			
											{this.state.zipcodeBulkData &&
												<div className="row">
													<div className="col-md-12" style={{marginBottom:'20px'}}>
														{/* zipcode filter */}
                                                    
                                                    {(this.state.zipcodeBulkData.length>1 || this.state.zipfilter) && <form id="searchchat" method="post"><div>
														<div class="kt-input-icon kt-input-icon--right header-search kt-input-icon--right">
															<input type="text" class="form-control" placeholder="Search Zip Code/Email" id="generalSearch" name="q" tp-type="username" onChange={(e)=>this.updatekeyword(e)} value={this.state.filterkey} autocomplete="off" onKeyDown={(e)=>this.handleKeyDown(e)} />
															<span class="kt-input-icon__icon kt-input-icon__icon--right" onClick={(e)=>this.filterZips(e)}><span>
																<i class="la la-search"></i></span></span>
																</div>
																</div>
																</form>}
													 </div>
													 <br/>
													<div className="col-md-12">
														{/* zipcode filter */}
                                                    
														{/*  */}
														<ul className="yr-listbox yr-listbox2" id="yr-listbox2">
															{this.state.zipcodeBulkData && this.state.zipcodeBulkData.map((item, i) => (
																<ZipcodeBulk
																	key={i}
																	data={item}
																	onViewZipcode={this.onViewZipCode}
																	onViewEmail={this.onViewEmail}
																	onViewPhones={this.onViewPhones}
																	phoneNumber = {item.phone}
																	onDeleteBulk={this.onDeleteBulk}
																	onEditBulk={this.onEditBulk}
																	deleteprogress={this.state.deleteprogress}
																/>
															)) }
														</ul>
													</div>
												</div>
											}

                                    {this.state.zipcodeBulkData.length>0 &&<div className="form-group row my-4" className={
												errors.length > 0 && typeof errors[this.state.zipcoderulesBulk?.length] != 'undefined' && errors[this.state.zipcoderulesBulk?.length].hasOwnProperty('noservice_branch_id_bulk')
													? "form-group row my-4 errosbuilderclass"
													: "form-group row my-4"
											}>
												<label className="col-md-12 col-form-label">If the zip code is outside of our service area. Go to Branch</label>
												<div className="col-md-12">
													<select data-id={this.state.noservice_branch_id_bulk} className={
														errors.length > 0 && typeof errors[this.state.zipcoderulesBulk?.length] != 'undefined' && errors[this.state.zipcoderulesBulk?.length].hasOwnProperty('noservice_branch_id_bulk')
															? "form-control is-invalid"
															: "form-control"
													} onChange={(e) => this.changeZipcode(e, '', 'noservice_branch_id_bulk',true)} value={this.state.noservice_branch_id_bulk}>
														<option value="">Select Branch</option>
														{this.props.branchList && this.props.branchList.map((value, index) => {
															return (
																<option key={index} value={value.branch_id}>{value.branch_name}</option>
															)
														})}
													</select>
													{errors.length > 0 && typeof errors[this.state.zipcoderulesBulk?.length] != 'undefined' && errors[this.state.zipcoderulesBulk?.length].hasOwnProperty('noservice_branch_id_bulk') && (
														<CustomErrorField
															message={errors[this.state.zipcoderulesBulk?.length].noservice_branch_id}
															className="error invalid-feedback"
														/>
													)}
												</div>
											</div>}

                                           
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<Modal
					show={this.state.modalstatus}
					footer={this.modalFooter(this.state.modalindex)}
					onclose={this.modalClose}
					title={this.state.modaltitle}
					body={this.state.modalbody}
				/>
				<ToastContainer
					position="top-right"
					autoClose={5000}
					hideProgressBar={false}
					newestOnTop
					closeOnClick
					rtl={false}
					pauseOnFocusLoss
					draggable
					pauseOnHover
					theme="light"
				/>

			</div>
		);
	}
}

export default ZipCodeRules;
