import React, { Component } from "react";
import api from "../../../api";
import config from "../../../config/";
import { Card, Message, Image } from "semantic-ui-react";
import { Form, Dropdown, TextArea, Input } from "formsy-semantic-ui-react";
import "./google-review.css";
class GoogleReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationModal: false,
      locationList: null,
      accessToken: "",
      loading: false,
      modalLoading: false,
      successModal: false,
      confirmModal: false,
      glinkId: '',
      successload: false,
      errorModal: false,
      errorMessage: "",
      fbData: null,
      pageData: null,
      selectedPage: null,
      pages: [],
      faceBookLogin: true,
      pageModal: false,
      subscribedPages: [],
      message: null,
      reputationStart: 0,
      reputationType: "",
      refreshToken: "",
      reviewToaster: false,
      reviewToasterMsg: "",
      reviewToasterColor: "",
      subscribedLink: [],
      link: "",
    };

    this.gapi = window.gapi;

    this.linkList();
  }
  componentDidMount() {
    this.auth2 = null;
    this.auth2 = window.gapi.auth2.init({
      client_id: config.googleClientId,
      // Scopes to request in addition to 'profile' and 'email'
      scope: "https://www.googleapis.com/auth/business.manage",
      immediate: true,
    });

    api.reviews
      .getSubscribedGooglePages()
      .then((resp) => {
        console.log(resp);
        if (resp) {
          this.setState({ subscribedPages: resp.pageList });
        }
      })
      .catch((err) => {
        this.setState({ loading: false });
        console.log(err);
      });
  }
  googleSignIn = () => {
    this.auth2.grantOfflineAccess().then(this.signInCallback);
  };
  signInCallback = (authResult) => {
    if (authResult["code"]) {
      this.setState({ loading: true });
      let authCode = authResult["code"];
      this.retrieveAccessTokenAndRefreshToken(authCode);
    }
  };

  onValidSubmitReviewsLink = (e) => {
    //alert("hi")
    this.setState({
      loading: true,
    });
    this.setState({
      submitButton: 1,
    });

    const sendData = { link: e.link };
    api.reviews.addReviewsLinkGoogle(sendData).then((data) => {
      try {
        this.setState({
          link: "",
          submitButton: 0,
          loading: false,
        });
        this.linkList();
        this.showToasterReviews(
          "green",
          "Review link has been added successfully."
        );
        document.getElementById("reviewForm2").reset();
      } catch (err) {
        console.log(err);
      }
    });
  };
  // Add by rohit
  DeleteConfirmLink(id) {
    this.setState({
      loading: true,
    });
    const sendlinkData = { id: id };
    api.reviews.checkGooglelink(sendlinkData).then((data) => {
      try {
        this.setState({
          loading: false,
        });
        if (data.message == 0) {
          this.DeleteLink(id);
        } else {
          this.setState({
            confirmModal: true,
            glinkId: id,
            //modalLoading: false,
            loading: false,
          });



        }
        //this.linkList();
      } catch (err) {
        console.log(err);
      }
    });

  }
  DeleteLink(id) {
    this.setState({
      loading: true,
    });
    this.setState({
      submitButton: 1,
    });
    const sendData = { id: id };
    api.reviews.deleteReviewsLinkGoogle(sendData).then((data) => {
      try {

        //alert(data.message);
        this.setState({
          submitButton: 0,
          loading: false,
        });
        this.showToasterReviews(
          "green",
          "Review link has been deleted successfully."
        );
        this.linkList();
      } catch (err) {
        console.log(err);
      }
    });
  }

  showToasterReviews = (color, msg) => {
    this.setState({
      reviewToaster: true,
      reviewToasterMsg: msg,
      reviewToasterColor: color,
    });
    setTimeout(
      function () {
        this.setState({ reviewToaster: false });
      }.bind(this),
      3000
    );
  };

  retrieveAccessTokenAndRefreshToken = (code) => {
    let formData = new FormData();
    formData.append("code", code);
    formData.append("client_id", config.googleClientId);
    formData.append("client_secret", config.googleClientSecret);
    formData.append("redirect_uri", config.googleRedirectUrl);
    formData.append("grant_type", "authorization_code");

    var http = new XMLHttpRequest();
    http.open("POST", "https://www.googleapis.com/oauth2/v4/token", true);
    let obj = this;
    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        let response = JSON.parse(http.responseText);
        console.log(response);
        obj.retrieveAccessTokenFromRefreshToken(
          response.access_token,
          response.refresh_token,
          obj
        );
      }

    };
    http.send(formData);
  };

  retrieveAccessTokenFromRefreshToken = (refreshToken, refresh_token2, obj) => {
    let formData = new FormData();
    formData.append("refresh_token", refreshToken);
    formData.append("client_id", config.googleClientId);
    formData.append("client_secret", config.googleClientSecret);
    formData.append("redirect_uri", config.googleRedirectUrl);
    formData.append("grant_type", "refresh_token");

    var http = new XMLHttpRequest();
    http.open("POST", "https://www.googleapis.com/oauth2/v4/token", true);

    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        let resp = JSON.parse(http.responseText);
        console.log(resp.refresh_token);
        obj.setState({
          refreshToken: refresh_token2,
        });
        obj.retrieveGoogleMyBusinessAccounts(
          resp.access_token,
          resp.refresh_token,
          obj
        );
      }
    };
    http.send(formData);
  };

  retrieveGoogleMyBusinessAccounts = (accessToken, refresh_token, obj) => {
    var http = new XMLHttpRequest();
    // http.open("GET", "https://mybusiness.googleapis.com/v4/accounts", true);
    http.open("GET", "https://mybusinessaccountmanagement.googleapis.com/v1/accounts", true);
    http.setRequestHeader("Authorization", "Bearer " + accessToken);

    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        let resp = JSON.parse(http.responseText);
        console.log('*** account 1_1', resp);
        if (resp && resp.accounts[0]) {
          resp = resp.accounts[0];

          let respData = resp.name.split("/");

          if (respData[1] && respData[1] !== "") {
            obj.getLocationId(accessToken, respData[1], refresh_token, obj);
          }
        }
      } else {

        let resp = JSON.parse(http.responseText);

        let code_err = resp.error.code;
        if (code_err == 403) {
          obj.setState({
            errorModal: true,
            loading: false,
            errorMessage: "We could not find any location on your business page.",
          });
        }
        console.log('*** account 1_2', resp);
        //console.log('Rohit find code data --> erroe ' + code_err);
      }
    };
    http.send();
  };
  toggleErrorModal = () => {
    this.setState({ errorModal: false });
  };
  errorModal = () => {
    return (
      <div
        className={
          this.state.errorModal
            ? "modal yr-upgrade-popup white-bg new-model-popup fade show "
            : "modal fade"
        }
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: this.state.errorModal ? "block" : "none" }}
        id="kt_modal_6"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggleErrorModal}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Google Locations"}</h5>
            </div>
            <div className="modal-body">
              <div className="kt-portlet__body">
                <div className="kt-section kt-section--first">
                  <div className="kt-section__body">
                    <Message positive>
                      <Message.Header>{this.state.errorMessage}</Message.Header>
                    </Message>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  getLocationId = (accessToken, accountID, refresh_token, obj) => {
    var http = new XMLHttpRequest();

    // let url = `https://mybusiness.googleapis.com/v4/accounts/${accountID}/locations?access_token=${accessToken}`;
    let url = `https://mybusinessbusinessinformation.googleapis.com/v1/accounts/${accountID}/locations?access_token=${accessToken}&readMask=name,title,storeCode,phoneNumbers,categories,storefrontAddress,websiteUri,regularHours,specialHours,serviceArea,metadata,latlng,profile`;

    http.open("GET", url, true);

    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        let resp = JSON.parse(http.responseText);
        console.log('*** location 1_1', resp);
        if (resp && resp.locations && resp.locations.length > 0) {
          obj.setState({ locationList: resp.locations, accountID: accountID,  accessToken }, () =>
            obj.setState({ locationModal: true, loading: false })
          );
        } else {
          obj.setState({
            errorModal: true,
            loading: false,
            errorMessage: "We could not find any location on your business page.",
          });
        }
      }
    };
    http.send();
  };
  toggleModal = () => {
    this.setState({ locationModal: false, loading: false });
  };
  toggleSuccessModal = () => {
    this.setState({ successModal: false });
  };
  toggleConfirmModal = () => {
    this.setState({ confirmModal: false });
  };

  DeleteGLink = () => {
    this.DeleteLink(this.state.glinkId);
    this.setState({ confirmModal: false });

  };

  displayLocationList = () => {
    return (
      <Card.Group>
        {this.state.locationList &&
          this.state.locationList.map((location) => {
            return (
              <Card>
                <Card.Content>
                  <Card.Header>{location.title}</Card.Header>
                  <Card.Meta>
                    Place Name: {location.serviceArea?.places?.placeInfos[0]?.placeName}
                  </Card.Meta>
                  <Card.Meta>
                    Place Id: {location.serviceArea?.places?.placeInfos[0]?.placeId}
                  </Card.Meta>
                  <Card.Description>
                    <button
                      type="button"
                      title="Connect"
                      className="btn linear-gradient yr-submitbtn"
                      onClick={(locationInfo, obj) =>
                        this.connectLocation(
                          location.name,
                          location.title,
                          location.metadata,
                          this
                        )
                      }
                    >
                      Connect Location
                    </button>
                  </Card.Description>
                </Card.Content>
              </Card>
            );
          })}
      </Card.Group>
    );
  };
  connectLocation = (locationInfo, locationName, metadata, obj) => {
    let self = this;
    this.setState({ modalLoading: true });

    // let accountID = locationInfo.split("/")[1];
    let accountID = this.state.accountID;
    let locationID = locationInfo.split("/")[1];
    
    // accountID.replace(/%20/g, "");
    locationID.replace(/%20/g, "");

    var http = new XMLHttpRequest();
    let url =
      "https://mybusiness.googleapis.com/v4/accounts/" +
      accountID +
      "/locations/" +
      locationID +
      "/reviews?access_token=" +
      this.state.accessToken;
    http.open("GET", url, true);

    http.onreadystatechange = function () {
      //Call a function when the state changes.
      if (http.readyState == 4 && http.status == 200) {
        let resp = JSON.parse(http.responseText);
        obj.getReviews(accountID, locationID, resp.reviews ? resp.reviews : []);

        let locinsertData = {
          accountID: accountID,
          locationID: locationID,
          accessToken: obj.state.accessToken,
          refresh_token: obj.state.refreshToken,
          review_url: metadata.newReviewUrl,
          locationName: locationName,
        };
        console.log(locinsertData);
        api.googleReview
          .addLocation(locinsertData)
          .then((resp) => {
            api.reviews
              .getSubscribedGooglePages()
              .then((resp) => {
                self.setState(
                  { subscribedPages: resp.pageList, successload: true },
                  () => { }
                );
              })
              .catch((err) => {
                console.log(err);
              });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    };
    http.send();
  };

  loadPages = () => {
    return this.state.subscribedPages.map((page, index) => {
      return (
        <div className="page-item" id="" key={index}>
          <div
            className="page-item-container"
            style={{ fontSize: "15px", color: "#a0a0a0" }}
          >
            {page.locationName}
          </div>
          <div className="action-block yr-block">
            <button
              onClick={(accessToken, pageId, autoId) =>
                this.confirmUnsubscribePage(
                  page.access_token,
                  page.location_id,
                  page.id
                )
              }
              className="btn linear-gradient zy-save-btn"
              style={{ margin: "10px 10px 10px 0" }}
            >
              Disconnect
            </button>
          </div>
        </div>
      );
    });
  };

  linkList = () => {
    api.reviews.LinkListGoogle().then((data) => {
      this.setState({
        subscribedLink: data.subscribedLink,
      });
    });
  };
  loadLink = () => {
    return this.state.subscribedLink.map((page, index) => {
      return (
        <div className="page-item" id="" key={index}>
          <div className="row">
            <div className="col-lg-9">
              <input
                name="link"
                disabled={true}
                value={page.link}
                className="form-control"
              />
            </div>
            <div className="col-lg-3 d-flex justify-content-end">
              <button
                onClick={() => this.DeleteConfirmLink(page.id)}
                className="btn linear-gradient zy-save-btn"
              >Remove</button>
            </div>
          </div>
        </div>
      );
    });
  };

  removePage = (pageId) => {
    const subscribedPages = [];
    this.state.subscribedPages.map((page) => {
      if (page.location_id !== pageId) {
        subscribedPages.push(page);
      }
    });

    this.setState({ subscribedPages });
  };

  unSubscribePage = (accessToken, pageId, autoId) => {
    this.setState({
      pageData: null,
    });
    api.reviews
      .deleteGPage({ delete_id: autoId })
      .then((resp) => {
        this.removePage(pageId);
        if (resp && resp.message !== "") {
          this.setState({ message: resp.message }, () => {
            setTimeout(() => {
              this.setState({ message: null });
            }, 5000);
          });
        } else {
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        console.log(err);
        this.setState({ loading: false });
      });
  };

  confirmUnsubscribePage = (accessToken, pageId, autoId) => {
    this.unSubscribePage(accessToken, pageId, autoId);
  };

  getReviews = (accountID, locationID, reviews) => {
    if (reviews.length > 0) {
      for (let i = 0; i < reviews.length; i++) {
        this.getSpacificReview(accountID, locationID, reviews[i]);
      }
      this.toggleModal();
      this.setState({
        successModal: true,
        modalLoading: false,
        loading: false,
      });
    } else {
      this.setState({
        modalLoading: false,
        errorMessage: "Reviews not found in this location",
        errorModal: true,
      });
    }
  };

  getSpacificReview = (accountID, locationID, reviewInfo) => {
    if (reviewInfo) {
      var http = new XMLHttpRequest();
      let reviewId = reviewInfo.reviewId;
      if (reviewId && reviewInfo.comment) {
        let url =
          "https://mybusiness.googleapis.com/v4/accounts/" +
          accountID +
          "/locations/" +
          locationID +
          "/reviews/" +
          reviewId +
          "?access_token=" +
          this.state.accessToken;
        http.open("GET", url, true);

        http.onreadystatechange = function () {
          //Call a function when the state changes.

          if (http.readyState == 4 && http.status == 200) {
            let resp = JSON.parse(http.responseText);
            if (resp && resp.comment) {
              api.googleReview.saveReviews(resp);
            }
          }
        };
        http.send();
      }
    }
  };
  locationListModal = () => {
    return (
      <div
        className={
          this.state.locationModal
            ? "modal yr-upgrade-popup white-bg new-model-popup fade show "
            : "modal fade"
        }
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: this.state.locationModal ? "block" : "none" }}
        id="kt_modal_6"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            {this.state.modalLoading && <div className="ui loading form"></div>}
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggleModal}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Google Locations"}</h5>
            </div>
            <div className="modal-body">
              <div className="kt-portlet__body">
                <div className="kt-section kt-section--first">
                  <div className="kt-section__body">
                    {this.displayLocationList()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  displaySuccessModal = () => {
    return (
      <div
        className={
          this.state.successModal
            ? "modal yr-upgrade-popup white-bg new-model-popup fade show "
            : "modal fade"
        }
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: this.state.successModal ? "block" : "none" }}
        id="kt_modal_6"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggleSuccessModal}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Google Reviews"}</h5>
            </div>
            <div className="modal-body">
              <div className="kt-portlet__body">
                <div className="kt-section kt-section--first">
                  <div className="kt-section__body">
                    <h3>
                      Google My Business reviews have been successfully
                      connected.
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  displayConfirmModal = () => {
    return (
      <div
        className={
          this.state.confirmModal
            ? "modal yr-upgrade-popup white-bg new-model-popup fade show "
            : "modal fade"
        }
        data-backdrop="static"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="staticBackdrop"
        aria-hidden="true"
        style={{ display: this.state.confirmModal ? "block" : "none" }}
        id="kt_modal_6"
      >
        <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: 600 }}>
          <div className="modal-content">
            <button
              type="button"
              className="close linear-gradient"
              data-dismiss="modal"
              aria-label="Close"
              onClick={this.toggleConfirmModal}
            >
              X
            </button>
            <div className="modal-header">
              <h5 className="modal-title">{"Confirm your action"}</h5>
            </div>
            <div className="modal-body" style={{ padding: '1.25rem' }}>
              <div className="px-4">
                This link is already used in some branch if you delete this lnk then this link removed from that associated  branch.
              </div>

            </div>
            <div className="modal-footer text-right">

              <button type="button" className="yr-cancelbtn linear-gradient-cancel btn-sm yr-btn-small" style={{ minWidth: 80, fontSize: 16 }} data-dismiss="modal" onClick={this.toggleConfirmModal}>
                <span>No</span></button>
              <button type="button" className="btn linear-gradient yr-submitbtn btn-sm ms-1" style={{ minWidth: 80, fontSize: 16 }} onClick={this.DeleteGLink}>Yes</button>

            </div>
          </div>
        </div>
      </div>
    );
  };
  render() {
    const errorLabel = <div className="red" />;
    return (
      <div>
        {this.state.locationModal && this.locationListModal()}
        {this.state.loading && <div className="ui loading form"></div>}
        {this.state.successModal && this.displaySuccessModal()}
        {this.state.confirmModal && this.displayConfirmModal()}
        {this.state.errorModal && this.errorModal()}

        <div>
          <h4 className="mb-4 conect-header">
            Click below to connect your Google My Business page with{" "}
            <span className="con-highlight">ZyraTalk </span>
          </h4>
          <div style={{ width: "30%", margin: "auto" }}>
            <a
              className="ui centered image"
              style={{ cursor: "pointer" }}
              onClick={this.googleSignIn}
            >
              <img src="/assets/images/google-sign-btn.png" />
            </a>
          </div>
          {/* <Image
                            centered
                            src="/assets/images/google-sign-btn.png"
                            as="a"
                            onClick={this.googleSignIn}
                            style={{ cursor: "pointer",width:"47%" }}
                          /> */}

          {/* <button onClick={this.googleSignIn}  className="google-sign-in-btn google-sign-in-btn--google">
                  Login with Google
                </button> */}
        </div>

        {this.state.subscribedPages.length > 0 && (
          <div style={{ marginTop: 20 }}>
            <div className="yr-fbBox" id="yr-fbBox-main">
              <div className="yr-sml-h" style={{ fontSize: "15px" }}>
                Already subscribed google page{" "}
              </div>
              {this.loadPages()}
            </div>
            <br />
          </div>
        )}

        <h4 style={{ textAlign: "center" }}>OR</h4>
        <Message
          color={
            this.state.reviewToasterColor
              ? this.state.reviewToasterColor
              : "green"
          }
          style={{
            display: this.state.reviewToaster ? "block" : "none",
            marginTop: 30,
          }}
        >
          {this.state.reviewToasterMsg}
        </Message>
        <h4 className="mb-4 conect-header">
          If you haven't signed in, please add your Google review link below
        </h4>
        <div className="requestReviewForm">
          <Form
            noValidate
            autoComplete="off"
            id="reviewForm2"
            onValidSubmit={this.onValidSubmitReviewsLink}
          >
            <div className="row">
              <div className="col-lg-9">
                <Form.Input
                  name="link"
                  value={this.state.link}
                  validations="isRequired"
                  validationErrors={{
                    isRequired: "This field is required.",
                  }}
                  errorLabel={errorLabel}
                />
              </div>

              <div className="col-lg-3 d-flex justify-content-end">
                <button
                  type="submit"
                  disabled={this.state.submitButton === 1 ? true : false}
                  className="btn linear-gradient zy-save-btn"
                >
                  Add Link
                </button>
              </div>
            </div>
          </Form>
        </div>

        {this.state.subscribedLink.length > 0 && (
          <div>
            <div
              className="yr-fbBox"
              id="yr-fbBox-main"
              style={{ marginTop: 20 }}
            >
              <div className="yr-sml-h" style={{ fontSize: "15px" }}>
                Here are the links you have already added:{" "}
              </div>
              {this.state.subscribedLink.length > 0 && this.loadLink()}
            </div>
            <br />
          </div>
        )}
      </div>
    );
  }
}

export default GoogleReview;
