import React from "react";
import { Form, Message, Input } from "semantic-ui-react";
import Validator from "validator";
import api from "../../api";
import Switch from "react-switch";
import utilFunc from "../../util_funs";

class Webhook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
      loading: false,
      webhook: "",
      webhookStatus: false,
      successMessage: null,
      errors: null,
    };
  }
  handleChange = (e) => {
    if (this.state.errors && this.state.errors.webhook) {
      this.setState({ errors: null, [e.target.name]: e.target.value });
    } else {
      this.setState({ [e.target.name]: e.target.value });
    }
  };

  handleSubmit = () => {
    const errors = this.validateForm();
    this.setState({ errors });

    if (Object.keys(errors).length === 0) {
      let { webhookStatus, webhook } = this.state;
      if (!webhookStatus) webhook = "";
      api.webhook.createWebhook({ webhookStatus, webhook }).then((resp) => {
        if (resp && resp.message) {
          this.setState({ successMessage: resp.message }, () => {
            // utilFunc.scrollTop(800);
            setTimeout(() => {
              this.setState({ successMessage: null });
            }, 5000);
          });
        }
      });
    }
  };

  validateForm() {
    const errors = {};
    if (this.state.webhookStatus) {
      if (!this.state.webhook) errors.webhook = "This field is required.";
      else if (!Validator.isURL(this.state.webhook)) {
        errors.webhook = "Invalid webhook url.";
      }
    }
    return errors;
  }
  handleSwitch = (checked) => this.setState({ webhookStatus: checked });
  componentDidMount() {
    api.webhook.loadWebhook({}).then((resp) => {
      try{
      if (resp.webhook) {
        const webhookInfo = resp.webhook;
        this.setState({
          webhook: webhookInfo.webhook_url,
          webhookStatus: webhookInfo.status === "active" ? true : false,
        });
      }} catch(e){}
    });
  }
  openPage() {
    window.open(
      "https://docs.google.com/document/d/1S0lzB7wsoTVpjsEhlnoAEIlPtY_XWnNer1zBmhcnax0/edit?usp=sharing"
    );
  }
  render() {
    const errorLabel = <div className="red" pointing={"true"} />;

    return (
      <React.Fragment>
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            {this.state.successMessage && (
              <Message color={"green"}>{this.state.successMessage}</Message>
            )}
            <div className="kt-portlet__body kt-portlet__body--fit ">
              <div class="form-group row">
                <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub  ">
                  <button
                    type="submit"
                    style={{ float: "center" }}
                    className="btn linear-gradient yr-submitbtn btn-sm"
                    onClick={this.openPage}
                  >
                    Webhook Tutorial Docs
                  </button>
                </div>
              </div>
              <div class="form-group row">
                <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub d-flex flex-row  ">
                  <label class="form-control-label">Enable Webhook</label>
                  <div style={{ margin: "3px 175px 0 10px"}}>
                    <Switch
                      onChange={this.handleSwitch}
                      checked={this.state.webhookStatus}
                      onColor="#86d3ff"
                      onHandleColor="#2693e6"
                      handleDiameter={30}
                      uncheckedIcon={false}
                      checkedIcon={false}
                      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                      height={20}
                      width={48}
                      className="react-switch"
                      id="material-switch"
                    />
                  </div>
                </div>
              </div>
              <Form autoComplete="off" onSubmit={this.handleSubmit}>
                {this.state.webhookStatus && (
                  <div class="form-group row">
                    <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub ">
                      <label class="form-control-label">
                        Webhook Url <span>*</span>
                      </label>
                      <Form.Input
                        type="text"
                        autoComplete="off"
                        name="webhook"
                        placeholder="Webhook Url"
                        value={this.state.webhook}
                        onChange={this.handleChange}
                        error={
                          this.state.errors && this.state.errors.webhook
                            ? true
                            : false
                        }
                      />
                      {this.state.errors && (
                        <div className="red" pointing={"true"}>
                          {this.state.errors.webhook}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="form-group row">
                  <div class="col-lg-12 col-md-12 col-sm-12 form-group-sub ">
                    <button
                      type="submit"
                      style={{ float: "right" }}
                      className="btn linear-gradient yr-submitbtn btn-sm"
                    >
                      Save
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Webhook;
