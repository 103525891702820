export const enviromMent = "uat";
const development = {
  apiBaseURL: "http://localhost/zyratalk/api/premium",
   apiBaseURL2: "http://localhost/zyratalk-re-engage/public/api/",
  installationUrl:
    "http://localhost/zyratalk/contractorschatbot/js/botdistribution.min.js",
  authSuccessUrl: "/",
  authFailerUrl: "/",
  authLogoutUrl: "http://localhost/zyratalk/premium/logout",
  baseUrl: "http://localhost/zyratalk",
  imageUrl: "",
  access_token_name: "access_token",
  refresh_token_name: "refresh_token",
  cookieExpirationDays: 1,
  onePlanetChatKey: "L1XKcOYyvASHeBDnjrs8",
  onePlanetGtmUrl:
    "http://localhost:zyratalk/oneplanetintegration/js/botdistributionForGTM.js",
  onePlanetBotUrl:
    "https://www.zyrachat.com/oneplanetintegration/js/botdistribution.min.js",

  bitlyUrlForGtm: "https://bit.ly/2WsXMeZ",
  dataTableSettings: {
    pageLimit: 10,
    limitOptions: [
      { key: "0", value: "10", text: "10" },
      { key: "1", value: "25", text: "25" },
      { key: "2", value: "50", text: "50" },
      { key: "3", value: "100", text: "100" },
    ],
  },
  installationPage: "https://www.youtube.com/embed/DK9Q1g04U8Q",
  facebookAppId: "829620930885467",
  facebookClientSecret: "8e3c40bc04fc9b4665195a0e5be3fb4b",
  nodeJsServerUrl: "http://localhost:3335",
  googleClientId:
    "891800343887-6me1gj5msbkf3g0fd0vqbbqpii8qgrf2.apps.googleusercontent.com",
  googleClientSecret: "gNWxBLiJQmMPjIQmH9xg24Hq",
  googleRedirectUrl: "http://localhost:3000",
  serverBaseURL: "http://localhost/zyratalk-re-engage/support/",
  unlayerProjectID: "26578",
  twilioAccountSid : 'ACbdfdeb374ea472736ae84dc674c68728',
  twilioAuthToken : '0321d8cac5c0a354ea60408b3a65cfdb'
};

//"homepage": "https://devreact.zyratalk.com",

const production = {
  apiBaseURL: "https://dev.zyratalk.com/api/premium",
  apiBaseURL2: "https://dev.zyratalk.com/newengage/public/api/",
  installationUrl:
    "https://dev.zyratalk.com/contractorschatbot/js/botdistribution.min.js",
  onePlanetChatKey: "L1XKcOYyvASHeBDnjrs8",
  bitlyUrlForGtm: "https://bit.ly/2WsXMeZ",
  onePlanetBotUrl:
    "https://www.zyrachat.com/oneplanetintegration/js/botdistribution.min.js   ",

  onePlanetGtmUrl:
    "https://dev.zyratalk.com/oneplanetintegration/js/botdistributionForGTM.js",
  authSuccessUrl: "/zyratalk_react",
  authFailerUrl: "/",
  authLogoutUrl: "https://dev.zyratalk.com/premium/logout",
  baseUrl: "https://devreact.zyratalk.com",
  access_token_name: "access_token",
  refresh_token_name: "refresh_token",
  imageUrl: "",
  cookieExpirationDays: 1,
  dataTableSettings: {
    pageLimit: 10,
    limitOptions: [
      { key: "0", value: "10", text: "10" },
      { key: "1", value: "25", text: "25" },
      { key: "2", value: "50", text: "50" },
      { key: "3", value: "100", text: "100" },
    ],
  },
  installationPage: "https://www.youtube.com/embed/DK9Q1g04U8Q",
 //facebookAppId: "829620930885467",
 // facebookClientSecret: "8e3c40bc04fc9b4665195a0e5be3fb4b",
 facebookAppId: "205808843625785",
  facebookClientSecret: "47cccd407d9ede4e442dd5817f2db43c",
  nodeJsServerUrl: "https://devnode.zyratalk.com",
  googleClientId:
    "891800343887-6me1gj5msbkf3g0fd0vqbbqpii8qgrf2.apps.googleusercontent.com",
  googleClientSecret: "gNWxBLiJQmMPjIQmH9xg24Hq",
  googleRedirectUrl: "https://devreact.zyratalk.com",
  serverBaseURL: "https://dev.zyratalk.com",
  unlayerProjectID: "26578",
  twilioAccountSid : 'ACb1589cdb0710cb771f48b3414b62f9d3',
  twilioAuthToken : 'b564621757e5456f617f54f6ecfbda31'
};

const uat = {
  apiBaseURL: "https://uat.zyratalk.com/api/premium",
    apiBaseURL2: "https://uat.zyratalk.com/newengage/public/api/",
  installationUrl:
    "https://uat.zyratalk.com/contractorschatbot/js/botdistribution.min.js",
  onePlanetChatKey: "L1XKcOYyvASHeBDnjrs8",
  bitlyUrlForGtm: "https://bit.ly/2WsXMeZ",
  onePlanetBotUrl:
    "https://www.zyrachat.com/oneplanetintegration/js/botdistribution.min.js   ",

  onePlanetGtmUrl:
    "https://uat.zyratalk.com/oneplanetintegration/js/botdistributionForGTM.js",
  authSuccessUrl: "/zyratalk_react",
  authFailerUrl: "/",
  authLogoutUrl: "https://uat.zyratalk.com/premium/logout",
  baseUrl: "https://uatreact.zyratalk.com",
  access_token_name: "access_token",
  refresh_token_name: "refresh_token",
  imageUrl: "",
  cookieExpirationDays: 1,
  dataTableSettings: {
    pageLimit: 10,
    limitOptions: [
      { key: "0", value: "10", text: "10" },
      { key: "1", value: "25", text: "25" },
      { key: "2", value: "50", text: "50" },
      { key: "3", value: "100", text: "100" },
    ],
  },
  installationPage: "https://www.youtube.com/embed/DK9Q1g04U8Q",
  //facebookAppId: "829620930885467",
 // facebookClientSecret: "8e3c40bc04fc9b4665195a0e5be3fb4b",
 facebookAppId: "205808843625785",
  facebookClientSecret: "47cccd407d9ede4e442dd5817f2db43c",
  nodeJsServerUrl: "https://uatnode.zyratalk.com",
  googleClientId:
    "891800343887-betdj1ktjgopdmpd038ps473u1s47mqd.apps.googleusercontent.com",
  googleClientSecret: "A7N1TXaOo4TGtzUNI4oJoxtk",
  googleRedirectUrl: "https://uatreact.zyratalk.com",
  serverBaseURL: "https://uat.zyratalk.com",
  unlayerProjectID: "26578",
  twilioAccountSid : 'ACb1589cdb0710cb771f48b3414b62f9d3',
  twilioAuthToken : 'b564621757e5456f617f54f6ecfbda31'
};

const live = {
  apiBaseURL: "https://www.zyratalk.com/api/premium",
  installationUrl: "https://nowl.ink/1791z3115a",
  onePlanetChatKey: "L1XKcOYyvASHeBDnjrs8",
  bitlyUrlForGtm: "https://nowl.ink/1791z3215a",
  onePlanetBotUrl:
    "https://www.zyrachat.com/oneplanetintegration/js/botdistribution.min.js   ",

  onePlanetGtmUrl:
    "https://www.zyratalk.com/oneplanetintegration/js/botdistributionForGTM.js",
  authSuccessUrl: "/zyratalk_react",
  authFailerUrl: "/",
  authLogoutUrl: "https://zyratalk.com/premium/logout",
  baseUrl: "https://app.zyratalk.com",
  access_token_name: "access_token",
  refresh_token_name: "refresh_token",
  imageUrl: "",
  cookieExpirationDays: 1,
  dataTableSettings: {
    pageLimit: 10,
    limitOptions: [
      { key: "0", value: "10", text: "10" },
      { key: "1", value: "25", text: "25" },
      { key: "2", value: "50", text: "50" },
      { key: "3", value: "100", text: "100" },
    ],
  },
  installationPage: "https://www.youtube.com/embed/DK9Q1g04U8Q",
  facebookAppId: "205808843625785",
  facebookClientSecret: "47cccd407d9ede4e442dd5817f2db43c",
  nodeJsServerUrl: "https://node.zyratalk.com",
  googleClientId:
    "891800343887-2ntuktuf700qigremdh3rk4g6cfooqdm.apps.googleusercontent.com",
  googleClientSecret: "gkOnxFFwgyIqOdYv7MG-tP6T",
  googleRedirectUrl: "https://app.zyratalk.com",
  serverBaseURL: "https://www.zyratalk.com",
  unlayerProjectID: "26578",
};

const config =
  enviromMent === "production"
    ? production
    : enviromMent === "uat"
      ? uat
      : enviromMent === "live"
        ? live
        : development;

export default config;