import React, { useState } from 'react'
import { Message } from "semantic-ui-react";
import CustomErrorField from '../../../../Form/CustomErrorField';

const OpenAndCloseHour = (props) => {
    const [errors, setErrors] = useState([]);

    const handleOnOff = (index, type) => {
        props.handleOnOff(index, type);
    }

    const handleTimeChange = (index, type, e) => {
        props.handleTimeChange(index, type, e);
    }
    return (
        <div className='row'>
            <div className='col-md-8'>
                <div className='row' style={{ margin: 'auto' }}>
                    <div className="box-title" style={{ margin: 'auto' }}>Open Hours</div>
                </div>
                <div className='row'>
                    <div className="form-group row px-4 mb-0 yr-scrll-x">
                        {props.list && props.list.map((item, index) => {
                            return (
                                <ul className="yr-time-ShowHide" key={index}>
                                    <li style={index === 0 ? { marginTop: 24 } : {}}>
                                        <label className="label-color">{item.day}</label>
                                    </li>
                                    <li>
                                        <div className="form-group" style={{ textAlign: 'center' }}>
                                            {index === 0 && <label className="drop-label">Start</label>}
                                            <span className="custom-arrow">
                                                <select
                                                    className={
                                                        errors &&
                                                            errors.length > 0 &&
                                                            errors.hasOwnProperty(index) &&
                                                            errors[index].open_hour_start
                                                            ? "form-control is-invalid mat-style"
                                                            : "form-control mat-style"
                                                    }
                                                    name=""
                                                    value={item.open_hour_start}
                                                    onChange={(e) =>
                                                        handleTimeChange(index, "open_hour_start", e)
                                                    }
                                                    disabled={
                                                        item.working || item.closed
                                                            ? true
                                                            : null
                                                    }
                                                >
                                                    <option value="">Select</option>
                                                    {Object.keys(props.hours).map(function (
                                                        key,
                                                        index
                                                    ) {
                                                        return (
                                                            <option key={index} value={key}>
                                                                {props.hours[key]}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                                {errors &&
                                                    errors.length > 0 &&
                                                    errors.hasOwnProperty(index) &&
                                                    errors[index].openerror && (
                                                        <CustomErrorField
                                                            message="This field is required"
                                                            id="help-error"
                                                            className="error invalid-feedback"
                                                        />
                                                    )}
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="form-group" style={{ textAlign: 'center' }}>
                                            {index === 0 && <label className="drop-label">End</label>}
                                            <span className="custom-arrow">
                                                <select
                                                    className={
                                                        errors &&
                                                            errors.length > 0 &&
                                                            errors.hasOwnProperty(index) &&
                                                            (errors[index].open_hour_end ||
                                                                errors[index].timediff)
                                                            ? "form-control is-invalid mat-style"
                                                            : "form-control mat-style"
                                                    }
                                                    name=""
                                                    value={item.open_hour_end}
                                                    onChange={(e) =>
                                                        handleTimeChange(index, "open_hour_end", e)
                                                    }
                                                    disabled={
                                                        (item.working || item.closed)
                                                            ? true
                                                            : null
                                                    }
                                                >
                                                    <option value="" >Select</option>
                                                    {typeof props.hours != "undefined" &&
                                                        Object.keys(props.hours).map(function (
                                                            key,
                                                            index
                                                        ) {
                                                            return (
                                                                <option 
                                                                    disabled={item.hasOwnProperty('open_hour_start_index') && index <= item.open_hour_start_index}
                                                                    key={index} 
                                                                    value={key}>
                                                                    {props.hours[key]}
                                                                </option>
                                                            );
                                                        })}

                                                    <option value="11:59 PM">11:59 PM</option>
                                                </select>
                                                {errors &&
                                                    errors.length > 0 &&
                                                    errors.hasOwnProperty(index) &&
                                                    (errors[index].closeerror ||
                                                        errors[index].timediff) && (
                                                        <CustomErrorField
                                                            message={
                                                                errors[index].timediff
                                                                    ? "Closed time will be greater than open time"
                                                                    : "This field is required"
                                                            }
                                                            id="help-error"
                                                            className="error invalid-feedback"
                                                        />
                                                    )}
                                            </span>
                                        </div>
                                    </li>
                                    <li style={index === 0 ? { marginTop: 24 } : {}}>
                                        <label className="kt-checkbox chat-checkbox" style={{fontSize: 13, fontWeight: 'inherit', paddingLeft: 25}}>
                                            <input
                                                type="checkbox"
                                                name="hide"
                                                checked={item["closed"] ? true : false}
                                                onChange={() => handleOnOff(index, "closed")}
                                            />
                                            Closed
                                            <span />
                                        </label>
                                    </li>
                                    <li style={index === 0 ? { marginTop: 24 } : {}}>
                                        <label className="kt-checkbox chat-checkbox" style={{fontSize: 13, fontWeight: 'inherit', paddingLeft: 25}}>
                                            <input
                                                type="checkbox"
                                                name="show"
                                                checked={item["working"] ? true : false}
                                                onChange={() =>
                                                    handleOnOff(index, "working")
                                                }
                                            />
                                            Available all day
                                            <span />
                                        </label>
                                    </li>
                                </ul>
                            )
                        })}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default OpenAndCloseHour