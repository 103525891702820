import React from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";

import "./App.css";
import Popup from "react-popup";

import MobileHeader from "./components/Header/TopHeader/MobileHeader";
import Header from "./components/Header/Header";
import Sidebar from "./components/Sidebar/Sidebar";
import Settings from "./components/Pages/Settings/Settings";
import TeamListing from "./components/Pages/ReviewManagement/TeamListing";
import TeamActivity from "./components/Pages/ReviewManagement/TeamActivity";
import YourReviews from "./components/Pages/ReviewManagement/YourReviews";
import ConnectReviewSites from "./components/Pages/ReviewManagement/ConnectReviewSites";
import RequestAReview from "./components/Pages/ReviewManagement/RequestAReview";
import RequestAReviewNew from "./components/Pages/ReviewManagement/RequestAReviewNew";
import ReviewForm from "./components/Pages/ReviewManagement/ReviewForm";
import CreateTicket from "./components/Ticket/CreateTicket";
import Dashboard from "./components/Pages/Settings/Dashboard";
import ChatSetup from "./components/Pages/ChatSetup/ChatSetup";
import LiveChat from "./components/Pages/LiveChat/LiveChat";
import Redirect from "./components/Redirect";
import io from "socket.io-client";
import NotFound from "./components/Pages/NotFound";
import NotAccess from "./components/Pages/NotAccess";
import SupportComments from "./components/Ticket/comment/SupportComment";
import CompletedChats from "./components/Pages/CompletedChats/CompletedChats";

import InstallationAndIntegration from "./components/Integration/Installation-And-Integration";
import config from "./config";
import LiveChatNotification from "./components/LiveChatNotification";

import Automation from "./components/Pages/Workspace/Automation/Automation";
import BotBuilder from "./components/Pages/Workspace/Botbuilder/BotBuilder";
import BotBuilderDetail from "./components/Pages/Workspace/Botbuilder/Details/BotBuilderDetail";

import GoalTracking from "./components/Integration/GoalTracking";
import GtmTracking from "./components/Integration/GtmTracking";
import UtmTracking from "./components/Integration/UtmTracking";
import ChatDashboardWebhooks from "./components/Integration/ChatDashboardWebhooks";
import HippaLog from "./components/Pages/Settings/HippaLog";
import Contacts from "./components/Pages/Marketing/Contacts/Contacts";
import ContactList from "./components/Pages/Marketing/Contacts/ContactList/ContactList";
import PlayBook from "./components/Pages/Marketing/Email/PlayBook";

import testEditor from "./components/Pages/Marketing/Contacts/testEditor";

import EmailTemplate from "./components/Pages/Marketing/Email/Template/EmailTemplate";
import EmailTemplateNew from "./components/Pages/Marketing/Email/Template/EmailTemplateNew";
import ListDomain from "./components/Pages/Marketing/ConfigurationSetting/ListDomain";
import AddDomain from "./components/Pages/Marketing/ConfigurationSetting/AddDomain";

import CallList from "./components/Pages/Marketing/YourCallList/CallList/CallList";
import DetailDomain from "./components/Pages/Marketing/ConfigurationSetting/DetailDomain";
import playBookAnalytics from "./components/Pages/Marketing/PlayBookAnalytics/PlayBookAnalytics";
import playBookBranchWiseStats from "./components/Pages/Marketing/PlayBookBranchWiseStats/PlayBookBranchWiseStats";

import ReviewSitesSetting from "./components/Pages/ReviewManagement/ReviewSitesSetting";
import ManuallyCustomization from "./components/Pages/ReviewManagement/ManuallyCustomization";
import AutmatedCustomization from "./components/Pages/ReviewManagement/AutmatedCustomization";

import Postcard from "./components/Pages/Marketing/Postcard/ListPostcard";
import EditPostcard from "./components/Pages/Marketing/Postcard/EditPostcard";

import DataSource from "./components/Pages/Marketing/Contacts/DataSource/DataSource";
import ApiInstruction from "./components/Pages/Settings/ApiInstruction";
import Integration from "./components/Pages/Settings/Integration/Integration";
import ChatDashboard from "./components/Pages/ChatDashboard/ChatDashboard";

class App extends React.Component {
  
  changePageTitle = (companyName, title) => {
    const pageInfo = document.getElementById("pageTitle");
    let titleText = companyName ? companyName + " | " : "Zyratalk | ";

    titleText += title ? title : "";

    pageInfo.innerHTML = titleText;
    pageInfo.innerText = titleText;
  };
  render() {
    
    // Start for node connection of live chat
    const socketQuery =
      "chatKey=" + this.props.user.chatBot_id + "&userType=company";
    const subscribeObject = {
      chatKey: this.props.user.chatBot_id,
      userType: "company",
    };
    const { nodeJsServerUrl } = config;
    const socket = io.connect(nodeJsServerUrl, {
      query: socketQuery,
      secure: true,
    });
    socket.on("connect", function () {
      var obj = subscribeObject;
      socket.emit("subscribe", obj);
      socket.on("welcome", function () {
        console.log("Yay");
      });
    });
    // Start for node connection of live chat

    if (this.props.user.company && this.props.pageTitle) {
      this.changePageTitle(this.props.user.company, this.props.pageTitle);
    }

    const componentSet = [
      {
        pathStatic: "/chat-setup",
        componentStatic: ChatSetup,
      },
      {
        pathStatic: "/redirect",
        componentStatic: Redirect,
      },
      {
        pathStatic: "/installation",
        componentStatic: InstallationAndIntegration,
      },
      {
        pathStatic: "/completed-chats",
        componentStatic: CompletedChats,
      },
      {
        pathStatic: "/botbuilder",
        componentStatic: BotBuilder,
      },
      {
        pathStatic: "/goal-tracking",
        componentStatic: GoalTracking,
      },
      {
        pathStatic: "/gtm-tracking",
        componentStatic: GtmTracking,
      },
      {
        pathStatic: "/utm-tracking",
        componentStatic: UtmTracking,
      },
      {
        pathStatic: "/dashboard-webhooks",
        componentStatic: ChatDashboardWebhooks,
      },
      {
        pathStatic: "/marketing/contacts",
        componentStatic: Contacts,
      },
      {
        pathStatic: "/marketing/contacts/contactlist",
        componentStatic: ContactList,
      },
      {
        pathStatic: "/marketing/email",
        componentStatic: PlayBook,
      },
      {
        pathStatic: "/marketing/contacts/testeditor",
        componentStatic: testEditor,
      },
      {
        pathStatic: "/settings/team-listing",
        componentStatic: TeamListing,
      },
      {
        pathStatic: "/team-activity",
        componentStatic: TeamActivity,
      },
      {
        pathStatic: "/your-reviews",
        componentStatic: YourReviews,
      },
      {
        pathStatic: "/request-a-review",
        componentStatic: RequestAReview,
      },
      {
        pathStatic: "/request-a-review-new",
        componentStatic: RequestAReviewNew,
      },
      {
        pathStatic: "/settings/connect-review-sites",
        componentStatic: ConnectReviewSites,
      },
      {
        pathStatic: "/settings/settings-customization",
        componentStatic: ReviewSitesSetting,
      },
      {
        pathStatic: "/marketing/callList",
        componentStatic: CallList,
      },
      {
        pathStatic: "/marketing/postcard",
        componentStatic: Postcard,
      },
      {
        pathStatic: "/marketing/adddomain",
        componentStatic: AddDomain,
      },
      {
        pathStatic: "/marketing/listdomain",
        componentStatic: ListDomain,
      },
      {
        pathStatic: "/marketing/contacts/datasource",
        componentStatic: DataSource,
      },
    ];

    var leftBarAccessList = this.props.user.leftBarAccessList;
    var leftBarAccessListChild = [];
    leftBarAccessList.forEach(function (item) {
      item.childElement.forEach(function (itemChild) {
        leftBarAccessListChild.push(itemChild.component);
      });
    });

    //console.log('leftBarAccessListChild',leftBarAccessListChild);

    //create route according to permission START
    const template = leftBarAccessListChild.map((value, index) => {
      
      const component = componentSet.find(
        (val, ind) => val.pathStatic === value
      );

      if (value.includes("/settings?tab=profile")) {
        return <Route exact path="/settings" component={Settings} />;
      } else if (value.includes("/live-chat")) {
        return (
          <Route
            exact
            path="/live-chat"
            render={() => <LiveChat socket={socket} />}
          />
        );
      } else if (component) {
        return (
          <Route
            exact
            path={component.pathStatic}
            component={component.componentStatic}
          />
        );
      }
      // else {
      //   return <Route exact path="*" component={NotAccess} />;
      // }
    });
    //create route according to permission END

    //create route for dashboard and live chat START
    var firstElement = leftBarAccessListChild[0];
    var templateDashboard;
    var ChatsetupidRoute;
    
    if (firstElement == "/live-chat") {
      var templateDashboard = (
        // <Route exact path="/" component={CompletedChats} />
        <Route exact path="/" component={ChatDashboard} />
      );
      var ChatsetupidRoute = (
        <Route exact path="/redirect" component={Redirect} />
      );
    } else {
      if(typeof componentNew != 'undefined' ){
        
        var componentNew = componentSet.find(
          (val, ind) => val.pathStatic === firstElement
        );
        var templateDashboard = (
          <Route exact path="/" component={componentNew.componentStatic} />
        );
      }
    }
    //create route for dashboard and live chat END

    //create route for dynamic url START
    var MarketingcontactlistRoute;
    var MarketingplaybookRoute;
    var MarketingeditpostcardRoute;
    var MarketingeditdetaildomainRoute;
    var MarketingplayBookAnalyticsRoute;
    var MarketingplayBookBranchWiseStatsRoute;
    var MarketingadddomainRoute;
    var MarketingtesteditorRoute;
    if (leftBarAccessListChild.includes("/marketing/contacts")) {
      var MarketingcontactlistRoute = (
        <Route
          exact
          path="/marketing/contactlist/:id"
          component={ContactList}
        />
      );
      var MarketingplaybookRoute = (
        <Route
          exact
          path="/marketing/playbook/:id" // component={EmailTemplate}
          component={EmailTemplateNew}
        />
      );
      var MarketingeditpostcardRoute = (
        <Route
          exact
          path="/marketing/editpostcard/:id"
          component={EditPostcard}
        />
      );
      var MarketingeditdetaildomainRoute = (
        <Route
          exact
          path="/marketing/detaildomain/:id"
          component={DetailDomain}
        />
      );
      var MarketingplayBookAnalyticsRoute = (
        <Route
          exact
          path="/marketing/playBookAnalytics/:id/:pid"
          component={playBookAnalytics}
        />
      );
      var MarketingplayBookBranchWiseStatsRoute = (
        <Route
          exact
          path="/marketing/playBookBranchWiseStats/:id"
          component={playBookBranchWiseStats}
        />
      );
      var MarketingadddomainRoute = (
        <Route exact path="/marketing/adddomain" component={AddDomain} />
      );
      var MarketingtesteditorRoute = (
        <Route
          exact
          path="/marketing/contacts/testeditor"
          component={testEditor}
        />
      );
      var MarketingContactDatasegment = (
        <Route
          exact
          path="/marketing/contacts/datasource"
          component={DataSource}
        />
      );
    }

    //settings dynamic route
    var SettingssupportRoute;
    var SettingssupportcommentRoute;
    var SettingshippalogRoute;
    if (leftBarAccessListChild.includes("/settings?tab=profile")) {
      var SettingssupportRoute = (
        <Route exact path="/support/new" component={CreateTicket} />
      );
      var SettingssupportcommentRoute = (
        <Route exact path="/support/comment/:id" component={SupportComments} />
      );
      var SettingshippalogRoute = (
        <Route exact path="/hippa-log" component={HippaLog} />
      );
    }

    //botbuilder dynamic route
    var botbuilderRoute;
    var automationRoute;
    if (leftBarAccessListChild.includes("/botbuilder")) {
      var botbuilderRoute = (
        <Route exact path="/botbuilder/:id" component={BotBuilderDetail} />
      );
      var automationRoute = (
        <Route exact path="/automation" component={Automation} />
      );
    }

    //request review dynamic route
    var requestreviewRoute;
    if (leftBarAccessListChild.includes("/request-a-review-new")) {
      var requestreviewRoute = (
        <Route exact path="/review-form/:id" component={ReviewForm} />
      );
    }

    //settings customization dynamic review route
    var settingsmanuallyRoute;
    var settingsautomatedRoute;
    var settingsconnectreviewsitesRoute;
   
    if (leftBarAccessListChild.includes("/settings/settings-customization")) {
      var settingsmanuallyRoute = (
        <Route
          exact
          path="/settings/manually-customization/:id"
          component={ManuallyCustomization}
        />
      );
      var settingsautomatedRoute = (
        <Route
          exact
          path="/settings/automated-customization/:id"
          component={AutmatedCustomization}
        />
      );
      var settingsconnectreviewsitesRoute = (
        <Route
          exact
          path="/settings/connect-review-sites"
          component={ConnectReviewSites}
        />
      );
    }
    //create route for dynamic url END

    return (
      <React.Fragment>
        <MobileHeader {...this.props} />

        <div className="kt-grid kt-grid--hor kt-grid--root">
          <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
            <div
              className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
              id="kt_wrapper"
            >
              <Header {...this.props} />
              <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-grid--stretch">
                <Popup />

                <Sidebar user={this.props.user} />
                <Switch>
                  <Route exact path="/goal-tracking" component={GoalTracking} />
                  <Route exact path="/gtm-tracking" component={GtmTracking} />
                  <Route exact path="/utm-tracking" component={UtmTracking} />
                  <Route exact path="/chat-dashboard" component={ChatDashboard} />
                  <Route
                    exact
                    path="/dashboard-webhooks"
                    component={ChatDashboardWebhooks}
                  />
                  {templateDashboard}
                  {ChatsetupidRoute}
                  {MarketingeditdetaildomainRoute}
                  {MarketingcontactlistRoute}
                  {MarketingplaybookRoute}
                  {MarketingeditpostcardRoute}
                  {MarketingplayBookAnalyticsRoute}
                  {MarketingplayBookBranchWiseStatsRoute}
                  {MarketingadddomainRoute}
                  {MarketingtesteditorRoute}
                  {SettingssupportRoute}
                  {SettingssupportcommentRoute}
                  {SettingshippalogRoute}
                  {requestreviewRoute}
                  {settingsmanuallyRoute}
                  {settingsautomatedRoute}
                  {settingsconnectreviewsitesRoute}
                  {/* {liveChatRoute} */}
                  {botbuilderRoute}
                  {MarketingContactDatasegment}
                  {automationRoute}
                  {template}
                  <Route exact path="*" component={NotAccess} />
                  <Route exact path="notAllowed" component={NotAccess} />
                </Switch>
              </div>
            </div>
          </div>
        </div>

        <div id="kt_scrolltop" className="linear-gradient kt-scrolltop">
          <i className="fa fa-arrow-up" />
        </div>
        <LiveChatNotification socket={socket} user={this.props.user} />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    pageTitle: state.page.pageTitle,
    pageMainTitle: state.page.pageMainTitle,
    subHeaderTitle: state.page.subHeaderTitle,
    toggleNavigation: state.page.navigation,
    toggleBreadcrum: state.page.toggleBreadcrum,
    selectedBreadCrum: state.page.breadcrum,
    navigationName: state.page.navigationName,
    user: state.user.company,
  };
};

export default connect(mapStateToProps)(App);
