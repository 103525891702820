import React from "react";
import { Segment } from "semantic-ui-react";
import {
  toggleBreadcrum,
  setPageTitle,
  
} from "../../store/Page";
import './goalTracking.css'
import config from "../../config"; 
import { connect } from "react-redux";
class GoalTracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false, 
      toggleContent:true
    }; 
    this.props.toggleBreadcrum({ toggle: false });
  }
   
  handleContent = (type) =>{
    if(type=='ga4'){
      this.setState({
        toggleContent:true
      })
    }else{
      this.setState({
        toggleContent:false
      })
    }
  }


  render() {
    return (
      <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor">
      <div className="kt-content kt-grid__item kt-grid__item--fluid">
          <div className="yr-body-p kt-margin-t-20">
              <div className="row">
                  <div className="col-md-12">
                      <h3>About goals</h3>
                      <p>Use goals to measure how often users complete specific actions.</p>
                      <p>Goals measure how well your site or app fulfills your target objectives. A goal represents a completed activity, called a conversion, that contributes to the success of your business. Examples of goals include making a purchase (for an ecommerce site), completing a game level (for a mobile gaming app), or submitting a contact information form (for a marketing or lead generation site).</p>
                      <p>Defining goals is a fundamental component of any digital analytics measurement plan. Having properly configured goals allows Analytics to provide you with critical information, such as the number of conversions and the conversion rate for your site or app. Without this information, it's almost impossible to evaluate the effectiveness of your online business and marketing campaigns.</p>
                      
                      <div style={{
                        display:"flex", 
                        fontSize:"17px",
                        fontWeight:"400",
                        width:"40%",
                        marginBottom:"10px"
                      }}>
                        <p className={this.state.toggleContent?"textColor":""} onClick={(e)=>this.handleContent('ga4')}  
                        style={{ borderBottom:this.state.toggleContent?"4px solid #087ab5":"none", 
                        padding:"10px",
                        cursor:"pointer", 
                        height:"45px",
                        marginRight:"15px"}}>Google Analytics: GA4
                        </p>

                        <p className={this.state.toggleContent?"":"textColor"}  onClick={(e)=>this.handleContent('universal')}
                        style={{ borderBottom:this.state.toggleContent?"none":"4px solid #087ab5", 
                        padding:"10px",
                        cursor:"pointer", 
                        height:"45px"
                       }}>Google Analytics: Universal Analysis</p>
                      </div>

                      <div style={{display:this.state.toggleContent==false ? "block":"none"}}>
                      <h3>On Chat Start Goal Setup</h3>
                      <p> Steps for creating Chat Start goals and trigger/tags setup ( See Ref. Video ). In these below steps use “ChatStartID” to create the trigger</p>
                      <ul>
                      <li>First Login with Google tag manager ( <a href='https://tagmanager.google.com' target="_blank">https://tagmanager.google.com</a> ) and Create Account.</li>
                      <li>After creating account it will show all your created account lists if you have any</li>
                      <li>Now click on any account in which you want to create tags and triggers. It will redirect you to the workspace</li>
                      <li>In this workspace click on the left hand side <strong style={{color:'red'}}>Variables</strong> menu and then click on <strong style={{color:'red'}}>configure button</strong></li>
                      <li>Check all the Pages, Utilities, Clicks variables lists</li>
                      <li>To create the <strong style={{color:'red'}}>Trigger</strong>  click on left hand side <strong style={{color:'red'}}>triggers</strong> menu. It will show all your created triggers lists if you have any. Click on <strong style={{color:'red'}}>New</strong> button</li>
                      <li>Configure your trigger by clicking on the <strong style={{color:'red'}}>Trigger Configuration</strong> link</li>
                      <li>Now click on the <strong style={{color:'red'}}>All Elements</strong>  link under the <strong style={{color:'red'}}>Click</strong> Label and click on the <strong style={{color:'red'}}>Some Clicks</strong> button</li>

                      <li>Now Select <strong style={{color:'red'}}>Click Id</strong> in the first dropdown menu ( If you have not found Click Id in dropdown, click on the choose <strong style={{color:'red'}}>Build-In Variable</strong> in dropdown and find the Click Id ). In second dropdown select the <strong style={{color:'red'}}>contains</strong>. Next add the <strong style={{color:'red'}}>ChatStartID</strong> in textbox and save the trigger form. Now your trigger has been configured.</li>

                      <li>Now we have to create the Tags. Click on the Left hand side <strong style={{color:'red'}}>Tags </strong>Menu. It will show all your created tags lists. Click on the New button and configure your tags by clicking on the <strong style={{color:'red'}}>Tag Configuration </strong>link</li>

                      <li>Next it will ask you to select the choose tag type. Select the <strong style={{color:'red'}}>Google Analytics: Universal Analytics</strong> link under the Featured Label </li>
                      <li>Now select the <strong style={{color:'red'}}>Event </strong> in Track Type Dropdown menu under the Tag Configuration and select the <strong style={{color:'red'}} dangerouslySetInnerHTML={{__html:'{{Click ID}}'}}></strong>  under the label section </li>
                      <li>Next select <strong style={{color:'red'}}>true </strong>in Non-Interaction Hit dropdown menu and select <strong style={{color:'red'}}>New Variable</strong> in Google Analytics Settings dropdown. Now add Your Tracking ID and save it.</li>
                      <li>Click on the <strong style={{color:'red'}}>trigger </strong>label and select your pre configured Trigger and save it.</li>
                      <li>After configuring the tags and trigger click on the <strong style={{color:'red'}}>submit </strong>button (Top right corner). Enter the details and publish your changes</li>
                      <li>After publishing the tag manager click on the <strong style={{color:'red'}}>GTM</strong> link in your top right corner. It will give you some scripts to add in your website. Add these scripts as per the instruction.<a href={config.apiBaseURL.split('api')[0]+"assets/images/goal_tracking/1.png"} target="_blank"> Click here to view screen shot</a></li>

                      <li>Now go to the google analytic ( https://analytics.google.com/ ) and login. After login click on the <strong style={{color:'red'}}>Admin </strong>link in bottom left</li>
                      <li>Then click on the <strong style={{color:'red'}}>Tracking Info </strong>link in Property section then click on the <strong style={{color:'red'}}>Tracking Code </strong>it will give you a script add this script as per the instruction.</li>
                      <li>Now go to the Home page of google analytic and click on the <strong style={{color:'red'}}>Admin </strong>link in bottom left corner then click on the <strong style={{color:'red'}}>Goals </strong>link under the All web site Data section</li>
                      <li>Next create New Goal. In Goal Setup click on the <strong style={{color:'red'}}>Custom </strong>button then click to continue.</li>

                      <li>Now add your Goal Description and select the Event type. In goal details add click text in Category and in Action add your tag's action name. Now save it and your goal has been setup</li>

                      <li>To check the real time effect click on the <strong style={{color:'red'}}>Realtime </strong>link in top left corner then click on the Conversation under the Realtime section. Start your chat from your website and complete it you will get Goal hits count increase in realtime</li>
                      <li><strong>Ref. Video URL:</strong> <a href='https://drive.google.com/file/d/1zXc3UeFSJDbS9nQ6quANOGeiQEY4qTgO/view?usp=sharing' target="_blank"><strong>Click here</strong></a></li>
                      </ul>


                      <h3>For Chat Start Event</h3>
                      <p>Steps for creating call us goals and trigger/tags setup ( See the video we have shared ). In these below steps use “callusID” to create the trigger.</p>
                      <ul>
                      <li>First Login with Google tag manager ( <a href='https://tagmanager.google.com' target="_blank">https://tagmanager.google.com</a> ) and Create Account.</li>
<li>After creating account it will show all your created account lists if you have any</li>
<li>Now click on any account in which you want to create tags and triggers. It will redirect you to the workspace</li>
<li>In this workspace click on the left hand side <strong style={{color:'red'}}>Variables</strong> menu and then click on <strong style={{color:'red'}}>configure button</strong></li>
<li>Check all the Pages, Utilities, Clicks variables lists</li>
<li>To create the <strong style={{color:'red'}}>Trigger</strong>  click on left hand side <strong style={{color:'red'}}>triggers</strong> menu. It will show all your created triggers lists if you have any. Click on <strong style={{color:'red'}}>New</strong> button</li>
<li>Configure your trigger by clicking on the <strong style={{color:'red'}}>Trigger Configuration</strong> link</li>
<li>Now click on the <strong style={{color:'red'}}>All Elements</strong>  link under the <strong style={{color:'red'}}>Click</strong> Label and click on the <strong style={{color:'red'}}>Some Clicks</strong> button</li>

<li>Now Select <strong style={{color:'red'}}>Click Id</strong> in the first dropdown menu ( If you have not found Click Id in dropdown, click on the choose <strong style={{color:'red'}}>Build-In Variable</strong> in dropdown and find the Click Id ). In second dropdown select the <strong style={{color:'red'}}>contains</strong>. Next add the <strong style={{color:'red'}}>callusID</strong> in textbox and save the trigger form. Now your trigger has been configured.</li>

<li>Now we have to create the Tags. Click on the Left hand side <strong style={{color:'red'}}>Tags </strong>Menu. It will show all your created tags lists. Click on the New button and configure your tags by clicking on the <strong style={{color:'red'}}>Tag Configuration </strong>link</li>

<li>Next it will ask you to select the choose tag type. Select the <strong style={{color:'red'}}>Google Analytics: Universal Analytics</strong> link under the Featured Label </li>
<li>Now select the <strong style={{color:'red'}}>Event </strong> in Track Type Dropdown menu under the Tag Configuration and select the <strong style={{color:'red'}} dangerouslySetInnerHTML={{__html:'{{Click ID}}'}}></strong>  under the label section </li>
<li>Next select <strong style={{color:'red'}}>true </strong>in Non-Interaction Hit dropdown menu and select <strong style={{color:'red'}}>New Variable</strong> in Google Analytics Settings dropdown. Now add Your Tracking ID and save it.</li>

<li>Click on the <strong style={{color:'red'}}>trigger </strong>label and select your pre configured Trigger and save it.</li>
<li>After configuring the tags and trigger click on the <strong style={{color:'red'}}>submit </strong>button (Top right corner). Enter the details and publish your changes</li>
<li>After publishing the tag manager click on the <strong style={{color:'red'}}>GTM</strong> link in your top right corner. It will give you some scripts to add in your website. Add these scripts as per the instruction.<a href={config.apiBaseURL.split('api')[0]+"assets/images/goal_tracking/1.png"} target="_blank"> Click here to view screen shot</a></li>

<li>Now go to the google analytic ( https://analytics.google.com/ ) and login. After login click on the <strong style={{color:'red'}}>Admin </strong>link in bottom left</li>
<li>Then click on the <strong style={{color:'red'}}>Tracking Info </strong>link in Property section then click on the <strong style={{color:'red'}}>Tracking Code </strong>it will give you a script add this script as per the instruction.</li>
<li>Now go to the Home page of google analytic and click on the <strong style={{color:'red'}}>Admin </strong>link in bottom left corner then click on the <strong style={{color:'red'}}>Goals </strong>link under the All web site Data section</li>
<li>Next create New Goal. In Goal Setup click on the <strong style={{color:'red'}}>Custom </strong>button then click to continue.</li>

<li>Now add your Goal Description and select the Event type. In goal details add click text in Category and in Action add your tag's action name. Now save it and your goal has been setup</li>

<li>To check the real time effect click on the <strong style={{color:'red'}}>Realtime </strong>link in top left corner then click on the Conversation under the Realtime section. Start your chat from your website and complete it you will get Goal hits count increase in realtime</li>
<li><strong>Ref. Video URL:</strong> <a href='https://drive.google.com/file/d/1jC9vvvZKWCf0Z-qSUy-DZWq3Ubcg1ypw/view?usp=sharing' target="_blank"><strong>Click here</strong></a></li>
                          
                      </ul>
                      <h3>On Chat End Goal Setup</h3>
                      <p>Steps for creating ChatEnd goals and trigger/tags setup ( See the video we have shared ). In these below steps use “ChatEndID” to create the trigger.</p>
                      <ul>
                      <li>First Login with Google tag manager ( <a href='https://tagmanager.google.com' target="_blank">https://tagmanager.google.com</a> ) and Create Account.</li>
<li>After creating account it will show all your created account lists if you have any</li>
<li>Now click on any account in which you want to create tags and triggers. It will redirect to the workspace</li>
<li>In this workspace click on the left hand side <strong style={{color:'red'}}>Variables</strong> menu and then click on <strong style={{color:'red'}}>configure button</strong></li>
<li>Check all the Pages, Utilities, Clicks variables lists</li>
<li>To create the <strong style={{color:'red'}}>Trigger</strong>  click on left hand side <strong style={{color:'red'}}>triggers</strong> menu. It will show all your created triggers lists if you have any. Click on <strong style={{color:'red'}}>New</strong> button</li>
<li>Configure your trigger by clicking on the <strong style={{color:'red'}}>Trigger Configuration</strong> link</li>
<li>Now click on the <strong style={{color:'red'}}>All Elements</strong>  link under the <strong style={{color:'red'}}>Click</strong> Label and click on the <strong style={{color:'red'}}>Some Clicks</strong> button</li>

<li>Now Select <strong style={{color:'red'}}>Click Id</strong> in the first dropdown menu ( If you have not found Click Id in dropdown, click on the choose <strong style={{color:'red'}}>Build-In Variable</strong> in dropdown and find the Click Id ). In second dropdown select the <strong style={{color:'red'}}>contains</strong>. Next add the <strong style={{color:'red'}}>ChatEndID</strong> in textbox and save the trigger form. Now your trigger has been configured.</li>

<li>Now we have to create the Tags. Click on the Left hand side <strong style={{color:'red'}}>Tags </strong>Menu. It will show all your created tags lists. Click on the New button and configure your tags by clicking on the <strong style={{color:'red'}}>Tag Configuration </strong>link</li>

<li>Next it will ask you to select the choose tag type. Select the <strong style={{color:'red'}}>Google Analytics: Universal Analytics</strong> link under the Featured Label </li>
<li>Now select the <strong style={{color:'red'}}>Event </strong> in Track Type Dropdown menu under the Tag Configuration and select the <strong style={{color:'red'}} dangerouslySetInnerHTML={{__html:'{{Click ID}}'}}></strong>  under the label section </li>
<li>Next select <strong style={{color:'red'}}>true </strong>in Non-Interaction Hit dropdown menu and select <strong style={{color:'red'}}>New Variable</strong> in Google Analytics Settings dropdown. Now add Your Tracking ID and save it.</li>

<li>Click on the <strong style={{color:'red'}}>trigger </strong>label and select your pre configured Trigger and save it.</li>
<li>After configuring the tags and trigger click on the <strong style={{color:'red'}}>submit </strong>button (Top right corner). Enter the details and publish your changes</li>
<li>After publishing the tag manager click on the <strong style={{color:'red'}}>GTM</strong> link in your top right corner. It will give you some scripts to add in your website. Add these scripts as per the instruction.<a href={config.apiBaseURL.split('api')[0]+"assets/images/goal_tracking/1.png"} target="_blank"> Click here to view screen shot</a></li>

<li>Now go to the google analytic ( https://analytics.google.com/ ) and login. After login click on the <strong style={{color:'red'}}>Admin </strong>link in bottom left</li>
<li>Then click on the <strong style={{color:'red'}}>Tracking Info </strong>link in Property section then click on the <strong style={{color:'red'}}>Tracking Code </strong>it will give you a script add this script as per the instruction.</li>
<li>Now go to the Home page of google analytic and click on the <strong style={{color:'red'}}>Admin </strong>link in bottom left corner then click on the <strong style={{color:'red'}}>Goals </strong>link under the All web site Data section</li>
<li>Next create New Goal. In Goal Setup click on the <strong style={{color:'red'}}>Custom </strong>button then click to continue.</li>

<li>Now add your Goal Description and select the Event type. In goal details add click text in Category and in Action add your tag's action name. Now save it and your goal has been setup</li>

<li>To check the real time effect click on the <strong style={{color:'red'}}>Realtime </strong>link in top left corner then click on the Conversation under the Realtime section. Start your chat from your website and complete it you will get Goal hits count increase in realtime</li>
<li><strong>Ref. Video URL:</strong> <a href='https://drive.google.com/file/d/1ahk4_dJRAk7L8MtqbqB9mNle51SbJDzY/view?usp=sharing' target="_blank"><strong>Click here</strong></a></li>
                      </ul> 
                  </div>

                  <div style={{display:this.state.toggleContent==true ? "block":"none"}}>
                  <h3>For GA4</h3>
                  <p> Steps for creating Chat Start goals and trigger/tags setup ( See Ref. Video ). In these below steps use “ChatStartID” to create the trigger</p>
                      <ul>
                      <li>First Login with Google tag manager ( <a href='https://tagmanager.google.com' target="_blank">https://tagmanager.google.com</a> ) and Create Account.</li>
                      <li>After creating account it will show all your created account lists if you have any</li>
                      <li>Now click on any account in which you want to create tags and triggers. It will redirect you to the workspace</li>
                      <li>In this workspace click on the left hand side <strong style={{color:'red'}}>Variables</strong> menu and then click on <strong style={{color:'red'}}>configure button</strong></li>
                      
                      <li>Check <strong style={{color:'red'}}>Click ID</strong> in Clicks variables lists.</li>
                      <li>To create the <strong style={{color:'red'}}>Trigger</strong> click on the left-hand side <strong style={{color:'red'}}>triggers</strong> menu. It will show all your created triggers lists if you have any. Click on the <strong style={{color:'red'}}>New</strong> button.</li>
                      <li>Configure your trigger by clicking on the <strong style={{color:'red'}}>Trigger Configuration</strong> link.</li>
                      <li>Now click on the <strong style={{color:'red'}}>All Elements</strong> link under the <strong style={{color:'red'}}>All Elements</strong>Click Label and click on the <strong style={{color:'red'}}>Some Clicks</strong> button.</li>
                      </ul>

                      
                      <h3>For Chat Start Event</h3>
                      <ul>
                        <li>In the Value field Select <strong style={{color:'red'}}>Click Id</strong> from <strong style={{color:'red'}}>Choose a variable</strong> ( If you have not found Click Id in the dropdown, 
                          click on the Choose <strong style={{color:'red'}}>Built-In Variable</strong> in the dropdown and find the Click Id ). In the second dropdown
                           select the <strong style={{color:'red'}}>contains</strong>. Next, add the <strong style={{color:'red'}}>ChatStartID</strong>  in the textbox and save the trigger form. Now your trigger has been configured.
                        </li> 
                      </ul>

                      <h3>For Call Us (Phone Button) Event</h3>
                      <ul>
                        <li>In the Value field Select <strong style={{color:'red'}}>Click Id</strong> from <strong style={{color:'red'}}>Choose a variable</strong> ( If you have not found Click Id in the dropdown, 
                          click on the Choose <strong style={{color:'red'}}>Built-In Variable</strong> in the dropdown and find the Click Id ). In the second dropdown
                           select the <strong style={{color:'red'}}>contains</strong>. Next, add the <strong style={{color:'red'}}>callusID</strong>  in the textbox and save the trigger form. Now your trigger has been configured.
                        </li> 
                      </ul>


                      <h3>For Chat End Event</h3>
                      <ul>
                        <li>In the Value field Select <strong style={{color:'red'}}>Click Id</strong> from <strong style={{color:'red'}}>Choose a variable</strong> ( If you have not found Click Id in the dropdown, 
                          click on the Choose <strong style={{color:'red'}}>Built-In Variable</strong> in the dropdown and find the Click Id ). In the second dropdown
                           select the <strong style={{color:'red'}}>contains</strong>. Next, add the <strong style={{color:'red'}}>ChatEndID</strong>  in the textbox and save the trigger form. Now your trigger has been configured.
                        </li> 
                      </ul>

                      <ul>
                        <li>Now we have to create the Tags. Click on the Left-hand side <strong style={{color:'red'}}>Tags</strong> Menu. It will show all your created tags lists. Click on the <strong style={{color:'red'}}>New</strong> and configure your tags by clicking on the <strong style={{color:'red'}}>Tag Configuration</strong> link</li>
                        <li>Next, it will ask you to select the chosen tag type. Select the <strong style={{color:'red'}}>Google Analytics: GA4 Event</strong> link under the Featured Label.</li>
                        <li>Now select configuration Tag from the Configuration Tag Dropdown list if it is available else select <strong style={{color:'red'}}>None – Manually Set ID</strong></li>
                        <li>Now, you need to fill the <strong style={{color:'red'}}>Measurement ID</strong>, that you will get from ( <a href='https://tagmanager.google.com' target="_blank">https://analytics.google.com</a> )</li>
                      </ul>
                      
                      
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
















    );
  }
}
const mapStateToProps = (state) => {
  return {
    user: state.user.company,
  };
};

export default connect(mapStateToProps, {
  toggleBreadcrum,
  setPageTitle,
  
})(GoalTracking); 
