import React from "react";
import { Link } from "react-router-dom";
const Logo = ({ user }) => {
  return (
    <div className="kt-header__brand   kt-grid__item" id="kt_header_brand">
      <a className="kt-header__brand-logo" to="/">
        <img alt="Logo" src={user.logoUrl} width="100px" />
      </a>
    </div>
  );
};

export default Logo;
