import React from "react";
import { connect } from "react-redux";
import ReactDOM from "react-dom";

// import DesignRules from './DesignRules';
import api from "../../../../../api";
import "../../../TranscriptChat/TranScriptChat.css";

import { Message } from "semantic-ui-react";
import {
  setPageTitle,
  toggleNavigation,
  setMessage,
  setBreadCrum,
} from "../../../../../store/Page";
import { Form } from "semantic-ui-react";

const queryString = require("query-string");
const regex = new RegExp("^[a-zA-Z0-9 ]+$");

class ChatFilter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chatstatus: "all",
      daterange: "alltime",
      selectedtext: "Choose Date Range ",
      rangepicker: false,
      csvdata: [],
      serach: "",
      filter: "",
      isOpen: false,
      filter: "",
    };
    this.openModal = this.openModal.bind(this);
  }

  openModal = () => {
    this.setState({ isOpen: true });
  };

  orderStatusChange = (e) => {
    this.setState({
      order_status: e.target.value,
    });

    this.props.handleSort(e.target.value, "asc");
  };

  chatStatusChange = (e) => {
    this.setState({
      chatstatus: e.target.value,
    });

    this.props.onSubmitFilter("chatstatus", e.target.value);
  };

  handleChange = (e) => {
    console.log(e.target.value);
    this.setState({
      serach: e.target.value,
    });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    console.log("---", this.state.serach);
    this.props.onSubmitFilter("q", this.state.serach);
  };
  chatTimeChange = (e) => {
    this.setState({
      chatstatus: e.target.value,
    });
    if (e.target.value == "daterange") {
      this.setState(
        {
          rangepicker: true,
          daterange: e.target.value,
        },
        function () {
          document.querySelector(".showrangepicker").click();
        }
      );
    } else {
      this.setState({ rangepicker: false, daterange: e.target.value });
      this.props.onSubmitFilter("chatfrequency", e.target.value);
    }
  };
  handleClick = (e) => {
    this.datepicker.click();
  };

  handleOnChange = (event, { name, value }) => {
    if (value !== "" && !regex.test(value)) {
      this.setState({ [name]: value, filterValid: false });
    } else {
      this.setState({ [name]: value, filterValid: true });
    }
    if (value.trim() == "") {
      this.props.onSubmitFilter(value);
    }
  };

  handleOnClick = () => {
    let value = this.state.filter;
    if (value.trim() !== "") {
      this.props.onSubmitFilter(value);
    }
  };

  render() {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let final = month + "/" + date + "/" + year;
    const { filter } = this.state;

    return (
      <div className="kt-form kt-form--label-right kt-margin-t-30  yr-manageUser-table">
        <div className="row" style={{ marginLeft: '0px' }}>
          <div
            className="col-xl-8 order-2 order-xl-1 mt-3"
            style={{ marginBottom: 15 }}
          >
            <div className="row" style={{ marginLeft: '0px' }}>
              <div className="col-md-12 kt-margin-b-20-tablet-and-mobile ">
                <div className="kt-form__group kt-form__group--inline">
                  {/* <div class="kt-form__label yr-shortBy">
                                <label class="kt-checkbox">
                                    <input type="checkbox" />
                                    <span></span>
                                </label>
                            </div> */}
                  {/* <div className="kt-form__label">
                    <label className="yr-nowrap kt-font-bolder">Sort by</label>
                  </div>
                  <div className="kt-form__control" style={{ width: "100px" }}>
                    <select
                      className="form-control"
                      id="kt_form_status"
                      style={{ width: "auto" }}
                      onChange={(e) => this.orderStatusChange(e)}
                    >
                      <option value="title">Title</option>
                      <option value="created_on">Date</option>
                    </select>
                  </div> */}
                  <div className="kt-input-icon kt-input-icon--left">
                    <Form onSubmit={this.handleOnClick}>
                      <Form.Input
                        id="playBookSearchBar"
                        style={{ marginLeft: 4 }}
                        name="filter"
                        className="search-input-mu"
                        // value={filter}
                        onChange={this.handleOnChange}
                      />
                    </Form>
                    <span
                      className="kt-input-icon__icon kt-input-icon__icon--left"
                      style={{ cursor: "pointer", background:"white", width:"26px", height:"30px", marginTop:"5px", marginRight:"5px" }}
                      onClick={this.handleOnClick}
                    >
                      <span>
                        <i className="la la-search" />
                      </span>
                    </span>
                    <span className="yr-searchPlace-holder">Search</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ChatFilter;
